<div class="section-container-inside">
  <div class="title" *ngIf="!fetchKryptomon">
    Loading...
  </div>
  <div class="title" *ngIf="kryptomons.length > 0 && fetchKryptomon">
    Eggs Incubator
  </div>
  <div class="title" *ngIf="kryptomons.length == 0 && fetchKryptomon">
    Feels like a desert...
  </div>
  <div class="egg-list">
    <div class="egg-card" *ngFor="let k of kryptomons">
      <div class="egg-card-image">
        <img src="{{ k.data.image }}" />
      </div>
      <div class="egg-card-content">
        <div class="egg-id-element">
          <p *ngIf="k.data.id!=='6188'">No. {{ k.data.id }}</p>
          <p *ngIf="k.data.id=='6188'">MissingNo</p>
          <img src="{{ k.data.iconImage }}" (click)="openDetails('egg', k.data.id, k)" />
        </div>
        <div class="egg-gen-element">
          <p>GEN: {{ k.data.generation }}</p>
          <p>ELEMENT: {{ k.data.typeFormatted }}</p>
        </div>
        <div class="egg-actions-button-container" *ngIf="k.data.id!=='6188'">
        <div class="egg-action-button" (click)="grantAllowance()" *ngIf="
            k.data.rendered &&
            isReadyToHatch(k.data) &&
            !hasAllowance &&
            hasKmonToHatch() &&
            !loadingAllowance
          ">
          <span>Allowance</span>
        </div>
        <div class="egg-action-button disabled" *ngIf="loadingAllowance">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="egg-action-button" (click)="startHatching(k)" *ngIf="k.data.rendered && isReadyToHatch(k.data) && hasAllowance && hasKmonToHatch()">
          Hatch
        </div>
        <div *ngIf="k.data.rendered && isReadyToHatch(k.data) && !hasKmonToHatch()">
          <p style="text-align: center; color: #d84f3e">100 KMON is required to hatch</p>
          <div class="egg-action-button" (click)="buyKmon(k)">
            Buy KMON
          </div>
        </div>
        <div class="egg-action-button disabled" *ngIf="k.data.rendered && !isReadyToHatch(k.data)">
          <countdown units="Hours | Minutes | Seconds" [divider]="divider" [text]="text" end="{{ k.data.hatchingDate }}"></countdown>
        </div>
        <div class="egg-action-button disabled" *ngIf="!k.data.rendered && !isReadyToHatch(k.data)">
          <countdown units="Hours | Minutes | Seconds" [divider]="divider" [text]="text" end="{{ k.data.hatchingDate }}"></countdown>
        </div>
        <div class="egg-action-button disabled" *ngIf="!k.data.rendered && isReadyToHatch(k.data)">
          rendering...
        </div>
      </div>
       <div class="egg-actions-button-container pat" *ngIf="k.data.id=='6188'">
        <div class="egg-action-button disabled">
          Patientia vincit omnia
        </div>
       </div>
      </div>
    </div>
    <div class="egg-card no-eggs" *ngIf="kryptomons.length == 0 && fetchKryptomon">
      <div class="egg-card-image">
        <img src="./assets/img/Home/cactus.png" />
      </div>
      <div class="egg-card-content">
        <div class="egg-id-element">
          <p>Get a Kryptomon's egg to start play!</p>
        </div>
        <div class="egg-action-button extend" (click)="goMarketplace()">
          Marketplace
        </div>
      </div>
    </div>
  </div>
</div>
<div class="hatching-overlay" *ngIf="showHatchModal">
  <div class="hatching-modal">
    <div class="hatching-modal-title">
      Oh! Something is moving...
    </div>
    <div class="hatching-modal-image">
      <img src="{{ kryptomonHatch.data.image }}" />
    </div>
    <div class="hatching-modal-text">
      Your very own Kryptomon legend is about to unfold!
    </div>
    <!--<div class="hatching-modal-button">
    </div>-->
  </div>
</div>
<div class="hatching-pitch-white" *ngIf="pitchWhite"></div>
<div class="hatching-kryptomon-show" *ngIf="showKryptomonOff">
  <div class="hatching-kryptomon-block">
    <div class="hatching-kryptomon-image">
      <img src="{{ kryptomonHatch.data.gif }}" />
    </div>
    <div class="hatching-island-block">
      <!--<img src="./assets/img/Home/purplerock.png" />-->
      <div class="hatching-naming-box">
        <p>
          Congratulations! Your Kryptomon is finally born. How would you like to
          name it?
        </p>
        <input type="text" placeholder="Insert a name..." [(ngModel)]="kryptomonName" />
        <div class="hatching-save-action">
          <div class="hatchin-naming-box-button" (click)="skipNaming()" *ngIf="!loadingName">
            Skip
          </div>
          <div class="hatchin-naming-box-button" (click)="saveNameNext(kryptomonHatch)" *ngIf="!loadingName">
            Save &amp; Next
          </div>
          <div class="hatchin-naming-box-button" *ngIf="loadingName">
            <div class="lds-ring white">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

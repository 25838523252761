import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { AppComponent } from "../../../app.component";
import { Router } from '@angular/router'
import { SimpleGlobal } from 'ng2-simple-global';
import { HttpClient } from "@angular/common/http";
import { BigNumber } from "ethers";

interface Kryptomons {
  type;
  talent;
  typeFormatted;
  id;
  generation;
  speciality;
  super;
  unfreezable;
  image;
  iconImage;
  gif;
  name;
}

interface httpResult {
  status;
  data;
  description;
  name;
  image;
}

@Component({
  selector: 'app-kryptomons',
  templateUrl: './kryptomonsV2.component.html',
  styleUrls: ['./kryptomonsV2.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class KryptomonsComponentV2 implements OnInit {
  public account;
  public kryptomons;
  public fetchKryptomon
  public typeFormatted = [
    "Fire",
    "Water",
    "Ice",
    "Ground",
    "Air",
    "Electro",
    "Ghost",
    "Grass",
  ];

  public evolutionTimes = [259200, // 3 days
    2629743, // 1 month
    31556926, // 12 months
    31556926]; // 12 motnhs

  public isEvolving = {};
  public hasAllowance = false;
  public loadingAllowance = false;
  public kmonBalance;

  constructor(public appComponent: AppComponent, public router: Router, public sg: SimpleGlobal, public http: HttpClient) {
    this.kryptomons = [];
    this.fetchKryptomon = false;
    if (this.appComponent.accounts == undefined) {
      this.appComponent.accountsObservable.subscribe((item) => {
        this.account = item[0];
        if (this.account !== undefined && this.account !== "") {
          this.getKryptomons();
          this.setAllowance();
          this.setKmonBalance();
        }
      });
    } else {
      this.account = this.appComponent.accounts[0];
      this.getKryptomons();
      this.setAllowance();
      this.setKmonBalance();
    }
  }

  ngOnInit(): void { }

  async getKryptomons() {
    //this.isLoading = true;
    this.appComponent.contractService.contractInstanceV2.methods
      .ownedKryptomons()
      .call({
        from: this.account,
      })
      .then(async (receipt) => {
        console.log(receipt);
        if (receipt.length > 0) {
          for (let i = 0; i < receipt.length; i += 1) {
            await this.getKryptomonDetails(receipt[i]);
          }
        }
        this.fetchKryptomon = true;
      })
      .catch((err) => {
        ////console.log(err, 'err');
        //this.isLoading = false;
        this.fetchKryptomon = true;
      });
  }

  isReadyToEvolve(index) {
    const evolutionTime = this.evolutionTimes[this.kryptomons[index].status];
    const nextEvolution = (parseInt(this.kryptomons[index].lastEvolved) + evolutionTime) * 1000;
    return nextEvolution < Date.now();
  }

  async startEvolving(id, index) {
    //waiting for the transaction to be successfull
    this.isEvolving[id] = true;
    await this.appComponent.contractService.contractInstanceV2.methods.evolveKryptomon(id).send({
      from: this.account
    }).then((response) => {
      console.log(response)
      setInterval(this.checkEvolvedKryptomon, 3000, [id, index, this]);
    }).catch((err) => {

    });
  }

  async checkEvolvedKryptomon(attributes) {
    const id = attributes[0];
    const index = attributes[1];
    const thisClass = attributes[2];
    const item: httpResult = await thisClass.http.get("https://api.kryptomon.co/json/kryptomon/meta/" + id).toPromise() as httpResult;
    const kryptomon = thisClass.kryptomons[index];
        if (kryptomon.data.gif != item.image) {
          kryptomon.data.gif = item.image;
          kryptomon.data.image = item.image.split("gif").join("png");
          kryptomon.lastEvolved = Date.now() / 1000;
          thisClass.isEvolving[id] = false;
        }
  }

  async getKryptomonDetails(receipt) {
    await this.appComponent.contractService.contractInstanceV2.methods
      .getKryptomon(receipt)
      .call({
        from: this.account,
      })
      .then((kryptomon) => {
        if (kryptomon[10] > 0) {
          this.kryptomons.push({
            id: kryptomon[0],
            genes: kryptomon[3],
            matron: kryptomon[7],
            sire: kryptomon[8],
            timeBorn: kryptomon[9],
            status: kryptomon[10],
            timeHatched: kryptomon[11],
            timeToHatch: 0,
            extra_data: kryptomon[5],
            lastEvolved: kryptomon[17]
          });

          this.parseKryptomonDetails();
        }
      })
      .catch((err) => {
        ////console.log(err, 'err');
      });
  }

  parseKryptomonDetails() {
    for (let i = 0; i < this.kryptomons.length; i++) {
      let genes = this.kryptomons[i].genes;
      let typeDraft = [];
      let type = [];
      let c = 0;
      for (let i = 0; i < 8; i++) {
        let sum = parseInt(genes[c]) * parseInt(genes[c + 1]);
        c = c + 2;
        typeDraft.push(sum);
      }
      let typeSelected = this.indexOfMax(typeDraft);
      let kryptomon = {} as Kryptomons;
      kryptomon.type = typeSelected;
      kryptomon.talent = typeDraft[typeSelected];
      kryptomon.typeFormatted = this.typeFormatted[typeSelected];

      if (genes[19] > genes[20] * 1.1) {
        kryptomon.speciality = "attack";
      } else if (genes[20] > genes[19] * 1.1) {
        kryptomon.speciality = "defense";
      } else {
        kryptomon.speciality = "balance";
      }

      if (genes[22] == 0) {
        kryptomon.super = false;
        var img_selected = kryptomon.speciality;
      } else {
        img_selected = "super";
        kryptomon.super = true;
      }

      kryptomon.unfreezable = this.kryptomons[i].extra_data[0];
      kryptomon.id = this.kryptomons[i].id;
      kryptomon.generation = genes[genes.length - 1];

      //console.log(kryptomon.unfreezable)

      // kryptomon.image =
      //   "https://kryptomon-images.ams3.digitaloceanspaces.com/images/kryptomons/png/kmon_" + kryptomon.id + "_png.png";
      // kryptomon.gif =
      //   "https://kryptomon-images.ams3.digitaloceanspaces.com/images/kryptomons/gif/kmon_" + kryptomon.id + "_gif.gif";

      /*if (kryptomon.unfreezable == 1) {
        kryptomon.image =
          "./assets/img/top_special/" + kryptomon.typeFormatted + "/" + img_selected + ".png";
      } else {
        kryptomon.image =
          "./assets/img/" + kryptomon.typeFormatted + "/" + img_selected + ".png";
      }*/

      kryptomon.iconImage =
        "./assets/img/icons/" + kryptomon.typeFormatted.toLowerCase() + ".svg";

      this.http
        .get("https://api.kryptomon.co/json/kryptomon/meta/" + kryptomon.id)
        .subscribe((item: httpResult) => {
          kryptomon.name = item.name;
          kryptomon.gif = item.image;
          kryptomon.image = item.image.split("gif").join("png");
        });

      this.kryptomons[i].data = kryptomon;
    }
  }

  openDetails(what, id, kryptomonSelected) {
    this.sg['kryptomonSelected'] = kryptomonSelected
    console.log(this.sg['kryptomonSelected'])
    this.router.navigate(['/detailsV2', what])
  }

  indexOfMax(arr) {
    if (arr.length === 0) {
      return -1;
    }

    var max = arr[0];
    var maxIndex = 0;

    for (var i = 1; i < arr.length; i++) {
      if (arr[i] > max) {
        maxIndex = i;
        max = arr[i];
      }
    }

    return maxIndex;
  }

  goMarketplace() {
    window.open("https://market.kryptomon.co");
  }

  async setAllowance() {
    let allowance = await this.appComponent.contractService.contractTokenInstance.methods.allowance(this.account, this.appComponent.contractService.contractInstanceV2._address).call();
    this.hasAllowance = BigNumber.from(allowance).gt(0);
  }

  grantAllowance() {
    this.loadingAllowance = true;
    this.appComponent.contractService.contractTokenInstance.methods.approve(this.appComponent.contractService.contractInstanceV2._address, this.appComponent.contractService.web3.utils.toWei('999999999999')).send({
      from: this.account
    }).then((result) => {
      if (result.events.Approval !== undefined) {
        this.hasAllowance = true;
        this.loadingAllowance = false;
      }
    }).catch((err) => {
      this.hasAllowance = false;
      this.loadingAllowance = false;
    });;
  }

  hasKmonToEvolve() {
    return this.kmonBalance.gte(100);
  }

  async setKmonBalance() {
    const balance = await this.appComponent.contractService.contractTokenInstance.methods.balanceOf(this.account).call();
    this.kmonBalance = BigNumber.from(parseInt(await this.appComponent.contractService.web3.utils.fromWei(balance)))
  }

  buyKmon() {
    window.location.href = 'https://pancakeswap.finance/swap?outputCurrency=0xc732b6586a93b6b7cf5fed3470808bc74998224d';
  }
}
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppComponent } from "../../app.component";
import { SimpleGlobal } from 'ng2-simple-global';
import { HttpClient } from "@angular/common/http";
import { ToastrService } from 'ngx-toastr';
import {Router} from '@angular/router'

@Component({
  selector: 'app-game-view',
  templateUrl: './game-view.component.html',
  styleUrls: ['./game-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GameViewComponent implements OnInit {

  public walletListed;
  public account
  public inviteCode
  public inviteValid
  public updating
  public hasKryptomonV1
  public hasKryptomonV2

  constructor(public router:Router, public toastr: ToastrService, public appComponent:AppComponent, public http:HttpClient) { 

    this.inviteValid = true;
    this.updating = false;
    this.hasKryptomonV1 = false;
    this.hasKryptomonV2 = false;
    this.getUpdateStatus();
    if (this.appComponent.accounts == undefined) {
      this.appComponent.accountsObservable.subscribe((item) => {
        this.account = item[0];
        if (this.account !== undefined && this.account !== "") {
          //this.checkWallet();
          this.checkKryptomonV1();
          this.checkKryptomonV2();
        }
      });
    }else{
      this.account = this.appComponent.accounts[0];
      //this.checkWallet();
      this.checkKryptomonV1();
      this.checkKryptomonV2();
    }
  }

  ngOnInit(): void {
  }

  getUpdateStatus(){
    this.http.get("https://api.kryptomon.co/internal/getUpdateStatus.php").subscribe((item:any)=>{
      if(item.update_status==1){
        this.updating = true;
      }else{
        this.updating = false;
      }
    })
  }

  checkKryptomonV1(){
    this.appComponent.contractService.contractInstance.methods
      .ownedKryptomons()
      .call({
        from: this.account,
      })
      .then((receipt) => {
        console.log(receipt);
        if (receipt.length > 0) {
          this.hasKryptomonV1 = true;
        } else {
          //this.checkClaim();
          //this.isLoading = false;
           this.hasKryptomonV1 = false;
        }
       
      })
      .catch((err) => {
        ////console.log(err, 'err');
        this.hasKryptomonV1 = false;
      });
  }

  checkKryptomonV2(){
    this.appComponent.contractService.contractInstanceV2.methods
      .ownedKryptomons()
      .call({
        from: this.account,
      })
      .then((receipt) => {
        console.log(receipt);
        if (receipt.length > 0) {
          this.hasKryptomonV2 = true;
        } else {
          //this.checkClaim();
          //this.isLoading = false;
           this.hasKryptomonV2 = false;
        }
       
      })
      .catch((err) => {
        ////console.log(err, 'err');
        this.hasKryptomonV2 = false;
      });
  }

  checkWallet(){
    let post = {address: this.account};
    this.http.post("https://api.kryptomon.co/auth/listed.php", post).subscribe((item:any)=>{
      if(item.status=="listed"){
        this.walletListed = true;
      }else{
        this.walletListed = false;
      }
    })
  }

  checkInvite(){
    let post = {invite: this.inviteCode, address:this.account}
    this.http.post("https://api.kryptomon.co/auth/checkInvite.php", post).subscribe((item:any)=>{
      console.log(item);
      if(item.status=="listed"){
        this.inviteValid = true;
      }else{
        this.inviteValid = false;
        this.toastr.error('Seems this invite code is not valid.', 'Ops!', { timeOut: 5000 });
      }
    })

  }

  openMarketPlace(){
    window.open("https://market.kryptomon.co");
  }

  openComic(){
    window.open("https://comic.kryptomon.co/home");
  }
  
  openHunt(){
    window.open("https://www.kryptomon.co/treasure-hunt");
  }

  migrateDashboard(){
    this.router.navigate(["/eggs"]);
  }

  openKryptomons(){
    this.router.navigate(["/kryptomonsV2"])
  }

}

import { Component, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router'
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import { Angulartics2 } from 'angulartics2';

@Component({
  selector: 'app-tokenomics',
  templateUrl: './tokenomics.component.html',
  styleUrls: ['./tokenomics.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class TokenomicsComponent implements AfterViewInit {
  public closeBanner
  constructor(public router:Router, private angulartics2: Angulartics2) { 
  	this.closeBanner = true;
  }

  ngAfterViewInit(): void {
  	setTimeout(()=>{
  		this.initCharts();
  	},100)
  }

  goHome() {
    console.log('home')
    location.href = 'https://kryptomon.co'      // or without async/await you can simply chain the promises
  }

    WhitePaper(){
      this.router.navigate(["/white-paper"])    // or without async/await you can simply chain the promises
      this.angulartics2.eventTrack.next({ 
      action: 'GoToWhitepaper', 
      properties: { category: 'home' },
    });
	 }

	    teamPage() {
    console.log('home')
    this.router.navigate(["/team"])      // or without async/await you can simply chain the promises
    this.angulartics2.eventTrack.next({ 
      action: 'GoToTeam', 
      properties: { category: 'home' },
    });
  }
  
	 toggleBanner(){
	   this.closeBanner=!this.closeBanner
	 }

  initCharts(){
  	am4core.useTheme(am4themes_animated);

	var chart = am4core.create("chartdiv", am4charts.PieChart);
	chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

	chart.data = [
	  {
	    country: "Pre-Seed Allocation",
	    value: 50
	  },
	  {
	    country: "Seed Allocation",
	    value: 200
	  },
	  {
	    country: "Pre-IDO",
	    value: 50
	  },
	  {
	    country: "Community Allocation",
	    value: 20
	  },{
	    country: "IDO Allocation",
	    value: 50
	  },
	  {
	    country: "Liquidity Pool",
	    value: 50
	  },
	  {
	    country: "Team Allocation",
	    value: 150
	  },
	  {
	    country: "Advisory Allocation",
	    value: 30
	  },
	  {
	    country: "Foundation Reserve",
	    value: 300
	  },
	  {
	    country: "Liquidity / Reward Allocation",
	    value: 100
	  }
	];

	var series = chart.series.push(new am4charts.PieSeries());
	series.dataFields.value = "value";
	series.dataFields.radiusValue = "value";
	series.dataFields.category = "country";
	series.slices.template.cornerRadius = 6;
	series.colors.step = 3;
	series.labels.template.text = "";
	series.hiddenState.properties.endAngle = -90;
	series.events.on("datavalidated", function(ev) {
	ev.target.slices.each(function(slice) {
	  if (slice.dataItem.values.value.percent < 5) {
	    slice.dataItem.hide();
	  }
	});
	});


	am4core.useTheme(am4themes_animated);

	var chart = am4core.create("chartdiv-use", am4charts.PieChart);
	chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

	chart.data = [
	  {
	    country: "Development",
	    value: 40
	  },
	  {
	    country: "Marketing / Community Building",
	    value: 30
	  },
	  {
	    country: "Legal",
	    value: 5
	  },
	  {
	    country: "Liquidity Pool",
	    value: 25
	  }
	];

	var series = chart.series.push(new am4charts.PieSeries());
	series.dataFields.value = "value";
	series.dataFields.radiusValue = "value";
	series.dataFields.category = "country";
	series.slices.template.cornerRadius = 6;
	series.colors.step = 3;
	series.labels.template.text = "";
	series.hiddenState.properties.endAngle = -90;
	series.events.on("datavalidated", function(ev) {
	ev.target.slices.each(function(slice) {
	  if (slice.dataItem.values.value.percent <= 5) {
	    slice.dataItem.hide();
	  }
	});
	});
  }


}

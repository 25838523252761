import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component'
import { GetEggComponent } from './pages/get-egg/get-egg.component'
import { HowToComponent } from './pages/how-to/how-to.component'
import { WinningListComponent } from './pages/winning-list/winning-list.component'
import { WhitePaperComponent } from './pages/white-paper/white-paper.component'
import { TokenomicsComponent } from './pages/tokenomics/tokenomics.component'
import { TeamComponent } from './pages/team/team.component'
import { AidropCollectComponent } from './pages/aidrop-collect/aidrop-collect.component'
import { LeaderboardComponent } from './pages/leaderboard/leaderboard.component'
import { MarketComponent } from './pages/market/market.component'
import { MBSaleComponent } from './pages/mb-sale/mb-sale.component'
import { BinanceNFTComponent } from './pages/binanceNFT/binanceNFT.component'
import { SplashscreenComponent } from './pages/splashscreen/splashscreen.component'
import { MerkleTestComponent } from './pages/merkle-test/merkle-test.component';
import { StakingComponent } from './pages/staking/staking.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { EggsComponent } from './pages/dashboard/eggs/eggs.component';
import { EggsComponentV2 } from './pages/dashboard/eggsV2/eggs.component';
import { KryptomonsComponent } from './pages/dashboard/kryptomons/kryptomons.component';
import { KryptomonsComponentV2 } from './pages/dashboard/kryptomonsV2/kryptomonsV2.component';
import { DetailsComponent } from './pages/dashboard/details/details.component';
import { DetailsComponentV2 } from './pages/dashboard/detailsV2/details.component';
import { InternalComponent } from './pages/internal/internal.component';
import { GameViewComponent } from './pages/game-view/game-view.component';
import { EggHuntListingComponent } from './pages/egg-hunt-listing/egg-hunt-listing.component';


const routes: Routes = [
//{path:'', redirectTo:"/dashboard"},
{path:'/', redirectTo:""},
//{path:'dashboard-old', component:GetEggComponent},
//{path:'how-to', component:HowToComponent},
//{path:'claim', component:WinningListComponent},
//{path:'white-paper', component:WhitePaperComponent},
//{path:'tokenomics', component:TokenomicsComponent},
//{path: 'team', component:TeamComponent},
//{path:'airdrop', component:AidropCollectComponent},
//{path:'leaderboard', component:LeaderboardComponent},
{path:'sale', component:MBSaleComponent},
{path:'claim', component:MarketComponent},
{path:'binanceNFT', component:BinanceNFTComponent},
{path:'play', component:GameViewComponent},
{path:'egg-hunt', component:EggHuntListingComponent},
//{path:'testnet/get-egg', component:SplashscreenComponent},
//{path:'internal/genome', component: InternalComponent},
//{path:'old-home', component:HomeComponent},
{path:'staking', component:StakingComponent},
{
	path: '', 
	component: DashboardComponent,
	children:[
		{path:'', redirectTo:"eggs", pathMatch: 'full'},
		{path:'eggs', component:EggsComponent},
		{path:'eggsV2', component:EggsComponentV2},
		{path:'kryptomons', component:KryptomonsComponent},
		{path:'kryptomonsV2', component:KryptomonsComponentV2},
		{path: 'details/:type', component:DetailsComponent},
		{path: 'detailsV2/:type', component:DetailsComponentV2},
		]
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
   { 
   	anchorScrolling: 'enabled',
	scrollPositionRestoration: "enabled",
	},
	)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit, ViewEncapsulation, AfterViewInit, NgZone, PLATFORM_ID, Inject } from '@angular/core';
import {ContractService} from '../../services/contract.service';
import {SparksService} from '../../services/sparks.service';
import {ActivatedRoute} from '@angular/router'
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import { isPlatformBrowser } from '@angular/common';
import {ThemeService} from 'ng-bootstrap-darkmode';
import { HttpClient } from '@angular/common/http';
import * as merkle from './merkle.json';
import {Router} from '@angular/router'
declare const tsParticles;

const isEmpty = (obj) => {
    for(let key in obj) {
        if(obj.hasOwnProperty(key))
            return true;
    }
    return false;
}
declare const screen;

interface httpResult{
  status
  data
  description
}
interface Kryptomons{
  image
  type
  talent
  typeFormatted
  xfactor
  speciality
  timeBorn
  super
  personality
  generation
  id
  islandImage
  timeBornUnix
  unfreezable
  bio
}

interface Personality{
  Constitution
  Affection
  Crazyness
  Instinct
  Hunger
  Laziness
  Braveness
  Smart
  Ego
  generation
}

interface chartData{
  typeName
  specialityName
  valueName
  valueSpeciality
  config
}

interface selectedKryptomon{
	data
	genes
	id
	matron
	sire
	test
	selected
  bio
}

@Component({
  selector: 'app-get-egg',
  templateUrl: './get-egg.component.html',
  styleUrls: ['./get-egg.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GetEggComponent implements AfterViewInit {

  private chart

  public accounts
  public isLoading
  public isLoadingBuy
  public kryptomons = [];
  public typeFormatted = ["Fire", "Water", "Ice", "Ground", "Air", "Electro", "Ghost", "Grass"];
  public selectedKryptomon = {} as selectedKryptomon
  public connection_text
  public shake
  public signupForm
  public email
  public nickname
  public signupLoading
  public nicknameAlreadyUsed
  public emailAlreadyUsed
  public showExtendedCard
  public alreadyUsedText
  public personalityChart
  public showDisclaimer
  public notListed
  public isTopSupporter
  public showShare
  public isTeamMember
  public listedOnDb
  constructor(public router:Router, public http:HttpClient, public themeService: ThemeService, public contractService: ContractService, public sparksService: SparksService, private route: ActivatedRoute, @Inject(PLATFORM_ID) private platformId, private zone: NgZone) { 
    //this.contractService.mounted();
    this.isLoading = false;
    this.isLoadingBuy = false;
    this.shake = false;
    this.signupForm = false;
    this.nicknameAlreadyUsed = false;
    this.emailAlreadyUsed = false;
    this.signupLoading = false;
    this.showExtendedCard = false;
    this.personalityChart = false;
    this.showDisclaimer = true;
    this.notListed = false;
    this.showShare = false;
    this.isTopSupporter = false;
    this.isTeamMember = false;
    this.listedOnDb = false;
    this.contractService.mount().then((data)=>{
      console.log('data')
      console.log(data)
      if(data!=='nomask' && data!=='denied_access' && data !== 'wrong_network'){
        //console.log("quauauau")
            this.getAccounts().then(()=>{
              this.login()
            });
      }else{
        if(data=="nomask"){
            this.isLoading = false;
            this.connection_text = "No wallet"
        }else if(data == "denied_access"){
          this.isLoading = false;
          this.connection_text = "Grant access"
            //console.log("qua!=")
        }else{     
           this.isLoading = false;
            this.connection_text = "Wrong Network"
        }
      }
    });
     themeService.theme$.subscribe(theme => {
       if(this.kryptomons.length>0 && this.selectedKryptomon!==undefined){
         this.createChart(this.selectedKryptomon)
         this.createPersonalityChart();
       }
     });

     
     
}

  ngAfterViewInit(): void {
  	

  }

  showModalConnect(){
    this.contractService.mount().then((data)=>{
      console.log('data')
      console.log(data)
      if(data!=='nomask' && data!=='denied_access' && data !== 'wrong_network'){
        //console.log("quauauau")
            this.getAccounts().then(()=>{
              this.login()
            });
      }else{
        if(data=="nomask"){
            this.isLoading = false;
            this.connection_text = "No wallet"
        }else if(data == "denied_access"){
          this.isLoading = false;
          this.connection_text = "Grant access"
            //console.log("qua!=")
        }else{     
           this.isLoading = false;
            this.connection_text = "Wrong Network"
        }
      }
    });
  }

  closeDisclaimer(){
    this.showDisclaimer = false;
  }

     // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    } 
  }

   ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

  signup(){
    ////console.log('sginup')
    if(this.validateEmail(this.email) && !this.emailAlreadyUsed){
      if(this.nickname.length>0 && !this.nicknameAlreadyUsed){
        this.signupLoading=true;
        let post = {email:this.email,nickname:this.nickname, address:this.accounts[0]};
        this.http.post("https://api.kryptomon.co/auth/signup.php", post).subscribe((result:httpResult)=>{
          if(result.status=="done"){
              this.login();
            }else{
              ////console.log(result)
            }
            this.signupLoading = false;
         })
      }else{
        this.nicknameAlreadyUsed = true;
      }
    }else{
      this.emailAlreadyUsed = true;
    }
  }

  login(){
    let post = {account: this.accounts[0]}
    this.http.post("https://api.kryptomon.co/auth/login.php", post).subscribe((result:httpResult)=>{
      if(result.status=="done"){
        this.signupForm = false;
        this.checkTopSupporter();
        this.getKryptomons();
      }else if(result.status=="noaccount"){
        this.signupForm = true;
      }else{
        ////console.log(result)
      }
    })
  }

  checkTopSupporter(){
    let post = {account: this.accounts[0]}
    this.http.post("https://api.kryptomon.co/auth/isTopSupporter.php", post).subscribe((result:httpResult)=>{
      if(result.status=="done"){
        this.isTopSupporter = true;
      }else if(result.status=="team"){
         this.isTeamMember = true;
      }else{
        this.isTopSupporter = false;
      }
    })
  }

  checkEmail(){
    if(this.validateEmail(this.email)){ 
     let data = {email: this.email};
       this.http.post("https://api.kryptomon.co/auth/checkEmail.php", data).subscribe((result:httpResult)=>{
         if(result.status=="nope"){
           this.emailAlreadyUsed = true;
           this.showExtendedCard = true;
           this.alreadyUsedText = "Email";
         }else{
           this.emailAlreadyUsed = false;
           this.showExtendedCard = false;
         }
       })
     }
  }

  checkNickname(){
   if(this.nickname!=="" && this.nickname!==undefined){ 
     let data = {nickname: this.nickname};
       this.http.post("https://api.kryptomon.co/auth/checkNickname.php", data).subscribe((result:httpResult)=>{
         if(result.status=="nope"){
           this.nicknameAlreadyUsed = true;
           this.showExtendedCard = true;
           this.alreadyUsedText = "Nickname";
         }else{
           this.nicknameAlreadyUsed = false;
           this.showExtendedCard = false;
         }
       })
     }
  }

  createChart(index){
     // Chart code goes in here
    let theme = this.themeService.theme;
    this.browserOnly(() => {
       if (this.chart) {
        this.chart.dispose();
      }
      // Themes begin
      am4core.useTheme(am4themes_dark);
      am4core.useTheme(am4themes_animated);
           // Create chart instance
      let chart = am4core.create("chartdiv", am4charts.PieChart);
      chart.startAngle = 160;
      chart.endAngle = 380;

      // Let's cut a hole in our Pie chart the size of 40% the radius
      chart.innerRadius = am4core.percent(40);

      let genes = this.selectedKryptomon.genes
      var typeDraft = []
      let c = 0;
      for(let i = 0; i<8;i++){
        let sum = parseInt(genes[c])*parseInt(genes[c+1]);
        c=c+2;
        typeDraft.push(sum);
      }


for(var i = 0; i<typeDraft.length;i++){
  let data = {} as chartData
  data.typeName = this.typeFormatted[i];
  data.valueName = typeDraft[i]
  chart.data.push(data);
}

for(var i = 0; i<2;i++){
   let data = {} as chartData
   data.valueSpeciality = genes[19+i];
   if(i==0){
      data.specialityName= "Attack";
   }else{
      data.specialityName= "Defense";
   }
  
  chart.data.push(data);
}


// Add and configure Series
let pieSeries = chart.series.push(new am4charts.PieSeries());
pieSeries.dataFields.value = "valueSpeciality";
pieSeries.dataFields.category = "specialityName";
pieSeries.slices.template.stroke = new am4core.InterfaceColorSet().getFor("background");
pieSeries.slices.template.strokeWidth = 1;
pieSeries.slices.template.strokeOpacity = 1;
pieSeries.slices.template.tooltipText = "{category}: {value.percent.formatNumber('#.00')}%";
// Disabling labels and ticks on inner circle
pieSeries.labels.template.disabled = true;
pieSeries.ticks.template.disabled = true;

// Disable sliding out of slices
pieSeries.slices.template.states.getKey("hover").properties.shiftRadius = 0;
pieSeries.slices.template.states.getKey("hover").properties.scale = 1;
pieSeries.radius = am4core.percent(40);
pieSeries.innerRadius = am4core.percent(30);

let cs = pieSeries.colors;
cs.list = [am4core.color(new am4core.ColorSet().getIndex(0))];

cs.stepOptions = {
  lightness: -0.05,
  hue: 0
};
cs.wrap = false;


// Add second series
let pieSeries2 = chart.series.push(new am4charts.PieSeries());
pieSeries2.dataFields.value = "valueName";
pieSeries2.dataFields.category = "typeName";
pieSeries2.slices.template.stroke = new am4core.InterfaceColorSet().getFor("background");
pieSeries2.slices.template.strokeWidth = 1;
pieSeries2.slices.template.strokeOpacity = 1;
pieSeries2.slices.template.states.getKey("hover").properties.shiftRadius = 0.05;
pieSeries2.slices.template.states.getKey("hover").properties.scale = 1;
pieSeries2.slices.template.tooltipText = "{category}: {value.percent.formatNumber('#.00')}%";

pieSeries2.labels.template.disabled = true;
pieSeries2.ticks.template.disabled = true;


let label = chart.seriesContainer.createChild(am4core.Label);
if(theme=="dark"){
label.fill = am4core.color('#fff')
}else{
 label.fill = am4core.color('#333')
}

label.textAlign = "middle";
label.horizontalCenter = "middle";
label.verticalCenter = "middle";
label.adapter.add("text", (text, target)=>{
  var specialityLabel
  if(genes[19]>(genes[20]*1.10)){
    specialityLabel = "Attack"
  }else if(genes[20]>(genes[19]*1.10)){
    specialityLabel= "Defense";
  }else{
    specialityLabel= "Balanced";
  }
  return "[bold font-size:30px]" + this.typeFormatted[this.indexOfMax(typeDraft)] + "[/]\n[font-size:18px]"+specialityLabel+"[/]";
})
this.chart = chart
this.personalityChart = false;
  })


}

  gotoDrop(){
    this.router.navigate(['/airdrop']);
  }

  gotToMarket(){
    this.router.navigate(['/market']);
  }

  async checkClaim(){
    const claim = merkle.default.claims[this.accounts[0]];
    if(claim == undefined){
      let post = {address: this.accounts[0]}
      this.http.post("https://api.kryptomon.co/merkle/checkAddressListed.php", post).subscribe((item:httpResult)=>{
        if(item.status=="listed"){
          this.listedOnDb = true;
        }else{
          this.listedOnDb = false;
        }
      })
      this.notListed = true;
    }else{
      this.notListed = false;
      
    }
  }
  
  async getAccounts(){
      this.accounts = await this.contractService.web3.eth.getAccounts().then((accounts) => {
        //console.log(accounts)
        return accounts;
      }).catch((err) => {
        ////console.log(err, 'err!!');
      });
      if(this.accounts.length==0){
        this.connection_text = "Grant access";
      }else{
        this.connection_text = "Connected";
      }
    }

    async buyKryptomon() {
      this.isLoadingBuy = true;
      let allowance = await this.contractService.contractTokenInstance.methods.allowance(this.accounts[0],this.contractService.contractAddress).call();
      if(allowance>0){
        this.makePurchase()
      }else{
        this.contractService.contractTokenInstance.methods.approve(this.contractService.contractAddress, this.contractService.web3.utils.toWei('999999999999')).send({
        from: this.accounts[0]
      }).then((result)=>{
        if(result.events.Approval!==undefined){
          this.makePurchase()
        }else{
          this.isLoadingBuy = false;
        }
      }, (error)=>{
        //console.log(error)
        this.isLoadingBuy = false;
      })
      }
    }

    async claim() {
      this.isLoadingBuy = true;
      const claim = merkle.default.claims[this.accounts[0]];
      if(claim!==undefined){
            if(claim.isPayable){
              let allowance = await this.contractService.contractTokenInstance.methods.allowance(this.accounts[0],this.contractService.contractMerkleInstance._address).call();
              if(allowance>0){
                this.executeClaim(claim);
              }
              else{
                this.contractService.contractTokenInstance.methods.approve(this.contractService.contractMerkleInstance._address, this.contractService.web3.utils.toWei('999999999999')).send({
                  from: this.accounts[0]
                }).then((result)=>{
                  if(result.events.Approval!==undefined){
                    this.executeClaim(claim);
                  }else{
                    this.isLoadingBuy = false;
                  }
                }, (error)=>{
                  //console.log(error)
                  this.isLoadingBuy = false;
                })
              }
            }
            else{
              this.executeClaim(claim);
            }
        }else{
        //console.log('notlisted')
        this.isLoadingBuy = false;
        this.notListed = true;
      }
    }

    async executeClaim(claim){
      if(claim!==undefined){
        this.notListed = false;
        await this.contractService.contractMerkleInstance.methods.claim(claim.index, this.accounts[0], claim.generation, claim.isPayable, claim.proof).send({
          from: this.accounts[0]
        }).then((receipt) => {
          //console.log(receipt);
          if(isEmpty(receipt.events)){
            this.getKryptomons();
          }
          this.isLoadingBuy = false;
        }).catch((err) => {
          ////console.log(err, 'err');
          this.isLoadingBuy = false;
        });
      }else{
        //console.log('notlisted')
        this.isLoadingBuy = false;
        this.notListed = true;
      }
    }

    //0.01 for ETH Contract - 1 for BNB
    //this.contractService.web3.utils.toWei('1')
    makePurchase(){
      this.isLoadingBuy = true;
      this.contractService.contractInstance.methods.buyKryptomon().send({
        from: this.accounts[0]
      }).then((receipt) => {
        if(isEmpty(receipt.events)){
          this.getKryptomons();
        }
        this.isLoadingBuy = false;
      }).catch((err) => {
        ////console.log(err, 'err');
        this.isLoadingBuy = false;
      })
    }

    makePurchaseETH(){
      this.isLoadingBuy = true;
      this.contractService.contractInstance.methods.buyKryptomon().send({
        from: this.accounts[0],
        value:this.contractService.web3.utils.toWei('0.01','ether')
      }).then((receipt) => {
        if(isEmpty(receipt.events)){
          this.addKryptomonFromReceipt(receipt);
        }
        this.isLoadingBuy = false;
      }).catch((err) => {
        ////console.log(err, 'err');
        this.isLoadingBuy = false;
      })
    }

    addKryptomonFromReceipt(receipt) {
      this.kryptomons.push({
        id: receipt.events.Birth.returnValues.kryptomonId,
        genes: receipt.events.Birth.returnValues.genes,
        matron: receipt.events.Birth.returnValues.matronId,
        sire: receipt.events.Birth.returnValues.sireId,
        timeBorn: receipt.events.Birth.returnValues.timeBorn,
        status: receipt.events.Birth.returnValues.status,
        extra_data: receipt.events.Birth.returnValues.status,
        newborn:true
      });
      this.kryptomonImage();
      //////console.log(this.kryptomons)
    }

  async getKryptomons() {
    this.isLoading = true;
    this.contractService.contractInstance.methods.ownedKryptomons().call({
      from: this.accounts[0],
    }).then((receipt) => {
      //////console.log(receipt)
      if(receipt.length>0){
          for (let i = 0; i < receipt.length; i += 1) {
            this.getKryptomonDetails(receipt[i])
          }
        }
        else{
          //this.checkClaim();
          this.isLoading = false;
      }
    }).catch((err) => {
      ////console.log(err, 'err');
      this.isLoading = false;
    });
  }

 async getKryptomonDetails(receipt){
   await this.contractService.contractInstance.methods.getKryptomonDetails(receipt).call({
        from: this.accounts[0],
      }).then((kryptomon) => {
        this.kryptomons.push({
          id: kryptomon[0],
          genes: kryptomon[1],
          matron: kryptomon[2],
          sire: kryptomon[3],
          timeBorn: kryptomon[4],
          status:kryptomon[7],
          timeHatched:kryptomon[8],
          timeToHatch:kryptomon[9],
          extra_data: kryptomon[6]
        });
        this.kryptomonImage();  
      }).catch((err) => {
        ////console.log(err, 'err');
      });
 }

   async kryptomonImage(){
     for(let x = 0;x<this.kryptomons.length;x++){
          let genes = this.kryptomons[x].genes;
             /*01->Fuoco
             23->Acqua
             45->Ghiaccio
             67->Terra
             78->Aria
             910->Elettricità
             1112->Spettro
             1314->Erba*/
           let typeDraft = [];
           let type = [];
           let c = 0;
           for(let i = 0; i<8;i++){
             let sum = parseInt(genes[c])*parseInt(genes[c+1]);
             c=c+2;
             typeDraft.push(sum);
           }
           let typeSelected = this.indexOfMax(typeDraft)
           let kryptomon = {} as Kryptomons;
           kryptomon.type=(typeSelected);
           kryptomon.talent=(typeDraft[typeSelected])
           kryptomon.typeFormatted=(this.typeFormatted[typeSelected]);

           let timeBorn = new Date(this.kryptomons[x].timeBorn*1000)
           let month = timeBorn.toLocaleString('default', { month: 'short' });
           let day = timeBorn.getDate();
           let year = timeBorn.getFullYear();
           kryptomon.timeBorn = month+" "+day+" "+year;
           kryptomon.timeBornUnix = this.kryptomons[x].timeBorn;
          
           if(genes[19]>(genes[20]*1.10)){
             kryptomon.speciality = "attack";
           }else if(genes[20]>(genes[19]*1.10)){
             kryptomon.speciality = "defense";
           }else{
             kryptomon.speciality = "balance";
           }

           if(genes[22]==0){
             kryptomon.super = false;   
              var img_selected = kryptomon.speciality;
            }else{
              img_selected = 'super';
              kryptomon.super = true;
            }

           kryptomon.unfreezable = this.kryptomons[x].extra_data[0];

           //console.log(kryptomon.unfreezable)

           if(kryptomon.unfreezable==1){
              kryptomon.image = "./assets/img/top_special/"+kryptomon.typeFormatted+"/"+img_selected+".png";
           }else{
              kryptomon.image = "./assets/img/"+kryptomon.typeFormatted+"/"+img_selected+".png";
           }

           kryptomon.islandImage = "./assets/img/top_special/"+kryptomon.typeFormatted+"/island.png";

           let personality = {} as Personality
           personality.Constitution = genes[24];
           personality.Affection = genes[27];
           personality.Crazyness = genes[28];
           personality.Instinct = genes[29];
           personality.Hunger = genes[30];
           personality.Laziness = genes[31];
           personality.Braveness = genes[32];
           personality.Smart = genes[33];
           personality.Ego = genes[35];
           kryptomon.personality = personality;
           kryptomon.generation = genes[genes.length-1];
           kryptomon.id=this.kryptomons[x].id;
           this.kryptomons[x].data = kryptomon; 

            let post = {
              meta:kryptomon,
              dna: genes,
              network: this.contractService.networkName
            }

           this.http.post("https://api.kryptomon.co/json/kryptomon/checkMeta.php", post).subscribe((item:httpResult)=>{
              if(item.status=="empty"){
                 this.http.post("https://api.kryptomon.co/json/kryptomon/save_meta.php", post).subscribe((item)=>{
                  this.kryptomons[x].newborn = false;
                    this.http.get("https://api.kryptomon.co/json/kryptomon/meta/"+this.selectedKryptomon.id).subscribe((item:httpResult)=>{
                      this.selectedKryptomon.bio = item.description;
                    })
                }, (error)=>{
                  //console.log(error)
                })
              }
            }, (error)=>{
              //console.log(error)
            })
         }
         this.isLoading = false;
         this.selectKryptomon(0);
         
   }


   createPersonalityChart(){
    let theme = this.themeService.theme
      // Chart code goes in here
    this.browserOnly(() => {
      // Themes begin
      am4core.useTheme(am4themes_dark);
      am4core.useTheme(am4themes_animated);
           // Create chart instance
      var chart = am4core.create("chartdiv_personality", am4charts.RadarChart);
      chart.hiddenState.properties.opacity = 0; // thi
     let colorList = ["rgba(13, 110, 253, 1)","rgba(13, 110, 253, 0.25)","#f4f1de","#f2cc8f","#028090", "#012622","#f1f7ed","#aeadf0","#e2856e"]
     let c = 0;
      for(var key in this.selectedKryptomon.data.personality){
        let data = {} as chartData
                data.typeName = key;
                data.valueName = parseInt(this.selectedKryptomon.data.personality[key]);
                let ratio = data.valueName/100;
                var color
                if(c==0){
                  if(ratio>=0.66){
                    color = "#0CF574" //green
                  }else if(ratio>=0.33 && ratio<0.66){
                    color = "#FFBE0B" //yellow
                  }else {
                    color = "#FF5E5B" //red
                  }
                }else if(c==1){
                  if(ratio>=0.66){
                    color = "#FF5E5B" //red
                  }else if(ratio>=0.33 && ratio<0.66){
                    color = "#FFBE0B" //yellow
                  }else {
                   color = "#0CF574" //green
                  }
                }else if(c==2){
                  if(ratio>=0.66){
                    color = "#FF5E5B" //red
                  }else if(ratio>=0.33 && ratio<0.66){
                    color = "#FFBE0B" //yellow
                  }else {
                   color = "#0CF574" //green
                  }
                }else if(c==3){
                  if(ratio>=0.66){
                    color = "#0CF574" //green
                  }else if(ratio>=0.33 && ratio<0.66){
                    color = "#FFBE0B" //yellow
                  }else {
                    color = "#FF5E5B" //red
                  }
                }else if(c==4){
                  if(ratio>=0.66){
                    color = "#FF5E5B" //red
                  }else if(ratio>=0.33 && ratio<0.66){
                    color = "#FFBE0B" //yellow
                  }else {
                   color = "#0CF574" //green
                  }
                }else if(c==5){
                  if(ratio>=0.66){
                    color = "#FF5E5B" //red
                  }else if(ratio>=0.33 && ratio<0.66){
                    color = "#FFBE0B" //yellow
                  }else {
                   color = "#0CF574" //green
                  }
                }else if(c==6){
                  if(ratio>=0.66){
                    color = "#0CF574" //green
                  }else if(ratio>=0.33 && ratio<0.66){
                    color = "#FFBE0B" //yellow
                  }else {
                    color = "#FF5E5B" //red
                  }
                }else if(c==7){
                  if(ratio>=0.66){
                    color = "#0CF574" //green
                  }else if(ratio>=0.33 && ratio<0.66){
                    color = "#FFBE0B" //yellow
                  }else {
                    color = "#FF5E5B" //red
                  }
                }else if(c==8){
                  if(ratio>=0.66){
                    color = "#FF5E5B" //red
                  }else if(ratio>=0.33 && ratio<0.66){
                    color = "#FFBE0B" //yellow
                  }else {
                   color = "#0CF574" //green
                  }
                }
                ////console.log(color)
                data.config = {fill: color}
                c++;
                chart.data.push(data);
      }
      ////console.log(chart.data)

      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis<am4charts.AxisRendererCircular>());
      categoryAxis.dataFields.category = "typeName";
      categoryAxis.renderer.labels.template.location = 0.5;
      categoryAxis.renderer.grid.template.strokeOpacity = 0.1;
      categoryAxis.renderer.axisFills.template.disabled = true;
      
      if(theme=="dark"){
      //console.log('qui');
      categoryAxis.renderer.labels.template.fill = am4core.color("#FFF");
      }else{
       //console.log('quo');
      categoryAxis.renderer.labels.template.fill = am4core.color("#333");
      }

      
      //categoryAxis.mouseEnabled = false;

      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererRadial>());
      valueAxis.tooltip.disabled = true;
      valueAxis.renderer.grid.template.strokeOpacity = 0.05;
      valueAxis.renderer.axisFills.template.disabled = true;
      valueAxis.renderer.axisAngle = 260;
      valueAxis.renderer.labels.template.horizontalCenter = "right";
      valueAxis.min = 0;
      valueAxis.max = 100;


      var series1 = chart.series.push(new am4charts.RadarColumnSeries());
      series1.columns.template.radarColumn.strokeOpacity = 0;
      series1.name = "Personality";
      series1.dataFields.categoryX = "typeName";
      series1.columns.template.tooltipText = "Trait {categoryX}: {valueY.value}";
      series1.dataFields.valueY = "valueName";
      series1.stacked = true;
      series1.columns.template.radarColumn.configField = 'config';



      var start = 0.5;
      chart.startAngle = 160;
      chart.endAngle = 380;
      chart.paddingTop = 25;
      chart.paddingLeft = 75;
      chart.paddingRight = 75;
      valueAxis.renderer.axisAngle = chart.startAngle;
      chart.radius = am4core.percent(80);
      chart.innerRadius = am4core.percent(60);
      chart.scale = 1;

      if(screen.width<800){
        categoryAxis.disabled = true;
        valueAxis.disabled = true;
         chart.paddingLeft = 0;
      chart.paddingRight = 0;
      }

      let label = chart.seriesContainer.createChild(am4core.Label);
      if(theme=="dark"){
      label.fill = am4core.color('#fff')
      }else{
       label.fill = am4core.color('#333')
      }

      label.textAlign = "middle";
      label.horizontalCenter = "middle";
      label.verticalCenter = "middle";
      label.adapter.add("text", (text, target)=>{
        return "[bold font-size:30px]#" + this.selectedKryptomon.data.generation+ "[/]\n[font-size:18px]Generation[/]";
      })

      this.chart = chart
      this.personalityChart = true;
   })


 }

  /*transferTo(){
    this.contractService.contractInstance.methods.safeTransferFrom(this.accounts[0], "0x1C8a04E855ED66913eE9521C3E522dec30F2aeE8", this.selectedKryptomon.id).send({
      from:this.accounts[0]
    }
    ).then((data)=>{
      ////console.log(data);
    }).catch((err) => {
        ////console.log(err, 'err');
      });
   }*/

   /*getOwnerList(){
     this.contractService.contractInstance.methods.getOwnerList(this.selectedKryptomon.id).call({
      from:this.accounts[0]
    }
    ).then((data)=>{
      ////console.log(data);
    }).catch((err) => {
        ////console.log(err, 'err');
      });
   }

   setAttribute(){
     this.contractService.contractInstance.methods.updateAttribute(this.selectedKryptomon.id).send({
      from:this.accounts[0]
    }
    ).then((data)=>{
      ////console.log(data);
    }).catch((err) => {
        ////console.log(err, 'err');
      });
   }

  
  

  setTeam(){
    ////console.log(this.accounts[0])
     this.contractService.contractInstance.methods.addMember("0x4A6bE10ef1b286A7A0815F814cde64C0d83De244").send({
      from:this.accounts[0]
    }
    ).then((data)=>{
      ////console.log(data);
    }).catch((err) => {
        ////console.log(err, 'err');
      });
   }

   createKryptomonOnRequest(){
    ////console.log(this.accounts[0])
     this.contractService.contractInstance.methods.createKryptomonOnRequest("0x1C8a04E855ED66913eE9521C3E522dec30F2aeE8").send({
      from:this.accounts[0]
    }
    ).then((data)=>{
      ////console.log(data);
    }).catch((err) => {
        ////console.log(err, 'err');
      });
   }


  payout(){
    this.contractService.contractInstance.methods.payout().send({
      from:this.accounts[0]
    }).then((data)=>{
      ////console.log(data);
    }).catch((err) => {
        ////console.log(err, 'err');
      });
  }

   setTeam(){
    ////console.log(this.accounts[0])
     this.contractService.contractInstance.methods.addMember("0x4A6bE10ef1b286A7A0815F814cde64C0d83De244").send({
      from:this.accounts[0]
    }
    ).then((data)=>{
      ////console.log(data);
    }).catch((err) => {
        ////console.log(err, 'err');
      });
   }
   

   setAttribute(){
     this.contractService.contractInstance.methods.updateAttribute(this.selectedKryptomon.id).send({
      from:this.accounts[0]
    }
    ).then((data)=>{
      ////console.log(data);
    }).catch((err) => {
        ////console.log(err, 'err');
      });
   }
   */

  connectDisconnectProvider(){
  if(this.connection_text=="Connected"){
    this.contractService.disconnectProvider();
  }else{
    this.showModalConnect();
  }
}
  
  hexToStr(hex) {
  var str = '';
  for (var i = 0; i < hex.length; i += 2) {
     var v = parseInt(hex.substr(i, 2), 16);
     if (v) str += String.fromCharCode(v);
  }

    var params = [];
    var res = "";
  for (var i=0; i<= str.length; i++){
    if(str.charCodeAt(i) > 31){
      res = res + str[i];
    }
    else{
      params.push(res);
      res = "";
    }
  }
  params.pop();

  return params.reverse();
}

indexOfMax(arr) {
    if (arr.length === 0) {
        return -1;
    }

    var max = arr[0];
    var maxIndex = 0;

    for (var i = 1; i < arr.length; i++) {
        if (arr[i] > max) {
            maxIndex = i;
            max = arr[i];
        }
    }

    return maxIndex;
}

selectKryptomon(index){
  this.shake = false;
	this.selectedKryptomon = this.kryptomons[index];
  //this.app.start(this.selectedKryptomon.data.renderedImage);

	for(var i = 0; i<this.kryptomons.length;i++){
		if(i==index){
			this.kryptomons[i].selected = true;
		}else{
			this.kryptomons[i].selected = false;
		}
	}
console.log(this.selectedKryptomon.id)
this.http.get("https://api.kryptomon.co/json/kryptomon/meta/"+this.selectedKryptomon.id).subscribe((item:httpResult)=>{
  this.selectedKryptomon.bio = item.description;
})

////console.log(this.kryptomons)
setTimeout(()=>{
  this.createChart(this.selectedKryptomon)
  this.createPersonalityChart()
  this.shake = true;
  setTimeout(()=>{
    this.shake = false;
  },821)
},10)
  
}

closeShare(){
  this.showShare = false;
}

validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

startConfetti(){
   tsParticles.load("tsparticles", {
  fpsLimit: 60,
  particles: {
    number: {
      value: 0
    },
    color: {
      value: ["#00FFFC", "#FC00FF", "#fffc00"]
    },
    shape: {
      type: "confetti",
      options: {
        confetti: {
          type: ["circle", "square"]
        }
      }
    },
    opacity: {
      value: 1,
      animation: {
        enable: true,
        minimumValue: 0,
        speed: 2,
        startValue: "max",
        destroy: "min"
      }
    },
    size: {
      value: 7,
      random: {
        enable: true,
        minimumValue: 3
      }
    },
    links: {
      enable: false
    },
    life: {
      duration: {
        sync: true,
        value: 5
      },
      count: 1
    },
    move: {
      enable: true,
      gravity: {
        enable: true,
        acceleration: 20
      },
      speed: 20,
      decay: 0.1,
      direction: "none",
      random: false,
      straight: false,
      outModes: {
        default: "destroy",
        top: "none"
      }
    }
  },
  interactivity: {
    detectsOn: "window",
    events: {
      resize: true
    }
  },
  detectRetina: true,
  background: {
    color: "#232123"
  },
  emitters: {
    direction: "none",
    life: {
      count: 0,
      duration: 0.1,
      delay: 0.4
    },
    rate: {
      delay: 0.1,
      quantity: 100
    },
    size: {
      width: 0,
      height: 0
    }
  }
});
}

}

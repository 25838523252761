<div class="update-overlay" *ngIf="updating">
  <img src="https://kryptomon-images.ams3.cdn.digitaloceanspaces.com/images/kryptomons/gif/kmon_0_gif.gif"/>
  Update is coming...
</div>
<div class="row content_display dashboard" *ngIf="!updating">
  <div class="col" *ngIf="!hasKryptomonV2 && !hasKryptomonV1">
    <div class="card">
      <div class="card-content invite-box">
        <img src="https://kryptomon-images.ams3.digitaloceanspaces.com/images/kryptomons/gif/kmon_0_gif.gif"/>
        <p>You don't have any Kryptomon in this wallet, switch wallet or get one!</p>
        <div class="egg-action-button" (click)="openMarketPlace()">
          Get a Kryptomon!
        </div>
      </div>
    </div>
  </div>
  <div class="col" *ngIf="!hasKryptomonV2 && hasKryptomonV1">
    <div class="card">
      <div class="card-content invite-box">
        <img src="https://kryptomon-images.ams3.digitaloceanspaces.com/images/kryptomons/gif/kmon_0_gif.gif"/>
        <p>In order to play you need to migrate your Kryptomon to V2</p>
        <div class="egg-action-button" (click)="openKryptomons()">
         Go to dashboard
        </div>
      </div>
    </div>
  </div>
  <div class="col invite-col" *ngIf="hasKryptomonV2">
    <div class="card card-alert" *ngIf="hasKryptomonV1">
       <div class="card-content invite-box">
        <p>If you still have some Kryptomons on V1, you will not be able to play with them until you migrate them to V2.</p>
      </div>
    </div>
    <div class="game-frame">
      <iframe src="https://kryptomon-web.nyc3.cdn.digitaloceanspaces.com/index.html" allowfullscreen=true webkitallowfullscreen=true mozallowfullscreen=true></iframe> 
    </div>
    <!-- <div class="actions-boxes">
      <div class="action-box">
        <div class="action-header">
          <img src="./assets/img/Home/k-logo.png"/>
          <p>MARKET</p>
        </div>
        <div class="action-content market">
          <p>MARKET<br>PLACE</p>
          <img src="./assets/img/Home/market_itempanel_img.png"/>
        </div>
        <div class="action-footer">
          <p>Now Live</p>
          <p class="soon" (click)="openMarketPlace()">Shop <fa-icon [icon]="['fas', 'caret-right']"></fa-icon></p>
        </div>
      </div>
      <div class="action-box comic">
        <div class="action-header">
          <p>Comic Book</p>
        </div>
        <div class="action-content">
           <p>EVA'S<br>JOURNEY</p>
          <img src="./assets/img/Home/comicbook_img.png"/>
        </div>
        <div class="action-footer">
           <p>Out Now!</p>
          <p class="soon" (click)="openComic()">Read <fa-icon [icon]="['fas', 'caret-right']"></fa-icon></p>
        </div>
      </div>
      <div class="action-box hunt">
        <div class="action-header">
          <img src="./assets/img/Home/logo-extended.png"/>
        </div>
        <div class="action-content">
           <p>TREASURE<br>HUNT</p>
          <img src="./assets/img/Home/moon_img.png"/>
        </div>
        <div class="action-footer">
           <p>16 May</p>
          <p class="soon" (click)="openHunt()">Learn More <fa-icon [icon]="['fas', 'caret-right']"></fa-icon></p>
        </div>
      </div>
    </div> -->
  </div>
</div>
<div class="row content_display dashboard-mobile" *ngIf="!updating">
  <div class="col">
    <div class="card">
      <div class="card-content">
        <p>Game is not mobile optimized yet, open this page from a PC</p>
      </div>
    </div>
  </div>
</div>

<div class="section-container-details">
  <div class="card-details">
    <div class="back-button" (click)="goBack()">
      <fa-icon [icon]="['fas', 'chevron-left']"></fa-icon>
      <span>Back</span>
    </div>
    <div class="card-details-header">
      <div class="header-boxed">
        #{{kryptomon.data.id}}
      </div>
      <!--<div class="header-boxed">
			</div>-->
      <div class="header-boxed">
        {{ account }}
      </div>
      <div class="header-boxed" *ngIf="kryptomon.data.unfreezable!==undefined && kryptomon.data.unfreezable!=='0'">
        {{ kryptomon.data.unfreezable }}
      </div>
      <div class="header-boxed">
        {{ kryptomon.data.special }}
      </div>
    </div>
    <div class="card-details-upbox">
      <div class="card-details-summary">
        <div class="summary-id" *ngIf="(kryptomon.status==0 || kryptomon.data.name==undefined) && kryptomon.data.id!=='6188'">
          #{{kryptomon.data.id}}
        </div>
        <div class="summary-id" *ngIf="((kryptomon.status>0 && kryptomon.data.name!=undefined) && !showTextInput) || kryptomon.data.id=='6188'" (click)="showTextInput = !showTextInput">
          {{kryptomon.data.name}}
        </div>
        <div class="summary-id-input" *ngIf="(kryptomon.status>0 && kryptomon.data.name!=undefined) && showTextInput">
          <input type="text" [(ngModel)]="kryptomonName" autofocus (blur)="saveName()" (keydown.enter)="saveName()"/>
        </div>
        <div class="summary-profile">
          <div class="profile-items">
            <div class="profile-item">
              Gender:
            </div>
            <div class="profile-item">
              Generation:
            </div>
            <div class="profile-item">
              Born:
            </div>
            <div class="profile-item">
              Element:
            </div>
          </div>
          <div class="profile-items">
            <div class="profile-item">
              {{kryptomon.data.sex}}
            </div>
            <div class="profile-item">
              {{kryptomon.data.generation}}
            </div>
            <div class="profile-item">
              {{kryptomon.data.timeBorn}}
            </div>
            <div class="profile-item">
              {{kryptomon.data.typeFormatted}}
            </div>
          </div>
        </div>
        <div class="summary-element">
          <div class="summary-element-icon" (click)="showBreakDown = !showBreakDown">
            <img src="{{kryptomon.data.iconImage}}">
          </div>
          <div class="summary-element-text">
            <p>{{kryptomon.data.typeFormatted}}</p>
            <p>{{kryptomon.data.elementPercentage}}%</p>
          </div>
          <div class="summary-element-breakdown" *ngIf="showBreakDown && kryptomon.data.id!=='6188'">
          	<div class="summary-element-breakdown-header">
          		<p>Element Chart</p>
          		<fa-icon [icon]="['fas', 'times']" (click)="showBreakDown = !showBreakDown"></fa-icon>
          	</div>
          	<div class="summary-element-breakdown-content">
          		<div class="summary-element-breakdown-item" *ngFor="let e of kryptomon.data.elementBreakDown">
          			<div class="summary-element-breakdown-item-image">
          				<img src="{{e.image}}">
          			</div>
          			<div class="summary-element-breakdown-item-text">
          				<p>{{e.typeName}}</p>
          				<p>{{e.percentage}}%</p>
          			</div>
          		</div>
          	</div>
          </div>
        </div>
      </div>
      <div class="card-details-image">
        <img src="{{kryptomon.data.image}}" *ngIf="kryptomon.status==0"/>
        <img src="{{kryptomon.data.gif}}" *ngIf="kryptomon.status>0"/>
      </div>
    </div>
    <div class="card-details-description">
      <p>{{kryptomon.data.bio}}</p>
    </div>
    <div class="card-details-chart" [ngClass]="{'extend' : extendChart}">
      <div class="tabs">
        <div class="tab" (click)="selectVersion(0)" [ngClass]="{'selected':versionSelected[0]}">
          V2
        </div>
      </div>
      <div class="chart-items" *ngFor="let a of attributesFormatted">
        <div class="chart-item item-first">
          {{a.text}}
        </div>
        <div class="chart-item item-second">
          {{a.value}}
        </div>
        <div class="chart-item item-third" *ngIf="kryptomon.id!=='6188'">
          <div class="progress">
            <div class="progress-value" [style.width.%]="a.value"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-details-chart-action" (click)="extendChart = !extendChart">
      <fa-icon [icon]="['fas', 'chevron-down']" *ngIf="!extendChart"></fa-icon>
      <fa-icon [icon]="['fas', 'chevron-up']" *ngIf="extendChart"></fa-icon>
    </div>
    <div class="card-details-footer">
    </div>
  </div>
</div>

<div class="row section">
  <div class="logo">
    <img class="first-img" src="./assets/img/Home/Group 5.svg" (click)="goHome()" />
  </div>
	<div id="tsparticles"></div>
  <div class="col">
    <div class="card">
      <div class="form" *ngIf="userObject==undefined">
      	<div class="form_img">
      	<img src="./assets/img/Air/super.png"/>
      </div>
      <p>Leaderboard claims are closed, congrats to all that have made it in time!</p>
       <!-- <p>Insert your email</p>
        <input type="text" placeholder="your@email.com" [(ngModel)]="email"/>
        <div class="send_button" (click)="checkScore()" *ngIf="!loading">
          <fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon>
        </div>
        <div class="lds-ripple" *ngIf="loading">
            <div></div>
            <div></div>
          </div>-->
      </div>
      <div class="rank_card" *ngIf="userObject!==undefined">
      	<h1 *ngIf="eggGen!==999">#{{userObject.position}}</h1>
        <h1 *ngIf="eggGen==999">Sad...</h1>
      	<p *ngIf="eggGen!==999">👋 This is your position in the leaderboard, meaning that you will receive a Gen{{eggGen}} Egg!</p>
      	<p *ngIf="eggGen==999">👋 Unfortunately you are not elegible for the free aidrop! And the Egg Sale is closed.</p>    
      </div>
      
    </div>
    <div class="card" *ngIf="userObject!==undefined && eggGen!=999">
    	<div class="rank_card">
      	 <div (click)="gotoDrop()" class="signup-btn">Whitelist your address!</div>
      </div>
    </div>
    <div class="card" *ngIf="error">
    	<div class="rank_card">
      	<p>Email not found, try again or <a href="https://forms.gle/YrWwmFyTcZNVeTxSA">fill this form</a> so we can check it for you.</p>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import contractAbiJson from "./abi/abi.json";
import contractAbiV4Json from "./abi/abiV4.json";
import contractAbiDiamond from "./abi/abiDiamond.json";
import contractAbiETHJson from "./abi/abi-eth.json";
import tokenAbiJson from "./abi/abi-token.json";
import merkleClaimAbi from "./abi/abi-merkle-claim.json";
import merkleAirdrop from "./abi/MerkleDistributor.json";
import lotteryAbiJson from "./abi/Lottery.json";
import merkleMBSaleAbi from "./abi/MBSale.json"
import stakingRewardsAbiJson from "./abi/StakingRewards.json";
import pancakeV2Pair from "./abi/PancakeV2Pair.json";
import redeemNFTABI from "./abi/RedeemNFT.json";
import ERC721 from "./abi/ERC721.json";

import WalletConnectProvider from "@walletconnect/web3-provider";
//import Fortmatic from "fortmatic";
//import Torus from "@toruslabs/torus-embed";

import Web3Modal from "web3modal";
declare const window
//const Web3 = require("web3");

//  You have to refer to default since it was bundled for ESModules
// but after that the documentation will be the same


//console.log(contractAbi)
const contractAbi = contractAbiJson;
const contractAbiETH = contractAbiETHJson;
const tokenAbi = tokenAbiJson;
const merkleAbi = merkleClaimAbi;
const lotteryAbi = lotteryAbiJson;
const stakingRewardsAbi = stakingRewardsAbiJson
//0xf1932BFFd3Fcf6218f9EB853442be97736ac705a - eth
//0x4f137AcD6C5C300280eF08bCd32E5C33637EcC35 - bnb

// PRODUCTION ADDRESSES
const contractAddress = "0xc33d69a337b796a9f0f7588169cd874c3987bde9";
const tokenAddress = "0xc732b6586a93b6b7cf5fed3470808bc74998224d";
const merkleClaimAddress = "0xaC511e5957a8ACA1f7A946E96268Bf84F44b5EFC";
const merkleClaimAddress2 = "0x82Fdc5A0A1eBe363EeA589f6E514E4d1b2175D26";
const merkleClaimAddress3 = "0x434Dcd4c89A65B1d844Fb3a5976D410207cD8b88";
const merkleClaimAddress4 = "0x841a2d88cDC528aABF100A0A5A386d53f6DCf8A6";
const merkleAirdropClaimAddress = "0x2A2DdF52eFEE31083C9557603B5D126DF7096f8a"; //"0x133bEE86134D346788993a4BcD9b0F2f1F30183f";
const merkleLoreAddress = "0x1830F3E3A7806718BE9D09a586421F163802d686";
const merkleLoreAddress2 = "0xF76034aF005603cF653Bc06c37f0EBdec377DC57";
const lotteryAddress = "0x7A16658f04c32D2df40726E3028b600D585D99A5";
const oldLotteryAddress = "0xDDcDF8619d27cBbF12070f3B7cbE5fDe17429622";
const rewardsAddress = "0xC43CA243d562939aF0778f06246B17efc97D3B5e";
const newRewardsAddress = "0x16108bcDC5C52a426F823FBF83aBFde763E91ABf";
const newRewardsAddressLP = "0xdBb47Cc765B9656e8C18fa975662CC3EbF778548";
const LPAddress = "0x9dFf2991f531c44CE5E484bC37589D6A18d68F5E";

//MetaMask #DEEPLINK ADDRESS FOR MOBILE DEVICES
const deeplink = "https://metamask.app.link/dapp/app.kryptomon.co/";

//const Web3Modal = window.Web3Modal.default;
const providerOptions = {
  /* See Provider Options Section */
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      rpc: { 56: 'https://bsc-dataseed1.defibit.io/', },
      network: "binance",
    }
  }
  // Other Providers Go Here
};
//cacheProvider:true,
const web3Modal = new Web3Modal({
  providerOptions, cacheProvider: true // required
});

const getWeb3 = () => new Promise(async (resolve) => {
  const provider = await web3Modal.connect().catch(() => {
    resolve("denied_access");
  });
  let currentWeb3;
  if (provider) {
    currentWeb3 = new window.Web3(provider);
    try {
      console.log("1")
      // Request account access if needed

      provider.enable()
      window.web3 = currentWeb3
      // Acccounts now exposed
      resolve(currentWeb3);
    } catch (error) {
      console.log(error)
      // User denied account access...
      resolve("denied_access");
      alert('Please allow access for the app to work');
    }
  } else if (window.web3) {
    console.log("2")
    window.web3 = new window.Web3(provider);
    // Acccounts always exposed
    resolve(currentWeb3);
  } else {
    resolve("nomask");
    console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
  }
});

@Injectable({
  providedIn: 'root'
})
export class ContractService {
  private web3js: any;
  private provider: any;
  private accounts: any;
  public web3
  public contractInstance;
  public contractInstanceV2;
  public contractTokenInstance;
  public contractMerkleInstance;
  public contractAirdropMerkleInstance;
  public contractAirdropMerkleInstance2
  public contractMerkleInstance2;
  public contractMerkleInstance3;
  public contractMerkleInstance4;
  public contractMerkleEggHunt;
  public contractMerkleEggHunt2;
  public contractMerkleLoreInstance;
  public contractMerkleLoreInstance2;
  public contractBuyMysteryBoxMerkleInstance;
  public contractBuyTokenInstance;
  public contractCryptoCom;
  public contractCryptoCom2;
  public account
  public isLoading
  public isMounted
  public networkName
  public contractAddress
  public contractLotteryInstance;
  public contractOldLotteryInstance;
  public contractRewardsInstance;
  public rewardsAddress
  public lotteryAddress
  public newContractRewardsInstance
  public newRewardsAddress
  public newRewardsAddressLP
  public newContractRewardsInstanceLP
  public contractLPInstance
  public contractRedeemNFTInstance;
  public contractPartnerInstance;
  public contractRedeemNFTInstance2;
  public contractPartnerInstance2;
  public apiEndPoint;
  public merkleAirdrop;
  public tokenAddress;
  public merkleAbi;
  public contractMerkleMB;
  public contractMBPurchaseToken;

  constructor() {
    this.contractAddress = contractAddress;
    this.rewardsAddress = rewardsAddress;
    this.newRewardsAddress = newRewardsAddress
    this.newRewardsAddressLP = newRewardsAddressLP;
    this.isMounted = false;
    this.merkleAirdrop = merkleAirdrop;
    this.tokenAddress = tokenAddress;
    this.merkleAbi = merkleAbi;
  }

  async disconnectProvider() {
    await web3Modal.clearCachedProvider();
    location.reload();
  }

  async mount() {
    this.isMounted = true;
    this.web3 = await getWeb3().then((res) => {
      return res;
    });
    if (this.web3.eth !== undefined) {
      this.web3.currentProvider.on('accountsChanged', (accounts) => {
        location.reload();
      })
      this.web3.currentProvider.on('networkChanged', (accounts) => {
        location.reload();
      })
      this.networkName = await this.web3.eth.getChainId().then(netId => {
        switch (netId) {
          case 1:
            return "Main";
            break;
          case 2:
            return "Morden";
            break;
          case 3:
            return "Ropsten";
            break;
          case 4:
            return "Rinkeby";
            break;
          case 42:
            return "Kovan";
            break;
          case 56:
            return "BinanceSmartProd";
            break;
          case 97:
            return "BinanceSmart";
            break;
          default:
            return "Unknown";
        }
      })
      if (this.networkName == "BinanceSmartProd") {
        this.contractInstance = await new this.web3.eth.Contract(contractAbiV4Json, contractAddress);
        this.contractInstanceV2 = await new this.web3.eth.Contract(contractAbiDiamond, "0x04B0f7d5CB2CE4688497f2525748FB7A9aFFa394");
        this.contractTokenInstance = await new this.web3.eth.Contract(tokenAbi, tokenAddress);
        this.contractMerkleInstance = await new this.web3.eth.Contract(merkleAbi, merkleClaimAddress);
        this.contractMerkleInstance2 = await new this.web3.eth.Contract(merkleAbi, merkleClaimAddress2);
        this.contractMerkleInstance3 = await new this.web3.eth.Contract(merkleAbi, merkleClaimAddress3);
        this.contractMerkleInstance4 = await new this.web3.eth.Contract(merkleAbi, merkleClaimAddress4);
        this.contractMerkleEggHunt = await new this.web3.eth.Contract(merkleAbi, "0xa09A961810f1B669519Db532B0Ef387721C38bD9");
        this.contractMerkleEggHunt2 = await new this.web3.eth.Contract(merkleAbi, "0xac20bD6a793EBdBb25342D4D34E09e1ae1BbbE54");
        this.contractAirdropMerkleInstance = await new this.web3.eth.Contract(merkleAirdrop.abi, "0xE2eFE4B6130862DA80bFf1e796702b0C6bf2Fb1e");
        this.contractLotteryInstance = await new this.web3.eth.Contract(lotteryAbi, lotteryAddress);
        this.contractOldLotteryInstance = await new this.web3.eth.Contract(lotteryAbi, oldLotteryAddress);
        this.contractRewardsInstance = await new this.web3.eth.Contract(stakingRewardsAbi, rewardsAddress);
        this.newContractRewardsInstance = await new this.web3.eth.Contract(stakingRewardsAbi, newRewardsAddress);
        this.contractMerkleLoreInstance = await new this.web3.eth.Contract(merkleAbi, merkleLoreAddress)
        this.contractMerkleLoreInstance2 = await new this.web3.eth.Contract(merkleAbi, merkleLoreAddress2)
        this.newContractRewardsInstanceLP = await new this.web3.eth.Contract(stakingRewardsAbi, newRewardsAddressLP);
        this.contractLPInstance = await new this.web3.eth.Contract(pancakeV2Pair, LPAddress);
        this.contractRedeemNFTInstance = await new this.web3.eth.Contract(redeemNFTABI, '0x1a1E83b6F7C39c17Bb507fbBA609e148ab50C31A')
        this.contractPartnerInstance = await new this.web3.eth.Contract(ERC721, '0xC4bdb04878e1B0c872bC1152751a16a968001a00')
        this.contractRedeemNFTInstance2 = await new this.web3.eth.Contract(redeemNFTABI, '0xf32e8288F1bDC30EDb7d7CABfdcAFe669E7845f8')
        this.contractPartnerInstance2 = await new this.web3.eth.Contract(ERC721, '0x9C4E29a6bC9759219b79E31cAcb7d5c526dAb007')
        this.contractBuyMysteryBoxMerkleInstance = await new this.web3.eth.Contract(merkleAbi, '0xf1f7474E30e72Bf8de26c8A2b67a9d0ebF5FcBFe');
        this.contractBuyTokenInstance = await new this.web3.eth.Contract(tokenAbi, '0xe9e7cea3dedca5984780bafc599bd69add087d56');
        this.contractAirdropMerkleInstance2 = await new this.web3.eth.Contract(merkleAirdrop.abi, "0x3d89d198698B5c9C4C85FB62Cd9C24b5747c040B");
        this.apiEndPoint = "https://api-v2.kryptomon.co";
        this.contractCryptoCom = await new this.web3.eth.Contract(merkleAbi, "0x842f58f009ca1E7053993967b801e7930D34b579");
        this.contractCryptoCom2 = await new this.web3.eth.Contract(merkleAbi, "");
        this.contractMerkleMB = await new this.web3.eth.Contract(merkleMBSaleAbi, "0x34746AE9A87A7e42D3f98Ffe46Df8D7D9d79dA4c");
        const purchaseToken = await this.contractMerkleMB.methods.paymentToken().call();
        this.contractMBPurchaseToken = await new this.web3.eth.Contract(tokenAbi, purchaseToken);
      } else if (this.networkName == "Rinkeby") {
        this.contractInstance = await new this.web3.eth.Contract(contractAbiV4Json, "0xBc0741f3EC450406Bb8DCD24c3eEd8549678d868");
        this.contractInstanceV2 = await new this.web3.eth.Contract(contractAbiDiamond, "0x5eA74A7105f9c2628AbE80D3Af22Afb1d7CE9A46");
        this.contractMerkleEggHunt = await new this.web3.eth.Contract(merkleAbi, "0x10c96cB5743E888D320bd245956c6E0C904bD58e");
        this.contractTokenInstance = await new this.web3.eth.Contract(tokenAbi, "0x9ec8ae8732a1ad3240a773617ca9048cc4cf606a");
        this.contractMerkleInstance = await new this.web3.eth.Contract(merkleAbi, "0xEE32A3F6C239ea7E8FB0672b4B2b7Db67bd310D5");
        this.contractMerkleInstance2 = await new this.web3.eth.Contract(merkleAbi, "0xEE32A3F6C239ea7E8FB0672b4B2b7Db67bd310D5");
        this.contractAirdropMerkleInstance = await new this.web3.eth.Contract(merkleAirdrop.abi, "0x358A7a85Ace067195bE3A61D1Dc0ae0Ce10F8A24");
        this.contractLotteryInstance = await new this.web3.eth.Contract(lotteryAbi, "0xc825F037C0957937B8300Ce14662ac559424c44d");
        this.contractBuyTokenInstance = await new this.web3.eth.Contract(tokenAbi, '');
        this.contractRewardsInstance = await new this.web3.eth.Contract(stakingRewardsAbi, "0xe2490Fe356c6Bb695c8823c79e25945EeD440918");
        this.apiEndPoint = "https://api-staging-n7ni4.ondigitalocean.app";
        this.contractMerkleMB = await new this.web3.eth.Contract(merkleMBSaleAbi, "0x34746AE9A87A7e42D3f98Ffe46Df8D7D9d79dA4c");
        const purchaseToken = await this.contractMerkleMB.methods.paymentToken().call();
        this.contractMBPurchaseToken = await new this.web3.eth.Contract(tokenAbi, purchaseToken);
        //this.contractRedeemNFTInstance = await new this.web3.eth.Contract(redeemNFTABI, '')
        this.contractCryptoCom = await new this.web3.eth.Contract(merkleAbi, "0x1A4b031C1AC66C82F86B5263ce6240510FE358be");
        this.contractCryptoCom2 = await new this.web3.eth.Contract(merkleAbi, "0x9Fd8aab80DdEB773868Fe19CB665084bf54bB6F9");
      } else if (this.networkName == "BinanceSmart") { 
        this.contractInstance = await new this.web3.eth.Contract(contractAbiV4Json, "0xe65bAF422bB854F71056E6Ff3B74E819d77b859f");
        this.contractTokenInstance = await new this.web3.eth.Contract(tokenAbi, "0x067B7d96bff6c924Fd1397535fb9e753861bd952");
        this.contractLotteryInstance = await new this.web3.eth.Contract(lotteryAbi, "0x78ca555c5397719BDFF2ce2f329764ED824e9847");
        this.contractRewardsInstance = await new this.web3.eth.Contract(stakingRewardsAbi, "0xa5Ac40f9De1deb41Ef1D86dbDf668233a82C1402");
        this.contractRedeemNFTInstance = await new this.web3.eth.Contract(redeemNFTABI, '0xB23037c569798C9b3B2D9c41024eDd76C548a8bF')
        this.contractPartnerInstance = await new this.web3.eth.Contract(ERC721, '0x4c2E7B35342B2a6A9c33C67E9bF24C2249f855FB')
        this.contractMerkleMB = await new this.web3.eth.Contract(merkleMBSaleAbi, "0xb55eb5343BD2B6CD821C089781976ACbA1D3404D");
        const purchaseToken = await this.contractMerkleMB.methods.paymentToken().call();
        console.log(`purchase token: ${purchaseToken}`)
        this.contractMBPurchaseToken = await new this.web3.eth.Contract(tokenAbi, purchaseToken);
      } else {
        const params = [{
          chainId: '0x38',
          chainName: 'Smart Chain',
          nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18
          },
          rpcUrls: ['https://bsc-dataseed.binance.org/'],
          blockExplorerUrls: ['https://bscscan.com']
        }]
      
        window.ethereum.request({ method: 'wallet_addEthereumChain', params })
          .then(() => console.log('Success'))
          .catch((error: Error) => console.log("Error", error.message));
        
        return 'wrong_network'
      }
    } else {
      return this.web3;
    }
  }
}
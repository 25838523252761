import { Component, OnInit, ViewEncapsulation, Input, ElementRef, ViewChild } from '@angular/core';
import {ContractService} from '../../services/contract.service';
import {AppComponent} from '../../app.component'
import {Router, NavigationEnd} from '@angular/router'
import {SimpleGlobal} from 'ng2-simple-global';
import { HttpClient } from "@angular/common/http";


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})



export class DashboardComponent implements OnInit {

  @ViewChild('videoPlayer') videoplayer: ElementRef;

  public account
  public navigationSelected = [true, false, false, false];
  public showVideo
  public hideVideoCover

  public hasHunterBadge
  public hasEarlySupportBadge
  public validUuid
  public inviteLink

  constructor(public http:HttpClient, public contractService:ContractService, public appComponent:AppComponent, public router:Router, public sg:SimpleGlobal) {

     this.showVideo = true;
     this.hideVideoCover = false;

     this.appComponent.accountsObservable.subscribe((item)=>{
       this.account = item[0];
       this.getBadges()
     })

     router.events.subscribe((val) => {

      if(val instanceof NavigationEnd) {
        if(val.url.indexOf('eggs')>=0 && val.url.indexOf('eggsV2') < 0){
          this.navigationSelected = [true, false, false, false];
        }else if(val.url.indexOf('kryptomons')>=0 && val.url.indexOf('kryptomonsV2') < 0){
          this.navigationSelected = [false, true, false, false];
        }
        else if(val.url.indexOf('eggsV2')>=0){
          this.navigationSelected = [false, false, true, false];
        }
        else if(val.url.indexOf('kryptomonsV2')>=0){
          this.navigationSelected = [false, false, false, true];
        }
      }

    })

     this.hasSeenVideo()

  }

  ngOnInit(): void {
    const dropdownArrow = document.querySelector(".dropdown-arrow");
    const navItems = document.getElementsByClassName("nav-item");
     dropdownArrow.addEventListener("click", ()=>{
       for (let i = 0; i < navItems.length; i++){
         navItems[i].classList.contains("show") ? navItems[i].classList.remove("show") : navItems[i].classList.add("show")
       }
    })
  }

  navigateInternally(index){
    
    for(let i = 0 ; i<this.navigationSelected.length;i++){
      if(i == index){
        this.navigationSelected[i] = true;
      }else{
        this.navigationSelected[i] = false;
      }
    }

    if(index==0){
      this.router.navigate(['/eggs']);
      this.closeDropdown()
    }else if(index==1){
      this.router.navigate(['/kryptomons'])
      this.closeDropdown()
    }else if(index==2){
      this.router.navigate(['/eggsV2'])
      this.closeDropdown()
    }
    else if(index==3){
      this.router.navigate(['/kryptomonsV2'])
      this.closeDropdown()
    }
  }

  hasSeenVideo(){
    let cookie = window.localStorage.getItem('video');
    if(cookie!==null && cookie !== undefined && cookie!==''){
      this.showVideo = false;
    }else{
      this.showVideo = true;
    }
  }

  saveVideoCookie(){
   window.localStorage.setItem('video','yes');
   this.showVideo = false;
  }

  playVideo(){
    this.hideVideoCover = true;
    this.videoplayer.nativeElement.play();
  }

  getBadges(){
    let post = {address:this.account};
    this.http.post("https://api.kryptomon.co/internal/getHuntBadge.php", post).subscribe((item:any)=>{
      console.log(item);
      if(item.status_hunt==true){
        this.hasHunterBadge = true;
      }else{
        this.hasHunterBadge = false;
      }
      if(item.status_supporter==true){
        this.hasEarlySupportBadge = true;
      }else{
        this.hasEarlySupportBadge = false;
      }
    })
  }

  async getLink(){
    let cookie = window.localStorage.getItem("signature_uuid");
      if(cookie!==null && cookie!==undefined){
        this.validUuid = cookie;
        let post ={signature_uuid:this.validUuid}
        this.http.post("https://api.kryptomon.co/auth/retrieveTrainerInviteLink.php", post).subscribe((item:any)=>{
            console.log(item)
            if(item.status == 'done'){
              this.inviteLink = item.inviteLink
              window.open(this.inviteLink,'_blank');
            }else{
              console.log('err')
            }
          })
      }else{

      var text = "I am ready to hunt";
      var msg = await this.contractService.web3.utils.utf8ToHex(text)
      var from = this.account;
      var params = [msg, from]
      var method = 'personal_sign'
      console.log(from)
       this.contractService.web3.currentProvider.sendAsync({
        method,
        params,
        from,
      }, (err:any, result:any) => {
        console.log('fired')
        if(err || result.error){
          console.log(err);
          console.log(result)
        }else{
          console.log(result)
          let post = {secret: result.result, walletId: this.account};
          console.log(post)
          this.http.post("https://api.kryptomon.co/auth/createTrainerInviteLink.php", post).subscribe((item:any)=>{
            console.log(item)
            if(item.status == 'done'){
              window.localStorage.setItem("signature_uuid", item.uuid);
              this.validUuid = item.uuid;
              this.inviteLink = item.inviteLink
              window.open(this.inviteLink,'_blank');
            }else{
              console.log('err')
            }
          })
        }    
      })
     }
  }
  

 closeDropdown(){
  const navItems = document.getElementsByClassName("nav-item");
  for (let i = 0; i <navItems.length; i++){
    navItems[i].classList.remove("show")
  }
 }
}

<div class="hero">
  <div class="hero-nav">
    <div class="nav-item" (click)="WhitePaper()">
      <p>White Paper</p>
    </div>
    <div class="nav-item" (click)="tokenomicsPage()">
      <p>Tokenomics</p>
    </div>
    <div class="nav-item">
      <p><a href="https://medium.com/kryptomon">Blog</a></p>
    </div>
    <div class="nav-item social-links">
      <a href="https://t.me/kryptomonofficial">
        <fa-icon [icon]="['fab', 'telegram-plane']"></fa-icon>
      </a>
      <a href="https://medium.com/kryptomon">
        <fa-icon [icon]="['fab', 'medium-m']"></fa-icon>
      </a>
      <a href="https://discord.gg/hYRjSfsWXt">
        <fa-icon [icon]="['fab', 'discord']"></fa-icon>
      </a>
      <a href="https://twitter.com/KryptomonTeam">
        <fa-icon [icon]="['fab', 'twitter']"></fa-icon>
      </a>
       <a href="https://www.reddit.com/r/Kryptomon/">
        <fa-icon [icon]="['fab', 'reddit']"></fa-icon>
      </a>
    </div>
    <!--<div class="nav-item">
        <p>Connect</p>
      </div>-->
  </div>
</div>
<div class="section first-section">
  <div class="row">
    <div class="col text-col">
      <img class="first-img" src="./assets/img/Home/Group 5.svg" (click)="goHome()" />
      <h1>Team</h1>
      <p>Ideas are the beginning, the spark and they are great but they are even better when they come true. For this you need execution and to have the best execution you need the best team.</p>
    </div>
  </div>
</div>
<div class="section team-section">
  <div class="row">
    <div class="col" *ngFor="let t of team">
      <div class="team-image">
        <img src="{{t.img}}" />
      </div>
      <div class="team-content">
        <p>{{t.name}}</p>
        <p>{{t.role}}</p>
        <p><q>{{t.bio}}</q></p>
        <a href="{{t.linkedin}}" target="_blank">
          <fa-icon [icon]="['fab', 'linkedin']"></fa-icon>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="section first-section advisors">
  <div class="row">
    <div class="col text-col">
      <h1>Advisors</h1>
      <p>Since the beginning we wanted to bring onboard an outstanding pool of Advisor, and well we have it!</p>
    </div>
  </div>
</div>
<div class="section team-section">
  <div class="row">
    <div class="col" *ngFor="let a of advisors">
      <div class="team-image">
        <img src="{{a.img}}" />
      </div>
      <div class="team-content">
        <p>{{a.name}}</p>
        <p>{{a.role}}</p>
        <a href="{{a.linkedin}}" target="_blank">
          <fa-icon [icon]="['fab', 'linkedin']"></fa-icon>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="section first-section advisors">
  <div class="row">
    <div class="col text-col">
      <h1>Partners &amp; Investors</h1>
      <p>And then, it's also all about the people you surrond yourself that makes the difference too!</p>
    </div>
  </div>
</div>
<div class="section team-section partners">
  <div class="row">
    <div class="col" *ngFor="let p of partners">
      <div class="team-image">
        <img src="{{p.img}}" />
      </div>
      <div class="team-content">
        <p>{{p.name}}</p>
        <p>{{p.role}}</p>
        <a href="{{p.linkedin}}" target="_blank">
          <fa-icon [icon]="['fas', 'globe-europe']"></fa-icon>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="section last-section">
  <div class="row">
    <div class="col">
      <img src="./assets/img/Home/dnadue.png" />
      <h1>We are growing fast!</h1>
      <p>Do you like our project and do you think you will be an outstanding member of this journey ? Feel free to candidate</p>
      <p>If you are a blockchain developer we would love to talk with you.</p>
      <a class="signup-btn typeform-share" href="https://form.typeform.com/to/nmjPaaFA">Candidate</a>
      <img src="./assets/img/Home/gem.png" />
    </div>
  </div>
</div>
<div class="wave-divider-footer">
  <img src="./assets/img/Home/wave.svg" />
</div>
<div class="footer">
  <div class="row">
    <div class="col">
      <img src="./assets/img/Home/Group 5.svg" />
      <p>The Kryptomon Company - 2021</p>
      <a href="https://www.iubenda.com/privacy-policy/20002823" class="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a>
      <a href="https://www.iubenda.com/privacy-policy/20002823/cookie-policy" class="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe " title="Cookie Policy ">Cookie Policy</a>
    </div>
  </div>
</div>


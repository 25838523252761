<!--<div class="social-campaign" *ngIf="showSocial">
  <div class="card-custom">
  	<div class="close-banner" (click)="toggleSocial()">
	x
</div>
    <div class="card-iframe">
      <iframe src="https://woorise.com/kryptomons/exclusive-early-access"></iframe>
    </div>
  </div>
</div>-->
<div class="hero">
  <div class="hero-nav">
    <div class="nav-item">
      <p>White Paper</p>
    </div>
    <div class="nav-item" (click)="buyTestEgg()">
      <p>Get your test egg!</p>
    </div>
       <div class="nav-item social-links">
       <a href="https://t.me/Kryptomon"><fa-icon [icon]="['fab', 'telegram-plane']"></fa-icon></a>
       <a href="https://medium.com/kryptomon"><fa-icon [icon]="['fab', 'medium-m']"></fa-icon></a>
    </div>
    <!--<div class="nav-item">
        <p>Connect</p>
      </div>-->
  </div>
</div>
<div class="section first-section">
  <div class="row">
    <div class="col text-col">
      <img class="first-img" src="./assets/img/Home/Group 5.svg" (click)="goHome()" />
      <h1>Get your free GEN0 Kryptomon</h1>
      <p>To celebrate the upcoming launch of the Kryptomons world we're going to airdrop the first and only 100 Gen0 Kryptmons' Eggs.</p>
      <p>Sign up now to be among the top 100 Kryptomon egg owners in the world!</p>
      <p>Enter the list and put your strategy in place to advance in position.</p>
    </div>
  </div>
</div>
<div class="section timeline-section first-section">
  <div class="row">
    <div class="col">
    <div class="card-custom second-card">
        <h1>Step 0</h1>
        <div class="card-item">
          <div class="card-content">
            <p class="subtitle"><span class="signup" (click)="gotoTelegram()">Join our telegram group</span> and go to the link you will find there to enter the waiting list!</p>
          </div>
        </div>
      </div>
      <div class="card-custom second-card">
      	<img src="./assets/img/Home/gem.png">
        <h1>Step 1</h1>
        <div class="card-item">
          <div class="card-content">
            <p class="subtitle">Signup with your email to the early access list and get 1 entry</p>
          </div>
        </div>
      </div>
      <div class="card-custom second-card">
        <h1>Step 2</h1>
        <div class="card-item">
          <div class="card-content">
            <p class="subtitle">Share the invite with your friends and network, the more people will join the more entries you will get to scale the list!</p>
          </div>
        </div>
      </div>
      <div class="card-custom second-card">
        <h1>Step 3</h1>
        <div class="card-item">
          <div class="card-content">
            <p class="subtitle">Before the official launch of Kryptomon, if you are going to be in the top 100, you will be contacted to our team and we will share with you your first Kryptomon Gen0 Egg!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="section last-section">
  <div class="row">
    <div class="col"><img src="./assets/img/Home/dnadue.png">
      <h1>1 in a 1000<br>is a special one</h1>
      <div class="signup-btn"(click)="gotoTelegram()">Claim you free Egg!</div><img src="./assets/img/Home/gem.png">
    </div>
  </div>
</div>
<div class="wave-divider-footer"><img src="./assets/img/Home/wave.svg"></div>
<div class="footer">
  <div class="row">
    <div class="col"><img src="./assets/img/Home/Group 5.svg">
      <p>2021</p>
    </div>
  </div>
</div>

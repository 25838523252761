<div class="newegg-overlay" *ngIf="noWallet">
  <div class="card">
    <div class="card-content" *ngIf="connection_text == 'No wallet'">
      <p>No wallet detected, please install a wallet like <a href="https://metamask.io/download"
          target="_blank">Metamask</a> or Mist and refresh the page once done!</p>
    </div>
    <div class="card-content" *ngIf="connection_text == 'Grant access'">
      <p>We've dected a wallet, but no connection has been established. Please connect a wallet</p>
      <div (click)="showModalConnect()" class="signup-btn marginTop25">Connect</div>
    </div>
    <div class="card-content" *ngIf="connection_text == 'Wrong Network'">
      <p>We've dected a wallet, but you are connected to the wrong network. Please connect to Binance Smartchain
        Mainnet.</p>
    </div>
  </div>
</div>
<div class="newegg-overlay" *ngIf="showShare">
  <div id="tsparticles"></div>
  <div class="card">
    <div class="close">
      <fa-icon [icon]="['fas', 'times-circle']" (click)="closeShare()"></fa-icon>
    </div>
    <div class="card-content" *ngIf="!collectedKryptomon">
      <img src="./assets/img/Home/{{modalImage}}" class="egg-pic" />
      <div class="modal-text">
        <p>{{modalTitle}}</p>
        <p>{{modalContent}}</p>
        <div (click)="doAction()" *ngIf="!isLoadingBuy" class="signup-btn">{{modalCta}}</div>
        <div class="lds-ripple" *ngIf="isLoadingBuy">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
    <div class="card-content" *ngIf="collectedKryptomon">
      <img src="{{kryptomons[0].data.image}}" class="egg-pic" />
      <p>Congratulation Trainer!</p>
      <p>You got your first Kryptomon Egg 🎉</p>
      <p>Share your Kryptomon egg with the world</p>
      <div class="share-buttons">
        <share-button button="twitter" url="https://kryptomon.co"
          description="Here is my Kryptomon Egg! 😍 A new adventure is about to begin and you? Do you want to be a part of it? Join the telegram group to find out how 🎉 https://t.me/kryptomonofficial #Kryptomon">
        </share-button>
        <share-button button="reddit" url="https://kryptomon.co" title="I finally got my Kryptomon's egg!"
          description="Here is my Kryptomon Egg! 😍 A new adventure is about to begin and you? Do you want to be a part of it? Join the telegram group to find out how 🎉 https://t.me/kryptomonofficial #Kryptomon">
        </share-button>
        <share-button button="facebook" url="https://kryptomon.co" title="I finally got my Kryptomon's egg!"
          description="Here is my Kryptomon Egg! 😍 A new adventure is about to begin and you? Do you want to be a part of it? Join the telegram group to find out how 🎉 https://t.me/kryptomonofficial #Kryptomon">
        </share-button>
      </div>
      <p class="marginTop25">And now?</p>
      <div (click)="gotoDashboard()" class="signup-btn">Go to Dashboard</div>
    </div>
  </div>
</div>
<div class="row content_display dashboard" style="width:25%; margin-bottom: -150px" *ngIf="!authorized && !ended">
  <div class="card card-error" style="background:linear-gradient(180deg, #d7319e 0%, #6331f2 100%); z-index: 10" (click)="authorizePaymentToken()">
    <div class="card-content">
      <span *ngIf="!isLoadingAuthorize" >Authorize {{purchaseTokenName}}</span>
      <div class="lds-ripple" style="top:-41px; height: 0;" *ngIf="isLoadingAuthorize">
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</div>
<div class="row content_display dashboard">
  <!-- <div class="col">
    <div class="card" [ngClass]="{'disabled': getDisabledMessage(0) != ''}" (click)="buyMysteryBox(0)">
      <div class="card-content">
        <img src="./assets/img/Home/mb-image.png" style="padding: 20px"/>
        <p>Trainer Club</p>
        <div class="lds-ripple" *ngIf="isLoadingBuy">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
    <div class="card card-error" *ngIf="getDisabledMessage(0) != ''">
      <div class="card-content">
        <span>{{getDisabledMessage(0)}}</span>
        <h6 class="get-payment-token" *ngIf="getDisabledMessage(0).startsWith('Not enough')">
          <a href="https://pancakeswap.finance/swap?outputCurrency={{contractService.contractMBPurchaseToken._address}}&chainId=56" target="_blank">Get {{purchaseTokenName}}</a>
        </h6>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card" [ngClass]="{'disabled': getDisabledMessage(1) != ''}" (click)="buyMysteryBox(1)">
      <div class="card-content">
        <img src="./assets/img/Home/mb-image.png" />
        <p>Whitelisted Members</p>
        <div class="lds-ripple" *ngIf="isLoadingBuy">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
    <div class="card card-error" *ngIf="getDisabledMessage(1) != ''">
      <div class="card-content">
        <span>{{getDisabledMessage(1)}}</span>
        <h6 class="get-payment-token" *ngIf="getDisabledMessage(1).startsWith('Not enough')">
          <a href="https://pancakeswap.finance/swap?outputCurrency={{contractService.contractMBPurchaseToken._address}}&chainId=56" target="_blank">Get {{purchaseTokenName}}</a>
        </h6>
      </div>
    </div>
  </div> -->
  <div class="col">
    <div class="card" [ngClass]="{'disabled': getDisabledMessage(0) != ''}" (click)="buyMysteryBox(0)">
      <div class="card-content">
        <img src="./assets/img/Home/mb-image.png" />
        <p style="margin-top: 1rem;">{{(+utils.formatEther(price)).toFixed(2)}} {{purchaseTokenName}}</p>
        <div class="lds-ripple" *ngIf="isLoadingBuy">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
    <div>
    </div>
    <div class="card card-error" *ngIf="getDisabledMessage(0) != ''">
      <div class="card-content">
        <span>{{getDisabledMessage(0)}}</span>
        <h6 class="get-payment-token" *ngIf="getDisabledMessage(0).startsWith('Not enough')">
          <a href="https://pancakeswap.finance/swap?outputCurrency={{contractService.contractMBPurchaseToken._address}}&chainId=56" target="_blank">Get {{purchaseTokenName}}</a>
        </h6>
      </div>
    </div>
  </div>
</div>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  openAccessibilityStatement(){
    window.open("https://www.iubenda.com/privacy-policy/20002823/cookie-policy")
  }

  openPrivacyPolicy(){
    window.open("https://moored-kip-5c1.notion.site/Kryptomon-Privacy-Notice-90a46ed1c72343078dbbc239820384cc")
  }
  openLicenceAgreement(){
    window.open("https://moored-kip-5c1.notion.site/Kryptomon-End-User-Licence-Agreement-e41b4cd4bee94fb78b74d62fd13475dd")
  }

}

<div class="wrong-network-overlay" *ngIf="
    connection_text == 'No wallet' ||
    connection_text == undefined ||
    connection_text == 'Wrong Network' || connection_text == 'Grant access'
  ">
  <div class="wrong-network-content-title" *ngIf="connection_text !== undefined">
    {{ connection_text }} - {{ contractService.networkName }}
  </div>
  <div class="wrong-network-content-title" *ngIf="connection_text == undefined">
    No wallet
    
  </div>
  <div class="wrong-network-content-content" *ngIf="connection_text == 'Wrong Network'">
    Please connect to the Binance Smart Chain.
  </div>
  <div class="wrong-network-content-content" *ngIf="connection_text == 'undefined'">
    No wallet has been detected.
  </div>
  <div class="wrong-network-content-content" >
    You need to connect a wallet to this App.
  </div>
</div>
<div class="row app-fixed" [ngClass]="{ solidBg: sg['solidBackground'] }">
  <div class="col">
    <!-- NAVBAR MENU -->
    <div class="menu-container">

      <div class="hamburger-menu" (click)="showNavMenu()"> 
        <img src="../assets/img/Home/085-controls.png" /> 
      </div>
      <div id="navMenuItems" class="menu-items">
        <div class="menu-profile" (click)="showMenuClick()">
          <img src="./assets/img/Home/avatar.png" [ngClass]="{

            red: connection_text == 'No wallet' || connection_text == undefined,
            green: connection_text == 'Connected',
            yellow:
              connection_text == 'Wrong Network' ||
              connection_text == 'Grant access'
          }" />
          <div class="menu-block-dropdown" [ngClass]="{ show: showMenu }">
            <div class="drop-item">
              <div class="bollino" [ngClass]="{
                red:
                  connection_text == 'No wallet' ||
                  connection_text == undefined,
                green: connection_text == 'Connected',
                yellow:
                  connection_text == 'Wrong Network' ||
                  connection_text == 'Grant access'
              }"></div>
              <div class="connection_text" *ngIf="connection_text !== undefined">
                {{ connection_text }} - {{ contractService.networkName }}
              </div>
              <div class="connection_text" *ngIf="connection_text == undefined">
                No wallet
              </div>
            </div>
            <div class="drop-item" (click)="openClaim()">
              <fa-icon [icon]="['fas', 'file-archive']"></fa-icon>
              Claim
            </div>
            <!--<div class="drop-item" (click)="copyClipboard(invite)" *ngFor="let invite of invites, let i = index">
            <fa-icon [icon]="['fas', 'paper-plane']"></fa-icon>
            Invite {{i+1}}: <span>{{invite}}</span>
          </div>-->
            <div class="drop-item" (click)="connectDisconnectProvider()">
              <fa-icon [icon]="['fas', 'sign-out-alt']"></fa-icon>
              Signout
            </div>
          </div>

        </div>
        <div class="menu-item" [ngClass]="{ selected: navigationSelected[0] }" (click)="openDashboard()">
          Dashboard
        </div>
        <div class="menu-item" (click)="openMarketPlace()">
          Marketplace
        </div>
        <div class="menu-item" (click)="openStaking()" [ngClass]="{ selected: navigationSelected[1] }">
          Staking
        </div>
        
        <div class="menu-item" (click)="openBNFT()" [ngClass]="{ selected: navigationSelected[2] }">
          Binance NFT
        </div>

        <div class="menu-item play" (click)="openSale()" [ngClass]="{ selected: navigationSelected[4] }">
          SALE
        </div>

        <div class="menu-item play" (click)="openPlay()" [ngClass]="{ selected: navigationSelected[3] }">
          Play
        </div>
      </div>
      <!-- LOGO ITEM-->
      <div class="menu-icon" (click)="openHomePage()">
        <img src="./assets/img/Home/Kryptomon_logo.svg" />
      </div>
      <!-- COINS MENU -->
      <div class="menu-coins" >
        <img src="./assets/img/Home/coin.png" (click)="openSwap()"/>
        <p>{{ kmonBalance.toLocaleString() }}</p>
        <img class="metaMask-btn" src="../assets/img/Home/meta-icon.png" (click)="addKMONToken()">
      </div>

    </div>
  </div>
</div>

<router-outlet></router-outlet>
<!-- Footer Component -->
<app-footer></app-footer>

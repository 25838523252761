import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router'
@Component({
  selector: 'app-winning-list',
  templateUrl: './winning-list.component.html',
  styleUrls: ['./winning-list.component.scss'],
 encapsulation: ViewEncapsulation.None
})
export class WinningListComponent implements OnInit {
  public showSocial
  constructor(public router: Router) {
  	this.showSocial = false;
  }

  ngOnInit(): void {
  }

   gotoTelegram() {
    location.href = 'https://t.me/kryptomonofficial'      // or without async/await you can simply chain the promises
  }

  buyTestEgg() {
    location.href = '/testnet/get-egg'      // or without async/await you can simply chain the promises
  }

  goHome() {
    console.log('home')
    this.router.navigate(["/"])      // or without async/await you can simply chain the promises
  }

  toggleSocial(){
  	this.showSocial = !this.showSocial;
  	console.log(this.showSocial)
  }

}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router'
import { Angulartics2 } from 'angulartics2';

@Component({
  selector: 'app-white-paper',
  templateUrl: './white-paper.component.html',
  styleUrls: ['./white-paper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WhitePaperComponent implements OnInit {

  public closeBanner;

  constructor(public router: Router, private angulartics2: Angulartics2) { 
    this.closeBanner = true;
  }
  ngOnInit(): void {
  }

  goHome() {
    console.log('home')
    location.href = 'https://kryptomon.co'      // or without async/await you can simply chain the promises
  }

 tokenomicsPage() {
    console.log('home')
    this.router.navigate(["/tokenomics"])      // or without async/await you can simply chain the promises
    this.angulartics2.eventTrack.next({ 
      action: 'GoToTokenomics', 
      properties: { category: 'home' },
    });
  }

   teamPage() {
    console.log('home')
    this.router.navigate(["/team"])      // or without async/await you can simply chain the promises
    this.angulartics2.eventTrack.next({ 
      action: 'GoToTeam', 
      properties: { category: 'home' },
    });
  }

   toggleBanner(){
   this.closeBanner=!this.closeBanner
 }
}

<div class="section-container">
  <div class="badge-section desktop">
    <img src="./assets/img/Home/hunter.png" class="badge-custom" *ngIf="hasHunterBadge" (click)="getLink()" />
    <div class="link-tooltip">
      Click me to join the private group!
    </div>
    <img src="./assets/img/Home/supporter.png" class="badge-custom" *ngIf="hasEarlySupportBadge" />
  </div>
  <div class="row">
    <div class="col">
      <div class="navigator" [ngClass]="{'hideNavigator':sg['solidBackground']}">
        <div class="dropdown-arrow" >▼</div>
        <div class="nav-item" (click)="navigateInternally(0)" [ngClass]="{'selected' : navigationSelected[0]}">
          Eggs
        </div>
        <div class="nav-item" (click)="navigateInternally(2)" [ngClass]="{'selected' : navigationSelected[2]}">
          Eggs V2
        </div>
        <div class="nav-item" (click)="navigateInternally(1)" [ngClass]="{'selected' : navigationSelected[1]}">
          Kryptomons
        </div>
        <div class="nav-item" (click)="navigateInternally(3)" [ngClass]="{'selected' : navigationSelected[3]}">
          Kryptomons V2
        </div>
        <!--<div class="nav-item" (click)="navigateInternally(2)" [ngClass]="{'selected' : navigationSelected[2]}">
          Items
        </div>-->
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="badge-section mobile">
    <img src="./assets/img/Home/hunter.png" class="badge-custom" *ngIf="hasHunterBadge" (click)="getLink()" />
    <div class="link-tooltip">
      Click me to join the private group!
    </div>
    <img src="./assets/img/Home/supporter.png" class="badge-custom" *ngIf="hasEarlySupportBadge" />
  </div>
</div>
<div class="video-overlay" *ngIf="showVideo">
  <div class="video-block">
    <div class="close-video" (click)="saveVideoCookie()">
      <fa-icon [icon]="['fas', 'times']"></fa-icon>
    </div>
    <div class="video-cover" (click)="playVideo()" *ngIf="!hideVideoCover">
      <fa-icon [icon]="['fas', 'play']"></fa-icon>
    </div>
    <video src="./assets/img/Home/kmon-video.mp4" #videoPlayer (ended)="saveVideoCookie()"></video>
  </div>
</div>

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
declare const tsParticles;
import {Router} from '@angular/router'

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LeaderboardComponent implements OnInit {
  public email;
  public userObject
  public loading
  public eggGen
  public error
  constructor(public http:HttpClient, public router:Router) { 
    this.loading = false;
    this.error = false;
  }

  ngOnInit(): void {
  }

  checkScore(){
    
    if(this.validateEmail(this.email)){
      this.loading = true;
      this.error = false;
        let data = {email:this.email};
        setTimeout(()=>{
        this.http.post("https://api.kryptomon.co/loader_lead/checkEmail.php", data).subscribe((item)=>{
          if(item!==null && item!==undefined){
            this.userObject = item;
            if(this.userObject.id<=100){
              this.eggGen = 0;
              this.startConfetti()
            }else if(this.userObject.id<=200){
              this.eggGen = 1;
              this.startConfetti()
            }else if(this.userObject.id<=400){
              this.eggGen = 2;
              this.startConfetti()
            }else if(this.userObject.id<=800){
              this.eggGen = 3;
              this.startConfetti()
            }else if(this.userObject.id<=1600){
              this.eggGen = 4;
              this.startConfetti()
            }else if(this.userObject.id<=3200){
              this.eggGen = 5;
              this.startConfetti()
            }else if(this.userObject.id<=6400){
              this.eggGen = 6;
              this.startConfetti()
            }else if(this.userObject.id<=10000){
              this.eggGen = 7;
              this.startConfetti()
            }else{
              this.eggGen = 999;
            }
            this.loading = false;
          }else{
            this.error = true;
            this.loading = false;
          }
        })
        }, 1000)
      }
  }

  goHome() {
    console.log('home')
    location.href = 'https://kryptomon.co'      // or without async/await you can simply chain the promises
  }

  gotoDrop(){
    this.router.navigate(['/airdrop']);
  }

  validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
  }

  startConfetti(){
   tsParticles.load("tsparticles", {
  fpsLimit: 60,
  particles: {
    number: {
      value: 0
    },
    color: {
      value: ["#00FFFC", "#FC00FF", "#fffc00"]
    },
    shape: {
      type: "confetti",
      options: {
        confetti: {
          type: ["circle", "square"]
        }
      }
    },
    opacity: {
      value: 1,
      animation: {
        enable: true,
        minimumValue: 0,
        speed: 2,
        startValue: "max",
        destroy: "min"
      }
    },
    size: {
      value: 7,
      random: {
        enable: true,
        minimumValue: 3
      }
    },
    links: {
      enable: false
    },
    life: {
      duration: {
        sync: true,
        value: 5
      },
      count: 1
    },
    move: {
      enable: true,
      gravity: {
        enable: true,
        acceleration: 20
      },
      speed: 20,
      decay: 0.1,
      direction: "none",
      random: false,
      straight: false,
      outModes: {
        default: "destroy",
        top: "none"
      }
    }
  },
  interactivity: {
    detectsOn: "window",
    events: {
      resize: true
    }
  },
  detectRetina: true,
  background: {
    color: "#232123"
  },
  emitters: {
    direction: "none",
    life: {
      count: 0,
      duration: 0.1,
      delay: 0.4
    },
    rate: {
      delay: 0.1,
      quantity: 100
    },
    size: {
      width: 0,
      height: 0
    }
  }
});
}
}



<div class="hero">
  <div class="hero-nav">
    <div class="nav-item" (click)="tokenomicsPage()">
      <p>Tokenomics</p>
    </div>
    <div class="nav-item" (click)="teamPage()">
      <p>Team</p>
    </div>
    <div class="nav-item">
      <p><a href="https://medium.com/kryptomon">Blog</a></p>
    </div>
    <div class="nav-item social-links">
      <a href="https://t.me/kryptomonofficial">
        <fa-icon [icon]="['fab', 'telegram-plane']"></fa-icon>
      </a>
      <a href="https://medium.com/kryptomon">
        <fa-icon [icon]="['fab', 'medium-m']"></fa-icon>
      </a>
      <a href="https://discord.gg/hYRjSfsWXt">
        <fa-icon [icon]="['fab', 'discord']"></fa-icon>
      </a>
      <a href="https://twitter.com/KryptomonTeam">
        <fa-icon [icon]="['fab', 'twitter']"></fa-icon>
      </a>
       <a href="https://www.reddit.com/r/Kryptomon/">
        <fa-icon [icon]="['fab', 'reddit']"></fa-icon>
      </a>
    </div>
    <!--<div class="nav-item">
        <p>Connect</p>
      </div>-->
  </div>
</div>
<div class="section first-section">
  <div class="row">
    <div class="col text-col">
      <img class="first-img" src="./assets/img/Home/Group 5.svg" (click)="goHome()" />
      <h1>White Paper (v1.0.3)</h1>
      <div class="page-body">
        <p><strong>Kryptomon: Collect and breed digital NFT Kryptomons</strong></p>
        <p>A new era is coming!</p>
        <p><strong>Table of contents</strong></p>
        <ul class="bulleted-list">
          <li [routerLink]="['/white-paper']" fragment="introduction">Introduction</li>
          <li [routerLink]="['/white-paper']" fragment="disclaimer">Disclaimer</li>
          <li [routerLink]="['/white-paper']" fragment="motivation">Motivation</li>
          <li [routerLink]="['/white-paper']" fragment="productroadmap">Product &amp; Roadmap</li>
          <ul>
            <li [routerLink]="['/white-paper']" fragment="overview">Overview</li>
            <li [routerLink]="['/white-paper']" fragment="kmon">KMONFT &amp; KMON</li>
            <li [routerLink]="['/white-paper']" fragment="phase0">Phase 0</li>
            <li [routerLink]="['/white-paper']" fragment="phase1">Phase 1</li>
            <li [routerLink]="['/white-paper']" fragment="phase2">Phase 2</li>
            <li [routerLink]="['/white-paper']" fragment="genetic">Genetic</li>
          </ul>
          <li [routerLink]="['/white-paper']" fragment="bmodel">Business Model</li>
          <li [routerLink]="['/white-paper']" fragment="team">Team</li>
          <li [routerLink]="['/white-paper']" fragment="tokenomics">Tokenomics</li>
          <li [routerLink]="['/white-paper']" fragment="allocation">Use of funds</li>
        </ul>
        <h2 id="introduction"><strong>Introduction</strong></h2>
        <p>Pokémon and Tamagotchi shaped entire generations, we grew up playing with those digital monsters. We care about them as if they were really alive, and we spent hours and hours to join that world and play with our creatures.</p>
        <p>In 2017 Cryptokitties launched a new idea, create digital cat based on blockchain, made them really unique and in some sort real. We loved the concept, but we founded it a little bit static, and so now that the time is right we introduce Kryptomons and the Kryptomons World, to quickly understand what the concept is we can say &quot;Cryptokitties meet Tamagotchi and Pokémon&quot;.</p>
        <h2 id="disclaimer">Disclaimer</h2>
        <p>To the maximum extent permitted by the applicable laws, regulations, and rules, KMON, any entities of KRYPTOMON and officers and employees thereof shall not be liable for any indirect, special, incidental, consequential, or other losses of any kind, in tort, contract or otherwise (including but not limited to loss of revenue, income or profits, and loss of use or data), arising out of or in connection with any acceptance of or reliance on this whitepaper or any part thereof by you.</p>
        <p>Any entities of KRYPTOMON and officers and employees thereof shall not be liable for your loss of KMON after it is transferred to you for any reason including but not limited to your failure to maintain or backup an accurate record of your password or password cracking by somebody due to the poor maintenance of your password.</p>
        <p>KRYPTOMON will do its best to launch its operations and develop KRYPTOMON GAME. Any person undertaking to acquire KMON acknowledges and understands however that KRYPTOMON does not provide any warranty as to the release of the KRYPTOMON GAME. They acknowledge and understand therefore that KRYPTOMON (incl. its bodies and employees) assumes no liability or responsibility for any loss or damage that would result from or relate to the incapacity to use the KMON.</p>
        <p>Regulatory authorities are carefully scrutinizing businesses and operations associated with Crypto Tokens in the world. In that respect, regulatory measures, investigations, or actions may impact KRYPTOMON’s business and may limit or prevent it from developing its operations in the future. Any person undertaking to acquire KMON must be aware that KRYPTOMON’s business model or KRYPTOMON GAME and its existing platforms may change or need to be modified because of new regulatory and compliance requirements from any applicable laws in any jurisdictions. In such case, purchasers and any person undertaking to acquire KMON acknowledge and understand that neither KRYPTOMON nor any of its affiliates shall be held liable for any direct or indirect loss or damages caused by such changes.</p>
        <p>This whitepaper and any other materials or explanations made by KRYPTOMON and its officers and employees shall not and cannot be considered as an invitation to enter into an investment. They do not constitute or relate in any way nor should they be considered as an offering of securities in any jurisdiction.</p>
        <p>This whitepaper does not include nor contain any information or indication that might be considered as a recommendation or that might be used as a basis for any investment decision. Neither KRYPTOMON nor any of its officers and employees are to be or shall be considered as advisors in any legal, tax, or financial matters. Acquiring KMON shall not grant any right or influence over KRYPTOMON’s organization and governance to the purchasers.</p>
        <h2 id="motivation">Motivation</h2>
        <p>First thing first, why are we doing this?</p>
        <p>To start we think it&#x27;s important to know a little bit more about the team behind.</p>
        <p>We are a team of colleagues of one of the major Venture Building company in Europe, what a Venture builder does? Easy we do create and launch new ventures for our corporate clients which are major multinational enterprises mostly in the FMCG industry. Our role is to individuate new needs and came up with ideas to solve those needs, but we don&#x27;t stop there we do actually validate the business for them, from the first product development (Minimum Viable Product) to the first traction. So long story short we do create products and services in our daily life.</p>
        <p>So then why launching Kryptomons?
          As Venture Builder we are thrilled by new ventures, this is what excites us and this is where our passion lives. We love to talk with customers/users, co-create the product, and see people love what we have built. For this reason, we always came up with different new ideas that are not suitable for our clients, but we truly believe in those simply because we are the first early adopters. </p>
        <p>Ok, but again why Kryptomons and not something else?</p>
        <p>The reason is simple, we are 80/90&#x27;s kids. We grew up with Tamagotchi and Pokémon and actually, some of our team members are still playing Pokémon games today even they are 30+ old.</p>
        <p>We don&#x27;t want to hide that the Kryptomon idea came up by looking at what was happening in the Cryptospace and all craze around NFTs, but as it&#x27;s easy to understand we saw a huge missing opportunity in the NFT world: they are static.</p>
        <p>The entire Kryptomons idea instead is around activity, have an alive digital monster that you need to take care of and make it stronger to become the strongest Kryptomons&#x27; Trainer out there.</p>
        <p>To easily explain the concept to our friends we use this example: &quot;Imagine for a moment that you are the only one out there in the entire world with a Charizard which is the strongest one because you train it and it&#x27;s yours, and there&#x27;s a proof of that.&quot;</p>
        <p>We really want to create the world that we always imagined when we were playing with those games and watch those anime.</p>
        <p>We truly believe in transparency and co-creation, what co-creation means? Co-creations means that today we don&#x27;t have all the answers, we don&#x27;t know how specific future game mechanics will work, but we are going to co-create these with our community, for this reason in the following pages you will see that some features or ideas are still not super detailed on the mechanics, this because we want to co-create it with you.</p>
        <p>Having said that, we hope that the Kryptomon concept will thrill you as much as it thrills us because together we will be able to create something Memorable.</p>
        <h2 id="productroadmap"><strong>The Product and Roadmap</strong></h2>
        <h3 id="overview">Overview</h3>
        <p>Kryptomons are digital collectible monsters built on the BSC blockchain. They can be bought by using our dedicated BEP20 Token (KMON). Breed with other players to create new eggs with exciting traits and new levels of power.</p>
        <p>At launch, 10,000 unique eggs will be stored in a smart contract on the BSC blockchain. </p>
        <p>Every egg has a unique but mutable genetic code (genotype) and a distinct visual appearance which is stored in the smart contract. The Genetic code is made up of 38 different genotypes that will determine all the aspects - both physical and behavioral - of your creature. Your kryptomon requires attention and if you fail to take regular care of it will freeze and you will need to spend KMON to unfreeze it, and it will lose some of its stats.</p>
        <p>Kryptomon is a unique concept in the NFT landscape, a truly living creature experience.</p>
        <h3 id="kmon">KMONFT &amp; KMON</h3>
        <p>The Kryptomon&#x27;s world is going to be based on two main tokens: KMONFT &amp; KMON.</p>
        <ul class="bulleted-list">
          <li>KMONFT is the actual NFT Token representing your Kryptomon</li>
        </ul>
        <ul class="bulleted-list">
          <li>KMON is the currency of this world, with KMON you will be able to buy your first Kryptomon egg, items, and power ups for your little creature. KMON will also have other functionalities in the world, like in the Pokémon game when players will fight against each other they will be able to bet some tokens, there will be the possibility to stake your KMON and earn interest either under the form of the actual token or redeem power ups and items to make your Kryptomon stronger. We are planning to make KMON a key asset of this world, but of course, we know that it would be good to earn some money from the token value, for this reason, we came up with the staking mechanism allowing players to not spend the entire token they&#x27;ve bought or earned into the game, but partially cause they can always mint new tokens via staking. </li>
        </ul>
        <p>How this staking mechanism will work? What is going to be the cost for a specific action? At the time of writing this white paper (17 April 2021 00:04), we don&#x27;t know yet. This again is where co-creation with the community will take place, we will decide this only after hearing the ideas and feedback from the community.</p>
        <h3 id="phase0">Phase 0 - Q2 2021</h3>
        <p>We have divided our development progress in three major phases (0, 1, 2).</p>
        <p>Phase 0 is the starting point. </p>
        <p>During this phase we are going to release the first version of Kryptomon&#x27;s DAPP, Smart Contract and Tokens.</p>
        <p>Phase 0 is going to be a little bit &quot;static&quot;, but it&#x27;s a crucial phase cause it will set the basis for this new world. In this Phase, only 10.000 Kryptomons eggs will be available to buy, once this limit is reached there will not be any more eggs available to buy from the Smart Contract, and then the only way to enter the world will be to buy a Kryptomon egg from another player.</p>
        <p>When you create a new product it&#x27;s always easy to overcomplicate stuff and build things that, at the end of the day, are not needed. For this reason, we wanted to Keep It Simple and Stupid.</p>
        <p>The only thing you will be able to actually do is buying a Kryptomon&#x27;s Egg which is already coming with its 38 genes genetic code. We will cover more about the Genetic Code later.</p>
        <p>How much a Kryptomon egg will cost at this stage? We want to create this world, no matter the cost and indeed a key factor is adoption, so for this reason, we&#x27;re going to set a fixed price of 0$ per egg, just pay the gas fees, making Kryptomon accessible to everyone.</p>
        <p>Currently, we already have available a first beta version of the DAPP that can be tested and we will share it with our community soon.</p>
        <p>
        </p>
        <div class="screens">
          <img src="./assets/img/Home/screen0.png" />
          <img src="./assets/img/Home/screen1.png" />
        </div>
        <h3 id="phase1">Phase 1 - Q3 2021</h3>
        <p>Ooh, something is moving...</p>
        <p>Phase 1 is the moment where the eggs will hatch and the Tamagotchi Mechanics will be implemented.</p>
        <p>This will mean that in this stage you will be able to take care of your Kryptomon&#x27;s squad. Based on their specific genetic they will need a different kind of attention, for example you could come up with a Lazy potato couch that will need more intensive training to change his attitude and improve his abilities, or you can find one who has a high score in affection so it will need more cuddle from your side.</p>
        <p>In this Phase the breeding mechanics will start to be active, meaning that you will be able to breed Kryptomons and generate new eggs that then you will be able to sell for profit on marketplaces.</p>
        <p>Also, the Staking feature will kicks in, allowing you to stake your KMON and earn interests based on your stake that then you will be able to convert in items and power ups for your little creature if you wish of course.</p>
        <p>Even though we are building on BSC and so we will have lower transactions fees, we know that a game like this will require a lot of actions and therefore transactions, so we will optimize the front end to store the actions and changes in the abilities and then make a single bulk transaction to save everything to the chain reducing the cost of transactions.</p>
        <p>In this phase part of the KMON used to buy the KMONFT&#x27;s Items and Powerups will be burned and part of it will be used for the liquidity and staking pool. This allocation will change in the future based on the community&#x27;s feedback and new mechanics allocation.</p>
        <h3 id="phase2">Phase 2 - Q4 2021</h3>
        <p>It&#x27;s battle time!</p>
        <p>In this phase we are going to implement the battle system and players will be able to fight each other and wins single battles and tournaments, while winning small bets against each other or even big tournaments&#x27; prizes. </p>
        <p>Most of the time people tend to reinvent the wheel, we don&#x27;t. We know what we want and what battle mechanic we want to bring to the Kryptomon&#x27;s World, Pokémon one. As Pokémon players ourselves we know really well that one and we want to bring the same experience. </p>
        <p>Setting up also an Elite Four League and even here we will co-create this experience throughout the entire Q4 with our community.</p>
        <p>So the questions now is: Who&#x27;s gonna be the Champion?</p>
        <h3 id="genetic">Genetic</h3>
        <p>Let&#x27;s talk about the genetic of our Kryptomons.</p>
        <p>As mentioned above all the Kryptomons will have a 38 genes initial genetic code, this will make your Kryptomon unique and will allow you to breed the strongest one by mixing different genetics like humans are doing for centuries with horses.</p>
        <p>The 38 Genetic code of course will define the initial attributes and stats of your creature when it will born, but then you will be able to improve those attributes through training and power ups items.</p>
        <p>As mentioned in Phase 0 there&#x27;s going to be only 10.000 eggs available and there&#x27;s a 1 / 1000 probability to get a Kryptomon with the X-Factor, this will make your Kryptomon special not only visually, but it will also have a generic powerup on all the attributes. It&#x27;s going to be a kind of Wonderkid.</p>
        <p>In the table below you can see the genetic code breakdown:</p>
        <p>
        </p>
        <table class="collection-content">
          <thead>
            <tr>
              <th>GENES COUPLE</th>
              <th>RESULT</th>
              <th>MAGNITUTE</th>
              <th>DESCRIPTION</th>
            </tr>
          </thead>
          <tbody>
            <tr id="9f643fc8-e913-4890-9e26-432f597fe346">
              <td class="cell-title">0</td>
              <td class="cell-&lt;GLm">FIRE GENES</td>
              <td class="cell-^}y`">100</td>
              <td class="cell-qCUh">QUANTITY OF GENES OF THIS ELEMENT</td>
            </tr>
            <tr id="2ea4a2a5-7554-4e0f-bf61-9f8b362e00cf">
              <td class="cell-title">1</td>
              <td class="cell-&lt;GLm">FIRE TALENT</td>
              <td class="cell-^}y`">10</td>
              <td class="cell-qCUh">STRENGTH OF THOSE GENES - TO BE MULTIPLIED BY THE QUANTITY TO DEFINE THE KRYPTOMON TYPE AND HOW BIG IS THE TALENT ON THIS ELEMENT</td>
            </tr>
            <tr id="91ca8867-8810-4eec-94ed-363cbcb00108">
              <td class="cell-title">2</td>
              <td class="cell-&lt;GLm">WATER</td>
              <td class="cell-^}y`">100</td>
              <td class="cell-qCUh"></td>
            </tr>
            <tr id="e5cf6698-49e6-474f-85ab-8f20912e86bf">
              <td class="cell-title">3</td>
              <td class="cell-&lt;GLm">WATER TALENT</td>
              <td class="cell-^}y`">10</td>
              <td class="cell-qCUh"></td>
            </tr>
            <tr id="4947ce50-71fd-4261-a6ab-ef9de12eee8c">
              <td class="cell-title"></td>
              <td class="cell-&lt;GLm">ICE</td>
              <td class="cell-^}y`">100</td>
              <td class="cell-qCUh"></td>
            </tr>
            <tr id="a5f8de73-1f49-4d23-a621-eb62272c449c">
              <td class="cell-title"></td>
              <td class="cell-&lt;GLm">ICE TALENT</td>
              <td class="cell-^}y`">10</td>
              <td class="cell-qCUh"></td>
            </tr>
            <tr id="a42b9ffc-b6d9-441c-9b18-da34d06f3e18">
              <td class="cell-title"></td>
              <td class="cell-&lt;GLm">GROUND</td>
              <td class="cell-^}y`">100</td>
              <td class="cell-qCUh"></td>
            </tr>
            <tr id="0676e930-303c-4fe9-8e58-40ac9a5bbc24">
              <td class="cell-title"></td>
              <td class="cell-&lt;GLm">GROUND TALENT</td>
              <td class="cell-^}y`">10</td>
              <td class="cell-qCUh"></td>
            </tr>
            <tr id="a2657037-dbac-4991-a856-7303223a22e3">
              <td class="cell-title"></td>
              <td class="cell-&lt;GLm">AIR</td>
              <td class="cell-^}y`">100</td>
              <td class="cell-qCUh"></td>
            </tr>
            <tr id="670a7e07-fbba-4bee-b791-4247b9ed4aac">
              <td class="cell-title"></td>
              <td class="cell-&lt;GLm">AIR TALENT</td>
              <td class="cell-^}y`">10</td>
              <td class="cell-qCUh"></td>
            </tr>
            <tr id="82edfcab-cf6e-413f-b993-133dbac79271">
              <td class="cell-title">10</td>
              <td class="cell-&lt;GLm">ELECTRO</td>
              <td class="cell-^}y`">100</td>
              <td class="cell-qCUh"></td>
            </tr>
            <tr id="cf17ce13-3c5f-4adb-a3d3-71c528f63af9">
              <td class="cell-title">11</td>
              <td class="cell-&lt;GLm">ELECTRO TALENT</td>
              <td class="cell-^}y`">10</td>
              <td class="cell-qCUh"></td>
            </tr>
            <tr id="2b21d0d4-6137-4ff3-93af-c55c1af98ddc">
              <td class="cell-title">12</td>
              <td class="cell-&lt;GLm">GHOST</td>
              <td class="cell-^}y`">100</td>
              <td class="cell-qCUh"></td>
            </tr>
            <tr id="1518e9cb-d920-491f-8347-cea9fdb43745">
              <td class="cell-title">13</td>
              <td class="cell-&lt;GLm">GHOST TALENT</td>
              <td class="cell-^}y`">10</td>
              <td class="cell-qCUh"></td>
            </tr>
            <tr id="82ea551a-e1d3-44ec-a531-575190c48b35">
              <td class="cell-title">14</td>
              <td class="cell-&lt;GLm">GRASS</td>
              <td class="cell-^}y`">100</td>
              <td class="cell-qCUh"></td>
            </tr>
            <tr id="46729e1c-39fc-46f3-97a0-4dc97f86521d">
              <td class="cell-title">15</td>
              <td class="cell-&lt;GLm">GRASS TALENT</td>
              <td class="cell-^}y`">10</td>
              <td class="cell-qCUh"></td>
            </tr>
            <tr id="477e2f94-526c-4e52-89a0-1557b93b9062">
              <td class="cell-title">16</td>
              <td class="cell-&lt;GLm">COLOR</td>
              <td class="cell-^}y`">10000</td>
              <td class="cell-qCUh"></td>
            </tr>
            <tr id="2b818110-226d-4c0d-9942-47f238df921e">
              <td class="cell-title">17</td>
              <td class="cell-&lt;GLm">SEX</td>
              <td class="cell-^}y`">10</td>
              <td class="cell-qCUh"></td>
            </tr>
            <tr id="66f06b87-abe2-4573-b33c-560402a9d917">
              <td class="cell-title">18</td>
              <td class="cell-&lt;GLm">GENERAL TALENT</td>
              <td class="cell-^}y`">10000</td>
              <td class="cell-qCUh">OVERALL TALENT - DEFINE HOW MUCH IT CAN STILL GROW</td>
            </tr>
            <tr id="734238ff-e547-4a6f-b254-f31a05d9a772">
              <td class="cell-title">19</td>
              <td class="cell-&lt;GLm">ATTACK</td>
              <td class="cell-^}y`">100</td>
              <td class="cell-qCUh">HOW STRONG IS THE ATTACK</td>
            </tr>
            <tr id="260f59ee-49f6-4c2d-bed6-f7429deb6629">
              <td class="cell-title">20</td>
              <td class="cell-&lt;GLm">DEFENSE</td>
              <td class="cell-^}y`">100</td>
              <td class="cell-qCUh">HOW STRONG IS THE DEFENSE</td>
            </tr>
            <tr id="08122818-1d41-44eb-92e6-f638c275dc65">
              <td class="cell-title">21</td>
              <td class="cell-&lt;GLm">SPECIAL</td>
              <td class="cell-^}y`">10</td>
              <td class="cell-qCUh">0 : IS MORE FOR PHYSICAL ATTACK / DEFENSE<br>
                10: IS MORE FOR SPECIAL ATTACK / DEFENSE</td>
            </tr>
            <tr id="7bd3459c-59b4-44be-83d9-29de20827d2f">
              <td class="cell-title">22</td>
              <td class="cell-&lt;GLm">X FACTOR</td>
              <td class="cell-^}y`">1</td>
              <td class="cell-qCUh">IS THIS SPECIAL ? IF SO IT WILL HAVE GENERAL POWER UP - IMAGINE CAPABLE TO GROW UP TO 110% INSTEAD OF 100%</td>
            </tr>
            <tr id="614f2c5f-4ef7-44fc-b4bf-0841e5403a8f">
              <td class="cell-title">23</td>
              <td class="cell-&lt;GLm">GROWTH TALENT FACTOR</td>
              <td class="cell-^}y`">100</td>
              <td class="cell-qCUh">HOW FAST IT COULD GROW HIS ATTRIBUTES</td>
            </tr>
            <tr id="f48c3a49-0368-4b97-acde-590f23696ef2">
              <td class="cell-title">24</td>
              <td class="cell-&lt;GLm">CONSTITUTION</td>
              <td class="cell-^}y`">100</td>
              <td class="cell-qCUh">0: IS MORE KEEN TO GET SICK AND NEED TREATMENTS<br>
                100: WILL NEVER GET SICK</td>
            </tr>
            <tr id="c28d5f61-21ed-4d08-a16c-b6cd0cbd4538">
              <td class="cell-title">25</td>
              <td class="cell-&lt;GLm">HEALTH POINTS</td>
              <td class="cell-^}y`">100</td>
              <td class="cell-qCUh">HOW MUCH LIFE IT COULD HAVE
                0: NOT A LOT OF LIFE HP (SLOW GROWTH)<br>
                100: CAN ACHIEVE THE MAXIMUM HEALTH POINTS IN GAME</td>
            </tr>
            <tr id="58475c89-6f27-4d8d-858f-4efc8126ca31">
              <td class="cell-title">26</td>
              <td class="cell-&lt;GLm">SPEED</td>
              <td class="cell-^}y`">100</td>
              <td class="cell-qCUh">HOW FAST IT IS</td>
            </tr>
            <tr id="f0432fd0-7eca-4585-8140-25d464a73830">
              <td class="cell-title">27</td>
              <td class="cell-&lt;GLm">AFFECTIONS</td>
              <td class="cell-^}y`">100</td>
              <td class="cell-qCUh">0: WILL NEED TO BE CUDDLE 3 TIMES PER DAY<br>
                100: WILL NEED TO BE CUDDLE MAYBE ONCE A MONTH</td>
            </tr>
            <tr id="d05c67b8-a53b-44f7-a870-3bb8d6cd19a7">
              <td class="cell-title">28</td>
              <td class="cell-&lt;GLm">CRAZYNESS</td>
              <td class="cell-^}y`">100</td>
              <td class="cell-qCUh">0: IS NOT CRAZY, ALWAYS FOLLOW ORDER AND RESPOND IN BATTLE<br>
                100: IT&#x27;S AN ASSHOLE</td>
            </tr>
            <tr id="ee999cb8-2390-493d-87f3-9f4b46133928">
              <td class="cell-title">29</td>
              <td class="cell-&lt;GLm">INSTINCT</td>
              <td class="cell-^}y`">100</td>
              <td class="cell-qCUh">0: NOT THE BEST IN BATTLE<br>
                100: HIGH PROBABILITY OF SUPER ATTACK OR EVADE DAMAGES</td>
            </tr>
            <tr id="d9f166c1-dd63-4fc9-8ca9-9e284cb046e4">
              <td class="cell-title">30</td>
              <td class="cell-&lt;GLm">HUNGER</td>
              <td class="cell-^}y`">100</td>
              <td class="cell-qCUh">0: IT WILL NOT NEED TO EAT FREQUENTLY<br>
                100: WORST THAN HOMER SIMPSON</td>
            </tr>
            <tr id="fbc458cd-d0a2-4055-a358-b26bde9c788a">
              <td class="cell-title">31</td>
              <td class="cell-&lt;GLm">LAZYNESS</td>
              <td class="cell-^}y`">100</td>
              <td class="cell-qCUh">0: IT WILL BE KEEN TO BE TRAINED FREQUENTLY AND TRAINING IS AT MAXIMUM EFFICIENCY<br>
                100: POTATO COUCH</td>
            </tr>
            <tr id="73a85f2f-402f-4bf0-9b8e-301636c8fcf1">
              <td class="cell-title">32</td>
              <td class="cell-&lt;GLm">BRAVE</td>
              <td class="cell-^}y`">100</td>
              <td class="cell-qCUh">0: AGAINST BIGGER ENEMY WILL SUFFER FEAR<br>
                100: DAVIDE VS GOLIA EFFECT</td>
            </tr>
            <tr id="2e55df44-2d8b-4078-bca7-d92fc99f1b27">
              <td class="cell-title">33</td>
              <td class="cell-&lt;GLm">SMART</td>
              <td class="cell-^}y`">100</td>
              <td class="cell-qCUh">0: DUMB LIKE A BRICK - NOT GREAT IN TRAINING AND BATTLE<br>
                1: WILL HAVE A POSITIVE EFFECT ON ALL THE ACTIVITIES</td>
            </tr>
            <tr id="6fb654e2-4f46-42c0-a91d-acdae54e9a01">
              <td class="cell-title">34</td>
              <td class="cell-&lt;GLm">BODY SIZE</td>
              <td class="cell-^}y`">100</td>
              <td class="cell-qCUh">0: KIRBY<br>
                100: GODZILLA</td>
            </tr>
            <tr id="05b2296a-d969-44c8-a567-8743114e0e14">
              <td class="cell-title">35</td>
              <td class="cell-&lt;GLm">EGO</td>
              <td class="cell-^}y`">100</td>
              <td class="cell-qCUh">0: VERY REALISTIC ONE - DOESN&#x27;T UNDERSTIMATE OTHERS AND KEEN TO KEEP TRAINING<br>
                100: NARCISO - WILL UNDERSTIMATE ENEMIES ON BATTLE AND NOT SO KEEN TO TRAIN</td>
            </tr>
            <tr id="b8be7c55-8d11-498a-9ca5-b469d83afb39">
              <td class="cell-title">36</td>
              <td class="cell-&lt;GLm">SKIN TYPE</td>
              <td class="cell-^}y`">1</td>
              <td class="cell-qCUh">0: FEATHER<br>
                1: SKIN<br>
                2: SCALE<br>
                3: SHORT HAIRS<br>
                4: LONG HAIRS</td>
            </tr>
            <tr id="ee4bbb10-5f86-4414-a9c8-eb427d1860a9">
              <td class="cell-title">37</td>
              <td class="cell-&lt;GLm">GENERATION</td>
              <td class="cell-^}y`">1</td>
              <td class="cell-qCUh"></td>
            </tr>
          </tbody>
        </table>
        <h2 id="bmodel">Business Model</h2>
        <p>We want to create something sustainable and can go bigger, as kids we played with Pokémon, we want our kids to play with Kryptomons.</p>
        <p>For doing that living out of the token valuation only will not make the system sustainable and to cover the costs we would either mint new tokens and sell having a negative impact on our community. Of course, at the beginning part of the token will be used to fund the development cost of Kryptomon, but as soon as the hatching mechanism will be in place and players can breed and sell eggs we will obtain a 3.65% fee as a transaction fee.</p>
        <h3 id="team">Team</h3>
        <p>As mentioned we are a team of experience venture builders for the biggest corporates in the world, our starting team have all the capabilities needed to start this incredible adventure: Designers, Developers, Product Designer, Growth Hackers and we will plan to extend the team with Game Designer, Character Designer and more devs.</p>
        <p>
        </p>
        <p>Umberto Canessa Cerchi - Product and Operations</p>
        <p>Maurice De Vries - Design and Branding</p>
        <p>Mirdad Vries - Developer</p>
        <p>Berenice Di Matto - Growth</p>
        <p>Claudio Cuccovillo - Growth</p>
        <p>Bartolomeo De Vitis - Finance</p>
      </div>
    </div>
  </div>
</div>
<div class="wave-divider-footer">
  <img src="./assets/img/Home/wave.svg" />
</div>
<div class="footer">
  <div class="row">
    <div class="col">
      <img src="./assets/img/Home/Group 5.svg" />
      <p>The Kryptomon Company - 2021</p>
      <a href="https://www.iubenda.com/privacy-policy/20002823" class="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a>
      <a href="https://www.iubenda.com/privacy-policy/20002823/cookie-policy" class="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe " title="Cookie Policy ">Cookie Policy</a>
    </div>
  </div>
</div>
<!-- <div class="lottery-banner" [ngClass]="{'close':closeBanner}">
  <div class="close-banner" (click)="toggleBanner()">
     <fa-icon [icon]="['fas', 'chevron-down']" *ngIf="!closeBanner"></fa-icon>
     <fa-icon [icon]="['fas', 'chevron-up']" *ngIf="closeBanner"></fa-icon>
  </div>
 <div class="lottery-text">
    <h1>🎉</h1>
    <h1>Get your free GEN0 Kryptomon</h1>
    <p>To celebrate the upcoming launch of the Kryptomons world we're going to airdrop the first and only 100 Gen0 Kryptmons' Eggs.</p>
    <a href="#uvembed113534" class="signup-btn" angulartics2On="click" angularticsAction="ClaimYours" angularticsCategory="home">Claim yours!</a>
  </div>
</div>-->

<section class="section-footer">
    <footer class="footer-container">
        <div class="top-footer">
            <img class="logo-footer" src="../../../assets/img/Home/Kryptomon_logo.svg" alt="logo" />
            <div class="image-footer">
                <img src="../../../assets/img/Home/meta-icon.png" alt="metamask">
                <img src="../../../assets/img/Home/binance-coin-bnb-logo.png" alt="binance" />
            </div>
        </div>
        <div class="copyright">Copyright @ 2021, <br> Kryptomon Company</div>
        <div class="bottom-footer">
            <ul>
                <li (click)="openAccessibilityStatement()">Accessibility statement</li>
                <li (click)="openPrivacyPolicy()">Privacy policy</li>
                <li (click)="openLicenceAgreement()">Terms of service</li>
            </ul>
        </div>

    </footer>
</section>
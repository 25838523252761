import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router'
import { Angulartics2 } from 'angulartics2';
@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TeamComponent implements OnInit {

  public team
  public advisors
  public partners

  constructor(public router: Router, public angulartics2: Angulartics2) { 
  	this.team = [
	  	{
	  		img: "./assets/img/team/umberto.jpg",
	  		name: "Umberto",
	  		role: "Product & Operations Lead",
	  		bio: "Creating new products and see people love them is what moves me! This is what I do in my daily life as Venture Builder",
	  		linkedin:"https://www.linkedin.com/in/umbertocanessa/"
	  	},{
	  		img: "./assets/img/team/berenice.jpg",
	  		name: "Berenice",
	  		role: "Growth & Community",
	  		bio:"Growth and communication are my elements. I have a weakness for Art and I love to connect the dots...",
	  		linkedin:"https://www.linkedin.com/in/berenicedimatto/"
	  	},{
	  		img: "./assets/img/team/maurice.jpg",
	  		name: "Maurice",
	  		role: "Design Lead",
	  		bio:"Designer, entrepreneur, and recordcollector who builds products and brands for scale , this by Adding value though design.",
	  		linkedin:"https://www.linkedin.com/in/mauricedevries/"
	  	},{
	  		img: "./assets/img/team/mirdad.jpg",
	  		name: "Mirdad",
	  		role: "Front-end Developer",
	  		bio:"It’s amazing to know that people are using something that you’ve helped building. Translating design into code is what I do on a daily base.",
	  		linkedin:"https://www.linkedin.com/in/mirdad-vries-0b8878146/"
	  	},{
	  		img: "./assets/img/team/claudio.jpg",
	  		name: "Claudio",
	  		role: "Growth & Data",
	  		bio:"Read the data, listen to the users, and then connect all together to create the highest value possible, best feeling ever.",
	  		linkedin:"https://www.linkedin.com/in/claudiocuccovillo/"
	  	},{
	  		img: "./assets/img/team/bartolomeo.jpg",
	  		name: "Bartolomeo",
	  		role: "Fundraising Coordinator",
	  		bio:"Numbers & Innovation are my passion, I love to measure the impact of everything we do.",
	  		linkedin:"https://www.linkedin.com/in/bartolomeodevitis/"
	  	},{
	  		img: "./assets/img/team/chris.jpg",
	  		name: "Chris",
	  		role: "Blockchain Dev Lead",
	  		bio:"I'm working with Blockchain since 2016 and since then I love it everyday.",
	  		linkedin:"https://www.linkedin.com/in/chrisciszak/"
	  	},{
	  		img: "./assets/img/team/carter.jpg",
	  		name: "Carter",
	  		role: "Content & Lore",
	  		bio:"Writing, world building and watching a community interact with a world I helped create? It really doesn't get any better than that.",
	  		linkedin:"https://www.linkedin.com/in/cxsteam/"
	  	}
  	]

  	this.advisors = [
	  	{
	  		img: "./assets/img/team/stefan.jpg",
	  		name: "Stefan",
	  		role: "ex Director @Pokerstars",
	  		linkedin:"https://www.linkedin.com/in/stefankovach/"
	  	},{
	  		img: "./assets/img/team/toby.jpg",
	  		name: "Toby",
	  		role: "CEO NovumInsight",
	  		linkedin:"https://www.linkedin.com/in/toby-lewis-7aa5a533/"
	  	},{
	  		img: "./assets/img/team/nimrod.jpg",
	  		name: "Nimrod",
	  		role: "Serial Entrepreneur",
	  		linkedin:"https://www.linkedin.com/in/nimimay/"
	  	},{
	  		img: "./assets/img/team/nicola.jpg",
	  		name: "Nicola",
	  		role: "Co-Founder @QueiDueSulServer",
	  		linkedin:"https://www.linkedin.com/in/palmierinicola/"
	  	},{
	  		img: "./assets/img/team/amit.jpg",
	  		name: "Amit",
	  		role: "Crypto Investor",
	  		linkedin:"https://www.linkedin.com/in/peledamit/"
	  	},{
	  		img: "./assets/img/team/davi.jpg",
	  		name: "Davi",
	  		role: "ex President Blockchain @YaleUniversity",
	  		linkedin:"https://www.linkedin.com/in/davillemos/"
	  	}
	  ]

	  this.partners = [
	  	{
	  		img: "./assets/img/team/grendle.png",
	  		name: "Grendel Games",
	  		role: "Game Studio",
	  		linkedin:"https://grendelgames.com/"
	  	},{
	  		img: "./assets/img/team/bga.png",
	  		name: "Blockchain Game Alliance",
	  		role: "Partner",
	  		linkedin:"https://blockchaingamealliance.org"
	  	},{
	  		img: "./assets/img/team/poolz.png",
	  		name: "Poolz",
	  		role: "IDO Partner",
	  		linkedin:"https://poolz.finance/"
	  	},{
	  		img: "./assets/img/team/exntc.png",
	  		name: "Exnetwork Capital",
	  		role: "Investor",
	  		linkedin:"https://exnetworkcapital.com/"
	  	},{
	  		img: "./assets/img/team/phoenix.png",
	  		name: "Phoenix VC",
	  		role: "Investor",
	  		linkedin:"https://www.phoenix-vc.com/"
	  	},{
	  		img: "./assets/img/team/moondot.png",
	  		name: "MoonDot",
	  		role: "Investor",
	  		linkedin:"https://moondot.capital/"
	  	},{
	  		img: "./assets/img/team/smo.png",
	  		name: "SMO Capital",
	  		role: "Investor",
	  		linkedin:"https://www.smocapital.net/"
	  	},{
	  		img: "./assets/img/team/rage.png",
	  		name: "Rage Fun",
	  		role: "Partner",
	  		linkedin:"https://rage.fan/"
	  	},{
	  		img: "./assets/img/team/mochi.png",
	  		name: "Mochi",
	  		role: "Partner",
	  		linkedin:"https://app.mochi.market/"
	  	},{
	  		img: "./assets/img/team/buni.png",
	  		name: "Bunicorn",
	  		role: "Partner",
	  		linkedin:"https://buni.finance/"
	  	},{
	  		img: "./assets/img/team/nftfy.png",
	  		name: "Nftfy",
	  		role: "Partner",
	  		linkedin:"https://nftfy.org"
	  	}

	  ]
  }

  ngOnInit(): void {
  }

   goHome() {
    console.log('home')
    location.href = 'https://kryptomon.co'      // or without async/await you can simply chain the promises
  }

    WhitePaper(){
      this.router.navigate(["/white-paper"])    // or without async/await you can simply chain the promises
      this.angulartics2.eventTrack.next({ 
      action: 'GoToWhitepaper', 
      properties: { category: 'home' },
    });
  }

   tokenomicsPage() {
    this.router.navigate(["/tokenomics"])      // or without async/await you can simply chain the promises
    this.angulartics2.eventTrack.next({ 
      action: 'GoToTokenomics', 
      properties: { category: 'home' },
    });
  }

}

import { Component, OnInit } from "@angular/core";
import { AppComponent } from "../../app.component";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-internal",
  templateUrl: "./internal.component.html",
  styleUrls: ["./internal.component.scss"],
})
export class InternalComponent implements OnInit {
  public account;

  constructor(public appComponent: AppComponent, public http: HttpClient) {
    this.appComponent.accountsObservable.subscribe((item) => {
      this.account = item[0];
      let c = 6024;
      this.parseGenome(c);
    });
  }

  async parseGenome(index) {
    await this.appComponent.contractService.contractInstance.methods
      .getKryptomonDetails(index)
      .call({
        from: this.account,
      })
      .then((kryptomon) => {
          let post = {
            dna: kryptomon[1],
            id: kryptomon[0],
            unfreezable: kryptomon[6][0],
            network: this.appComponent.contractService.networkName,
          };
          console.log(post)
          /*this.http
            .post(
              "https://api.kryptomon.co/json/kryptomon/saveGenome.php",
              post
            )
            .subscribe((item) => {
              console.log(kryptomon[0]);
              index++;
              //this.parseGenome(index);
            });*/
      })
      .catch((err) => {
        console.log(err, "err");
      });
  }

  ngOnInit(): void {}
}
import { Component, OnInit, ViewEncapsulation, AfterViewInit, NgZone, PLATFORM_ID, Inject } from '@angular/core';
import { ContractService } from '../../services/contract.service';
import { SparksService } from '../../services/sparks.service';
import { ActivatedRoute } from '@angular/router'
import { isPlatformBrowser } from '@angular/common';
import { ThemeService } from 'ng-bootstrap-darkmode';
import { HttpClient } from '@angular/common/http';
import * as merkle_eggHunt from './merkle-egg-hunt15.json';
import * as merkle_eggHunt2 from './merkle-egg-hunt15.1.json';
import * as merkle_cryptocom from './merkle-cryptocom-02.json';
import * as merkle_cryptocom2 from './merkle-cryptocom-1.json';
import * as merkle_cryptocom3 from './merkle-cryptocom-2.json';
import * as merkle_cryptocom4 from './merkle-cryptocom-3.json';
import * as merkle_cryptocom5 from './merkle-cryptocom-4.json';
import * as airdrop2 from './egg_hunt_kmon_airdrop11.json';
import * as airdrop from './egg_hunt_kmon_airdrop9.1.json';
import { Router } from '@angular/router'
import { AppComponent } from 'src/app/app.component';
import { fetchJson } from '@ethersproject/web';
import * as rewards from './rewards';
import { BigNumber } from 'ethers';

declare const tsParticles;

const isEmpty = (obj) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key))
      return true;
  }
  return false;
}

interface httpResult {
  status
  data
  description
}

interface Kryptomons {
  image
  type
  talent
  typeFormatted
  xfactor
  speciality
  timeBorn
  super
  personality
  generation
  id
  islandImage
  timeBornUnix
  unfreezable
  bio
}

interface Personality {
  Constitution
  Affection
  Crazyness
  Instinct
  Hunger
  Laziness
  Braveness
  Smart
  Ego
  generation
}


@Component({
  selector: 'app-market',
  templateUrl: './market.component.html',
  styleUrls: ['./market.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MarketComponent implements OnInit {


  public connection_text
  public noWallet
  public accounts
  public disabled
  public claimed
  public listedOnDb
  public notListed
  public showShare
  public modalImage
  public modalTitle
  public modalContent
  public modalCta
  public isLoadingBuy
  public actionTodo
  public kryptomons
  public typeFormatted = ["Fire", "Water", "Ice", "Ground", "Air", "Electro", "Ghost", "Grass"];
  public collectedKryptomon
  public isClosed
  public airdropClaim
  public availableRewards = []
  public availableCryptoComClaims = []

  constructor(public appComponent: AppComponent, public contractService: ContractService, public http: HttpClient, public router: Router) {

    this.disabled = [false, true, true, false]
    this.claimed = [false, false];
    this.notListed = false;
    this.showShare = false;
    this.isLoadingBuy = false;
    this.kryptomons = []
    this.collectedKryptomon = false;
    this.isClosed = false;
    this.airdropClaim = null;

    if (this.appComponent.accounts == undefined) {
        this.appComponent.accountsObservable.subscribe((item) => {
          this.noWallet = false;
          //console.log("quauauau")
          this.getAccounts().then(() => {
            this.checkEggHuntClaim();
            this.checkAirdrop();
            this.checkAirdrop2();
            this.checkRewards();
            this.checkCryptoComClaim();
          });
        });
      } else {
        this.noWallet = false;
        //console.log("quauauau")
        this.getAccounts().then(() => {
          this.checkEggHuntClaim();
          this.checkAirdrop();
          this.checkAirdrop2();
          this.checkRewards();
          this.checkCryptoComClaim();
        });
      }

    //this.checkClose()
  }

  async getAccounts() {
    this.accounts = await this.contractService.web3.eth.getAccounts().then((accounts) => {
      //console.log(accounts)
      return accounts;
    }).catch((err) => {
      ////console.log(err, 'err!!');
    });
    if (this.accounts.length == 0) {
      this.connection_text = "Grant access";
    } else {
      this.connection_text = "Connected";
    }
  }

  ngOnInit(): void {
  }

  async checkEggHuntClaim() {
    const claim = merkle_eggHunt.default.claims[this.accounts[0]];
    const claim2 = merkle_eggHunt2.default.claims[this.accounts[0]];
    if (claim == undefined && claim2 == undefined) {
      this.disabled[3] = true;
    } else {
      // check if the claim has been completed
      let isClaimed = false;
      if (claim != undefined)
        isClaimed = await this.isClaimed(claim, this.contractService.contractMerkleEggHunt);

      let isClaimed2 = false;
      if (claim2 != undefined)
        isClaimed2 = await this.isClaimed(claim2, this.contractService.contractMerkleEggHunt2);

      if (isClaimed && isClaimed2) {
        this.notListed = true;
        this.disabled[3] = true;
        this.claimed[3] = true;
        console.log('not claimed')
      }
      else {
        this.notListed = false;
        this.disabled[3] = false;
        this.claimed[3] = false;
        console.log('claimed')
      }
    }

  }

  getUserCryptoComClaims(account) {
    const claims = [];
    for(let i = 0; i < 100; i ++){
      let claim = merkle_cryptocom.default.claims[account + "-" + i];
      if(claim){
        claim.contractAddress = merkle_cryptocom.default.contractAddress;
        claims.push(claim);
      }

      claim = merkle_cryptocom2.default.claims[account + "-" + i];
      if(claim){
        claim.contractAddress = merkle_cryptocom2.default.contractAddress;
        claims.push(claim);
      }

      claim = merkle_cryptocom3.default.claims[account + "-" + i];
      if(claim){
        claim.contractAddress = merkle_cryptocom3.default.contractAddress;
        claims.push(claim);
      }

      claim = merkle_cryptocom4.default.claims[account + "-" + i];
      if(claim){
        claim.contractAddress = merkle_cryptocom4.default.contractAddress;
        claims.push(claim);
      }

      claim = merkle_cryptocom5.default.claims[account + "-" + i];
      if(claim){
        claim.contractAddress = merkle_cryptocom5.default.contractAddress;
        claims.push(claim);
      }
    }
    return claims;
  }

  async checkCryptoComClaim() {
    const claims = this.getUserCryptoComClaims(this.accounts[0]);

    let isClaimed = true;
    for(let i = 0; i < claims.length; i++){
      const claimContract = await new this.contractService.web3.eth.Contract(this.contractService.merkleAbi, claims[i].contractAddress);
      isClaimed = await this.isClaimed(claims[i], claimContract);
      if(!isClaimed){
        this.availableCryptoComClaims.push(claims[i]);
      }
    }
  }

  async claimCryptoCom() {
    if(this.availableCryptoComClaims.length == 0) return;

    const claim = this.availableCryptoComClaims[this.availableCryptoComClaims.length - 1];
    const claimContract = await new this.contractService.web3.eth.Contract(this.contractService.merkleAbi, claim.contractAddress);
    this.executeCryptoComClaim(claim, claimContract);
  }

  async claimHunt() {
    this.isLoadingBuy = true;
    const claim = merkle_eggHunt.default.claims[this.accounts[0]];
    claim.account = this.accounts[0];
    const claim2 = undefined; //merkle_eggHunt2.default.claims[this.accounts[0]];

    if (claim !== undefined) {
      if(!await this.isClaimed(claim, this.contractService.contractMerkleEggHunt))
        await this.processClaim(claim, this.contractService.contractMerkleEggHunt)
    }
    if (claim2 !== undefined) {
      if(!await this.isClaimed(claim2, this.contractService.contractMerkleEggHunt2))
        await this.processClaim(claim2, this.contractService.contractMerkleEggHunt2)
    }
  }

  async checkRewards() {
    const claims = [];
    for (const [key, value] of Object.entries(rewards)) {
      // find claims
      if (value.claims[this.accounts[0]]) {
        claims.push({
          contractAddress: value.contractAddress,
          claim: value.claims[this.accounts[0]]
        })
        claims[claims.length - 1].claim.address = this.accounts[0];
      }
    }

    for(let i = 0; i < claims.length; i++){
      const claim = claims[i];
      const contract = await new this.contractService.web3.eth.Contract(this.contractService.merkleAirdrop.abi, claim.contractAddress);
      if(!await this.isClaimed(claim.claim, contract)){
        this.availableRewards.push(claim);
      }
    }
  }

  getTotalKMONRewards() {
    const initialValue = BigNumber.from("0");
    const kmonValue = this.availableRewards.reduce((prev, cur,) => {
      const curValue = BigNumber.from(cur.claim.amount);
      return prev.add(curValue);
    }, initialValue);

    if(kmonValue)
      return this.contractService.web3.utils.fromWei(kmonValue.toString());
    
    return 0;
  }

  async claimRewards() {
    for (var i = 0; i < this.availableRewards.length; i++) {
      var claim = this.availableRewards[i];
      this.executeRewardsClaim(claim.claim, await new this.contractService.web3.eth.Contract(this.contractService.merkleAirdrop.abi, claim.contractAddress));
    }
  }

  async isClaimed(claim, merkleInstance) {
    return await merkleInstance.methods.isClaimed(claim.index).call();
  }

  async getClaim() {
    try{
      this.airdropClaim = await fetchJson(this.appComponent.contractService.apiEndPoint + '/airdrop/' + this.accounts[0]);
    }
    catch{
      this.airdropClaim = undefined;
    }
  }

  async checkAirdrop() {
    const airdropClaim = airdrop.default.claims[this.accounts[0]];
    if (airdropClaim == undefined) {
      this.disabled[0] = true;
    } else {
      const isClaimed = await this.isClaimed(airdropClaim, this.contractService.contractAirdropMerkleInstance);
      if(!isClaimed){
        this.disabled[0] = false;
        this.claimed[0] = false;
      }
      else {
        this.disabled[0] = true;
        this.claimed[0] = true;
      }
    }
  }

  async checkAirdrop2() {
    const airdropClaim = airdrop2.default.claims[this.accounts[0]];
    if (airdropClaim == undefined) {
      this.disabled[2] = true;
    } else {
      const isClaimed = await this.isClaimed(airdropClaim, this.contractService.contractAirdropMerkleInstance2);
      if(!isClaimed){
        this.disabled[2] = false;
        this.claimed[2] = false;
      }
      else {
        this.disabled[2] = true;
        this.claimed[2] = true;
      }
    }
  }

  showModal(what) {
    if (what == 'airdrop' && !this.disabled[0]) {
      const airdropClaim = airdrop.default.claims[this.accounts[0]];
      this.showShare = true;
      this.modalImage = "coin.png";
      this.modalTitle = "Physital NFT Campaign";
      this.modalContent = "Thanks a lot for your hard work! You've earned " + parseFloat(this.contractService.web3.utils.fromWei(airdropClaim.amount)).toFixed(2) + " KMON for your airdrop! Make sure you've got some BNB to pay for the network gas fees."
      this.actionTodo = "collect";
      this.modalCta = "Collect!"
    } else if (what == 'sale' && !this.disabled[2]) {
      const airdropClaim = airdrop2.default.claims[this.accounts[0]];
      this.showShare = true;
      this.modalImage = "egg-hunt.png";
      this.modalTitle = "Airdrop";
      this.modalContent = "Hope you enjoyed the Treasure Hunt! You've earned " + parseFloat(this.contractService.web3.utils.fromWei(airdropClaim.amount)).toFixed(2) + " KMON for your airdrop! Make sure you've got some BNB to pay for the network gas fees."
      this.actionTodo = "claim";
      this.modalCta = "Claim Now!"
    } else if (what == 'top10' && !this.disabled[1]) {
      this.showShare = true;
      this.modalImage = "tenK.png";
      this.modalTitle = "Top10 Eggdrop";
      this.modalContent = "Hey! First of all thanks a lot for your early support! Now you can claim your free egg! Make sure to have a little bit of BNBs to cover the Gas Fee and remember you can only collect an egg once so if you have already collected an egg this will not work!"
      this.actionTodo = "freeClaim";
      this.modalCta = "Get Egg!"
    }
  }

  doAction() {
    if (this.actionTodo == "freeClaim") {
      //this.getKryptomons();
    } else if (this.actionTodo == "collect") {
      //this.claimAirdrop();
      this.claimAirdropHuobi();
      //this.startConfetti();
    } else {
      this.claimAirdrop2();
    }
  }

  async buyMysteryBox() { 
    this.isLoadingBuy = true;
    const buyClaim = undefined;//merkle_mystery_box.default.claims[this.accounts[0]];

    if(buyClaim !== undefined){
      this.processBuyClaim(buyClaim, this.contractService.contractBuyMysteryBoxMerkleInstance);
    }
  }

  async processBuyClaim(claim, contractMerkleInstance) {
    if (claim !== undefined) {
      if (claim.isPayable) {
        let allowance = await this.contractService.contractBuyTokenInstance.methods.allowance(this.accounts[0], contractMerkleInstance._address).call();
        if (allowance > 0) {
          this.executeClaim(claim, contractMerkleInstance);
        }
        else {
          this.contractService.contractBuyTokenInstance.methods.approve(contractMerkleInstance._address, this.contractService.web3.utils.toWei('999999999999')).send({
            from: this.accounts[0]
          }).then((result) => {
            if (result.events.Approval !== undefined) {
              this.executeClaim(claim, contractMerkleInstance);
            } else {
              this.isLoadingBuy = false;
            }
          }, (error) => {
            //console.log(error)
            this.isLoadingBuy = false;
          })
        }
      }
      else {
        this.executeClaim(claim, contractMerkleInstance);
      }
    } else {
      //console.log('notlisted')
      this.isLoadingBuy = false;
      this.notListed = true;
    }
  }

  async processClaim(claim, contractMerkleInstance) {
    if (claim !== undefined) {
      if (claim.isPayable) {
        let allowance = await this.contractService.contractTokenInstance.methods.allowance(this.accounts[0], contractMerkleInstance._address).call();
        if (allowance > 0) {
          this.executeClaim(claim, contractMerkleInstance);
        }
        else {
          this.contractService.contractTokenInstance.methods.approve(contractMerkleInstance._address, this.contractService.web3.utils.toWei('999999999999')).send({
            from: this.accounts[0]
          }).then((result) => {
            if (result.events.Approval !== undefined) {
              this.executeClaim(claim, contractMerkleInstance);
            } else {
              this.isLoadingBuy = false;
            }
          }, (error) => {
            //console.log(error)
            this.isLoadingBuy = false;
          })
        }
      }
      else {
        this.executeClaim(claim, contractMerkleInstance);
      }
    } else {
      //console.log('notlisted')
      this.isLoadingBuy = false;
      this.notListed = true;
    }
  }

  async executeRewardsClaim(claim, contractMerkleInstance) {
    if (claim !== undefined) {
      await contractMerkleInstance.methods.claim(claim.index, claim.address, claim.amount, claim.proof).send({
        from: this.accounts[0]
      }).then((receipt) => {
        console.log("claimed");
        console.log(receipt);
        // remove from available claims
        const indexToRemove = this.availableRewards.indexOf({ contractAddress: contractMerkleInstance._address, claim});
        this.availableRewards.splice(indexToRemove, 1);
      }).catch((err) => {
        ////console.log(err, 'err');
      });
    } else {
      //console.log('notlisted')
    }
  }

  async executeAirdropClaim(claim, contractMerkleInstance) {
    if (claim !== undefined) {
      this.notListed = false;
      await contractMerkleInstance.methods.claim(claim.index, claim.address, claim.amount, claim.proof).send({
        from: this.accounts[0]
      }).then((receipt) => {
        //console.log(receipt);
        if (isEmpty(receipt.events)) {
          setTimeout(() => {
            this.startConfetti()
          }, 1000)
        }
        this.isLoadingBuy = false;
      }).catch((err) => {
        ////console.log(err, 'err');
        this.isLoadingBuy = false;
      });
    } else {
      //console.log('notlisted')
      this.isLoadingBuy = false;
      this.notListed = true;
    }
  }

  async executeCryptoComClaim(claim, contractMerkleInstance) {
    if (claim !== undefined) {
      this.notListed = false;
      await contractMerkleInstance.methods.claim(claim.index, claim.address, claim.generation, claim.isPayable, claim.proof).send({
        from: this.accounts[0]
      }).then((receipt) => {
        //console.log(receipt);
        if (isEmpty(receipt.events)) {
          this.getKryptomons();
          this.availableCryptoComClaims.pop();
        }
        this.isLoadingBuy = false;
      }).catch((err) => {
        ////console.log(err, 'err');
        this.isLoadingBuy = false;
      });
    } else {
      //console.log('notlisted')
      this.isLoadingBuy = false;
      this.notListed = true;
    }
  }

  async executeClaim(claim, contractMerkleInstance) {
    if (claim !== undefined) {
      this.notListed = false;
      await contractMerkleInstance.methods.claim(claim.index, claim.account, claim.generation, claim.isPayable, claim.proof).send({
        from: this.accounts[0]
      }).then((receipt) => {
        //console.log(receipt);
        if (isEmpty(receipt.events)) {
          this.getKryptomons();
        }
        this.isLoadingBuy = false;
      }).catch((err) => {
        ////console.log(err, 'err');
        this.isLoadingBuy = false;
      });
    } else {
      //console.log('notlisted')
      this.isLoadingBuy = false;
      this.notListed = true;
    }
  }

  async claimAirdrop() { 
    this.isLoadingBuy = true;

    if(this.airdropClaim !== undefined){
      this.executeAirdropClaim(this.airdropClaim, this.contractService.contractAirdropMerkleInstance)
    }
  }

  async claimAirdropHuobi() { 
    this.isLoadingBuy = true;
    const airdropClaim = airdrop.default.claims[this.accounts[0]];

    if(airdropClaim !== undefined){
      airdropClaim.address = this.accounts[0];
      this.executeAirdropClaim(airdropClaim, this.contractService.contractAirdropMerkleInstance)
    }
  }

  async claimAirdrop2() { 
    this.isLoadingBuy = true;
    const airdropClaim = airdrop2.default.claims[this.accounts[0]];

    if(airdropClaim !== undefined){
      airdropClaim.address = this.accounts[0];
      this.executeAirdropClaim(airdropClaim, this.contractService.contractAirdropMerkleInstance2)
    }
  }

  async getKryptomons() {
    this.contractService.contractInstance.methods.ownedKryptomons().call({
      from: this.accounts[0],
    }).then((receipt) => {
      //////console.log(receipt)
      if (receipt.length > 0) {
        this.getKryptomonDetails(receipt[receipt.length - 1])
      } else {
        
      }
    }).catch((err) => {
      ////console.log(err, 'err');
    });
  }

  async getKryptomonDetails(receipt) {
    await this.contractService.contractInstance.methods.getKryptomonDetails(receipt).call({
      from: this.accounts[0],
    }).then((kryptomon) => {
      this.kryptomons.push({
        id: kryptomon[0],
        genes: kryptomon[1],
        matron: kryptomon[2],
        sire: kryptomon[3],
        timeBorn: kryptomon[4],
        status: kryptomon[7],
        timeHatched: kryptomon[8],
        timeToHatch: kryptomon[9],
        extra_data: kryptomon[6]
      });
      this.kryptomonImage();
    }).catch((err) => {
      ////console.log(err, 'err');
    });
  }

  async kryptomonImage() {
    for (let x = 0; x < this.kryptomons.length; x++) {
      let genes = this.kryptomons[x].genes;
      /*01->Fuoco
      23->Acqua
      45->Ghiaccio
      67->Terra
      78->Aria
      910->Elettricità
      1112->Spettro
      1314->Erba*/
      let typeDraft = [];
      let type = [];
      let c = 0;
      for (let i = 0; i < 8; i++) {
        let sum = parseInt(genes[c]) * parseInt(genes[c + 1]);
        c = c + 2;
        typeDraft.push(sum);
      }
      let typeSelected = this.indexOfMax(typeDraft)
      let kryptomon = {} as Kryptomons;
      kryptomon.type = (typeSelected);
      kryptomon.talent = (typeDraft[typeSelected])
      kryptomon.typeFormatted = (this.typeFormatted[typeSelected]);

      let timeBorn = new Date(this.kryptomons[x].timeBorn * 1000)
      let month = timeBorn.toLocaleString('default', { month: 'short' });
      let day = timeBorn.getDate();
      let year = timeBorn.getFullYear();
      kryptomon.timeBorn = month + " " + day + " " + year;
      kryptomon.timeBornUnix = this.kryptomons[x].timeBorn;

      if (genes[19] > (genes[20] * 1.10)) {
        kryptomon.speciality = "attack";
      } else if (genes[20] > (genes[19] * 1.10)) {
        kryptomon.speciality = "defense";
      } else {
        kryptomon.speciality = "balance";
      }

      if (genes[22] == 0) {
        kryptomon.super = false;
        var img_selected = kryptomon.speciality;
      } else {
        img_selected = 'super';
        kryptomon.super = true;
      }

      kryptomon.unfreezable = this.kryptomons[x].extra_data[0];

      //console.log(kryptomon.unfreezable)

      if (kryptomon.unfreezable == 1) {
        kryptomon.image = "./assets/img/top_special/" + kryptomon.typeFormatted + "/" + img_selected + ".png";
      } else {
        kryptomon.image = "./assets/img/" + kryptomon.typeFormatted + "/" + img_selected + ".png";
      }

      kryptomon.islandImage = "./assets/img/top_special/" + kryptomon.typeFormatted + "/island.png";

      //kryptomon.image = "./assets/img/"+kryptomon.typeFormatted+"/"+img_selected+".png";
      //kryptomon.renderedImage = "./assets/img/"+kryptomon.typeFormatted+"/animated/"+img_selected+".json";
      let personality = {} as Personality
      personality.Constitution = genes[24];
      personality.Affection = genes[27];
      personality.Crazyness = genes[28];
      personality.Instinct = genes[29];
      personality.Hunger = genes[30];
      personality.Laziness = genes[31];
      personality.Braveness = genes[32];
      personality.Smart = genes[33];
      personality.Ego = genes[35];
      kryptomon.personality = personality;
      kryptomon.generation = genes[genes.length - 1];
      kryptomon.id = this.kryptomons[x].id;
      this.kryptomons[x].data = kryptomon;

      let post = {
        meta: kryptomon,
        dna: genes,
        network: this.contractService.networkName
      }

      this.http.post("https://api.kryptomon.co/json/kryptomon/checkMeta.php", post).subscribe((item: httpResult) => {
        if (item.status == "empty") {
          this.http.post("https://api.kryptomon.co/json/kryptomon/save_meta.php", post).subscribe((item) => {
            this.kryptomons[x].newborn = false;

          }, (error) => {
            //console.log(error)
          })
        }
      }, (error) => {
        //console.log(error)
      })
    }

    this.collectedKryptomon = true;
    this.isLoadingBuy = false;
    setTimeout(() => {
      this.startConfetti()
    }, 1000)
  }


  showModalConnect() {
    console.log('connect')
    this.contractService.mount().then((data) => {
      console.log('data')
      console.log(data)
      if (data !== 'nomask' && data !== 'denied_access' && data !== 'wrong_network') {
        //console.log("quauauau")
        this.noWallet = false;
        this.getAccounts().then(() => { });
      } else {
        console.log('wut')
        if (data == "nomask") {
          this.noWallet = true;
          this.connection_text = "No wallet"
        } else if (data == "denied_access") {
          this.noWallet = true;
          this.connection_text = "Grant access"
          //console.log("qua!=")
        } else {
          this.noWallet = true;
          this.connection_text = "Wrong Network"
        }
      }
    }, (error) => {
      console.log('cia')
    });
  }

  indexOfMax(arr) {
    if (arr.length === 0) {
      return -1;
    }

    var max = arr[0];
    var maxIndex = 0;

    for (var i = 1; i < arr.length; i++) {
      if (arr[i] > max) {
        maxIndex = i;
        max = arr[i];
      }
    }

    return maxIndex;
  }

  closeShare() {
    this.showShare = false;
    this.collectedKryptomon = false;
  }

  connectDisconnectProvider() {
    if (this.connection_text == "Connected") {
      this.contractService.disconnectProvider();
    } else {
      this.showModalConnect();
    }
  }

  startConfetti() {
    tsParticles.load("tsparticles", {
      fpsLimit: 60,
      particles: {
        number: {
          value: 0
        },
        color: {
          value: ["#00FFFC", "#FC00FF", "#fffc00"]
        },
        shape: {
          type: "confetti",
          options: {
            confetti: {
              type: ["circle", "square"]
            }
          }
        },
        opacity: {
          value: 1,
          animation: {
            enable: true,
            minimumValue: 0,
            speed: 2,
            startValue: "max",
            destroy: "min"
          }
        },
        size: {
          value: 7,
          random: {
            enable: true,
            minimumValue: 3
          }
        },
        links: {
          enable: false
        },
        life: {
          duration: {
            sync: true,
            value: 5
          },
          count: 1
        },
        move: {
          enable: true,
          gravity: {
            enable: true,
            acceleration: 20
          },
          speed: 20,
          decay: 0.1,
          direction: "none",
          random: false,
          straight: false,
          outModes: {
            default: "destroy",
            top: "none"
          }
        }
      },
      interactivity: {
        detectsOn: "window",
        events: {
          resize: true
        }
      },
      detectRetina: true,
      background: {
        color: "#232123"
      },
      emitters: {
        direction: "none",
        life: {
          count: 0,
          duration: 0.1,
          delay: 0.4
        },
        rate: {
          delay: 0.1,
          quantity: 100
        },
        size: {
          width: 0,
          height: 0
        }
      }
    });
  }

  gotoDashboard() {
    this.router.navigate(['/']);
  }

}

import { Component, OnInit } from '@angular/core';
import {ContractService} from '../../services/contract.service';
import { HttpClient } from '@angular/common/http';

interface merkleResponse{
  amount
  flags
  index
  proof
}

@Component({
  selector: 'app-merkle-test',
  templateUrl: './merkle-test.component.html',
  styleUrls: ['./merkle-test.component.scss']
})

export class MerkleTestComponent implements OnInit {

  public accounts

  constructor(public contractService: ContractService, public http:HttpClient) { 

    let stillBuy = [];

    this.contractService.mount().then((data)=>{
      console.log(data)
     // this.getAccounts();

     this.http.get("https://api.kryptomon.co/merkle/getSaleJson.php").subscribe(async item=>{
         
         let response = JSON.parse(JSON.stringify(item));

         console.log(response[0])

         for(var i = 0; i<response.length;i++){

         }
         console.log(stillBuy)     
     })



    })

    
  }

  async isClaimed(claim, merkleInstance) {
    return await merkleInstance.methods.isClaimed(claim.index).call();
  }

  ngOnInit(): void {
  }

  /*async getAccounts(){
      this.accounts = await this.contractService.web3.eth.getAccounts().then((accounts) => {
        console.log(accounts)
        return accounts;
      }).catch((err) => {
        //console.log(err, 'err!!');
      });
      this.checkMerkle();
    }

  checkMerkle(){
    let data = {address: "0x0000000000000D9054F605cA65A2647c2B521422"}
    this.http.post("https://api.kryptomon.co/merkle/check.php", data).subscribe((item:merkleResponse)=>{
      console.log(item)
      if(item!==undefined && item!==null){
        this.contractService.contractMerkleInstance.methods.isClaimed(item.index).call().then((response)=>{
          console.log(response)
          if(response==false){
            this.contractService.contractMerkleInstance.methods.claim(item.index, this.accounts[0], item.amount, item.proof).send({
              from:this.accounts[0]
            }).then((response)=>{
              console.log(response)
            }, (error)=>{
              console.log(error)
            })
          }
        }, (error)=>{
          console.log(error)
        })
      }
    })
  }*/

}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
declare const tsParticles;
interface httpResponse{
  status
  token
  address
}

@Component({
  selector: 'app-aidrop-collect',
  templateUrl: './aidrop-collect.component.html',
  styleUrls: ['./aidrop-collect.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class AidropCollectComponent implements OnInit {

  public email
  public token
  public address
  public invalidToken
  public loading
  public errorMsg
  public showExtendedCard
  public successRegister
  public wrongRegister
  public validEmail
  public emailAlreadyUsed
  public saleEgg
  public newAddress
  public changeForm
  public successChange
  public validChange
  constructor(public http:HttpClient) {
    this.loading = false;
    this.showExtendedCard = false;
    this.successRegister = false;
    this.wrongRegister = false;
    this.validEmail = false;
    this.emailAlreadyUsed = false;
    this.saleEgg = false
    this.changeForm = false;
    this.successChange = false;
    this.validChange = false;
    //this.token = this.getParameterByName('token');
    //this.checkToken();
  }

  ngOnInit(): void {
  }

  goHome() {
    console.log('home')
    location.href = 'https://kryptomon.co'      // or without async/await you can simply chain the promises
  }

  checkToken(){ 
    if(this.token!==undefined && this.token!==null){
      let post = {token:this.token}
      this.http.post("https://api.kryptomon.co/merkle/checkToken.php", post).subscribe((item:httpResponse)=>{
        console.log(item)
        if(item.status=="done"){
          console.log('available')
          this.loading = false;
          this.invalidToken = false;
        }else{
          console.log('used')
          this.loading = false;
          this.invalidToken = true;
        }
      })
    }else{
      this.loading = false;
      this.invalidToken = true;
    }
  }

  showChange(){
    this.changeForm = true;
  }

  updateAddress(){
    console.log(this.newAddress)
    this.loading = true;
    if(this.validateAddress(this.newAddress)){
        let post = {email:this.email, token:this.token, address:this.newAddress};
        console.log(post)
         this.http.post("https://api.kryptomon.co/merkle/updateAddress.php", post).subscribe((item:httpResponse)=>{
           console.log(item)
        if(item.status=='done'){
          console.log('done')
          this.successChange = true;
          this.successRegister = true;
          this.showExtendedCard = false;
          this.wrongRegister = false;
        }else{
          this.wrongRegister = true;
          this.errorMsg = "Ops! Something went wrong, retry!";
          console.log('retry')
        }
        this.loading = false;
      })
    }else{
      this.errorMsg = "Wallet address is not valid, please check and retry.";
      this.showExtendedCard = true;
    }
  }

  checkEmail(){
    this.loading = true;
    if(this.validateEmail(this.email)){
      let post = {email:this.email};
      this.http.post("https://api.kryptomon.co/merkle/checkEmail.php", post).subscribe((item:httpResponse)=>{
        if(item.status=='done'){
          this.wrongRegister = false;
          this.validEmail = true;
          this.token = item.token;
          this.loading = false;
          this.validChange = false;
        }else if(item.status=='done_sale'){
          this.wrongRegister = true;
          this.emailAlreadyUsed = true;
          this.token = item.token;
          this.loading = false;
          this.validChange = false;
          this.errorMsg = "Egg Sale whitelisting is closed";
        }else if(item.status=='already'){
          this.emailAlreadyUsed = true;
          this.wrongRegister = true;
          this.errorMsg = "An address has been already whitelisted for the Air drop, and CANNOT be changed:  "+item.address;
          this.loading = false;
          this.validChange = false;
        }else if(item.status=='already_sale'){
          this.emailAlreadyUsed = true;
          this.wrongRegister = true;
          this.errorMsg = "An address has been already whitelisted for the EggSale: "+item.address+", but unfortunately now the sale it's closed.";
          this.loading = false;
          this.validChange = false;
        }else{
          this.wrongRegister = true;
          this.errorMsg = "An error occoured, the email is not matching our Database";
          this.loading = false;
          this.validChange = false;
        }
      })
    }
  }

  saveAddress(){
    this.showExtendedCard = false;
    console.log(this.validateAddress(this.address))
    if(this.validateEmail(this.email) && this.validateAddress(this.address)){
      let post = {email:this.email, token:this.token, address:this.address}
      console.log(post)
      this.loading = true;
      this.http.post("https://api.kryptomon.co/merkle/saveAddress.php", post).subscribe((item:httpResponse)=>{
        console.log(item)
        if(item.status=="done"){
          if(this.token.indexOf("ALL")>=0){
            this.saleEgg = true;
          }else{
            this.saleEgg = false;
          }
          this.successRegister = true;
          this.startConfetti()
          console.log('done')
        }else if(item.status=="retry"){
          this.wrongRegister = true;
          this.errorMsg = "Ops! Something went wrong, retry!";
          console.log('retry')
        }else{
          this.wrongRegister = true;
          this.errorMsg = "An error occoured, the token may have already been used or this the email is not matching our Database";
          console.log('errpr')
        }
        this.loading = false;
      })
    }else{
      if(!this.validateEmail(this.email)){
        this.errorMsg = "Email is not valid, please check and retry.";
        this.showExtendedCard = true;
      }else if(!this.validateAddress(this.address)){
        this.errorMsg = "Wallet address is not valid, please check and retry.";
        this.showExtendedCard = true;
      }
    }
  }

  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
  }

 validateAddress(token){
   const re = /0x[a-fA-F0-9]{40}/g
   return re.test(token)
 }

  startConfetti(){
   tsParticles.load("tsparticles", {
  fpsLimit: 60,
  particles: {
    number: {
      value: 0
    },
    color: {
      value: ["#00FFFC", "#FC00FF", "#fffc00"]
    },
    shape: {
      type: "confetti",
      options: {
        confetti: {
          type: ["circle", "square"]
        }
      }
    },
    opacity: {
      value: 1,
      animation: {
        enable: true,
        minimumValue: 0,
        speed: 2,
        startValue: "max",
        destroy: "min"
      }
    },
    size: {
      value: 7,
      random: {
        enable: true,
        minimumValue: 3
      }
    },
    links: {
      enable: false
    },
    life: {
      duration: {
        sync: true,
        value: 5
      },
      count: 1
    },
    move: {
      enable: true,
      gravity: {
        enable: true,
        acceleration: 20
      },
      speed: 20,
      decay: 0.1,
      direction: "none",
      random: false,
      straight: false,
      outModes: {
        default: "destroy",
        top: "none"
      }
    }
  },
  interactivity: {
    detectsOn: "window",
    events: {
      resize: true
    }
  },
  detectRetina: true,
  background: {
    color: "#232123"
  },
  emitters: {
    direction: "none",
    life: {
      count: 0,
      duration: 0.1,
      delay: 0.4
    },
    rate: {
      delay: 0.1,
      quantity: 100
    },
    size: {
      width: 0,
      height: 0
    }
  }
});
}

}

import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';
import {ContractService} from './services/contract.service';
import { Observable, Subscription, Subject} from 'rxjs';
import {Router, NavigationEnd} from '@angular/router'
import {SimpleGlobal} from 'ng2-simple-global';
import { HttpClient } from "@angular/common/http";
import { ClipboardService } from 'ngx-clipboard'
import { ToastrService } from 'ngx-toastr';
import { $ } from 'protractor';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class AppComponent implements OnInit{
  title = 'KriptomonAng';
  public accounts
  public accountsObservable = new Subject()
  public balance
  public kmonBalance = 0;
  public showMenu
  public connection_text 
  public navigationSelected = [true,false, false, false]
  public invites

  constructor(public toastr: ToastrService, public clipSrv:ClipboardService, public http:HttpClient,angulartics: Angulartics2GoogleGlobalSiteTag, public contractService:ContractService, public router:Router, public sg:SimpleGlobal) {
    this.showMenu = false;
    angulartics.startTracking();
    this.contractService.mount().then((data)=>{
      if(data!=='nomask' && data!=='denied_access' && data !== 'wrong_network'){
        //console.log("quauauau")
          this.getAccounts().then(()=>{
            if(this.accounts.length>0){
              window.analytics.identify(this.accounts[0], { ethAddress: this.accounts[0] })
              this.getKMONBalance()
              setInterval(() => {
                this.getKMONBalance();
              }, 2000)
            }else{
              //error
            }
          })
      }else{
        if(data=="nomask"){
           this.connection_text = "No wallet"
        }else if(data == "denied_access"){
         this.connection_text = "Grant access"
        }else{     
          this.connection_text = "Wrong Network"
        }
      }
    })
    router.events.subscribe((val) => {

      if(val instanceof NavigationEnd) {
        console.log(val.url)
        if(val.url.indexOf('staking')>=0){
          this.navigationSelected = [false,true, false,false];
        }else if(val.url == '/'){
          this.navigationSelected = [true,false, false, false];
        }
        else if(val.url.indexOf('binanceNFT')>=0){
          this.navigationSelected = [false,false, true, false];
        }
        else if(val.url.indexOf('play')>=0){
          this.navigationSelected = [false,false, false, true];
        }
      }

    })


  }

  ngOnInit(){
    window.addEventListener('scroll', ()=>{
      let scrollHeigth;

   if(window.innerWidth < 350){
    scrollHeigth = 10;
   }else if(window.innerWidth < 500 && window.innerWidth > 350){
    scrollHeigth = 10;
   }else if(window.innerWidth < 700 && window.innerWidth > 500){
    scrollHeigth = 10;
   }else if(window.innerWidth < 1028 && window.innerWidth > 700){
    scrollHeigth = 10;
   }else {
     scrollHeigth = 12;
   }
    
    if(window.scrollY >= scrollHeigth){
      this.sg['solidBackground'] = true;
    }else if(window.scrollY < scrollHeigth){
      this.sg['solidBackground'] = false;
    }
    }, true)
  }

  connectDisconnectProvider(){
  if(this.connection_text=="Connected"){
    this.contractService.disconnectProvider();
  }else{
    //this.showModalConnect();
  }
}

  async getAccounts(){
      this.accounts = await this.contractService.web3.eth.getAccounts().then((accounts) => {
        //console.log(accounts)
        return accounts;
      }).catch((err) => {
        ////console.log(err, 'err!!');
      });
      
      if(this.accounts.length==0){
        this.connection_text = "Grant access";
      }else{
        this.connection_text = "Connected";
      }

      this.accountsObservable.next(this.accounts)
      //this.checkWallet();
    }

  async getKMONBalance(){
    this.balance = await this.contractService.contractTokenInstance.methods.balanceOf(this.accounts[0]).call();
    this.kmonBalance = (Math.round(parseFloat(this.contractService.web3.utils.fromWei(this.balance))*100))/100
  }
  showNavMenu(){
    let navMenu = document.getElementById("navMenuItems");
    navMenu.style.display === "none" ? navMenu.style.display = "block" : navMenu.style.display = "none";
  }

  openSwap(){
    window.open("https://pancakeswap.finance/swap?outputCurrency=0xc732b6586a93b6b7cf5fed3470808bc74998224d");
  }

  openMarketPlace(){
    window.open("https://market.kryptomon.co");
    this.showNavMenu()
  }

  openSale(){
    this.router.navigate(['/sale'])
    this.showNavMenu()
  }

  openStaking(){
    this.router.navigate(['/staking'])
    this.showNavMenu()
  }

  openBNFT(){
    this.router.navigate(['/binanceNFT'])
    this.showNavMenu()
  }

  openPlay(){
    this.router.navigate(['/play'])
    this.showNavMenu()
  }

  openDashboard(){
    this.router.navigate(['/'])
    this.showNavMenu()
  }

  openHomePage(){
    window.location.href = "https://kryptomon.co";
  }

  openClaim(){
    this.router.navigate(['/claim']);
    this.showNavMenu();
  }

  showMenuClick(){
    this.showMenu = !this.showMenu
  }

  checkWallet(){
    let post = {address: this.accounts[0]};
    this.http.post("https://api.kryptomon.co/auth/listed.php", post).subscribe((item:any)=>{
      console.log(item)
      if(item.status=="listed"){
        this.invites = item.invites;
      }
    })
  }

  copyClipboard(text: string){
    this.clipSrv.copy(text)
    this.toastr.info('Share this code with your friend to invite them!', 'Copied to clipboard', { timeOut: 5000 });
  }

  // addBSCNetwork(){
  //   const params = [{
  //     chainId: '0x38',
  //     chainName: 'Smart Chain',
  //     nativeCurrency: {
  //       name: 'BNB',
  //       symbol: 'BNB',
  //       decimals: 18
  //     },
  //     rpcUrls: ['https://bsc-dataseed.binance.org/'],
  //     blockExplorerUrls: ['https://bscscan.com']
  //   }]
  
  //   window.ethereum.request({ method: 'wallet_addEthereumChain', params })
  //     .then(() => console.log('Success'))
  //     .catch((error: Error) => console.log("Error", error.message))
    
  // }

  async addKMONToken(){
    const tokenAddress = this.contractService.tokenAddress;
    const tokenSymbol = "KMON";
    const tokenDecimals = 18;
    const tokenImage = "https://s2.coinmarketcap.com/static/img/coins/64x64/11291.png";
    
    try {
      const wasAdded = await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage,
          },
        },
      });

    if (wasAdded) {
      console.log("Thanks for your interest!");
    } else {
      console.log("KMON token was not added");
    }
  } catch (error) {
    console.log(error);
  }
    this.showNavMenu()
  }
}

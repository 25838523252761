import { Component, OnInit, ViewEncapsulation, AfterViewInit, NgZone, PLATFORM_ID, Inject } from '@angular/core';
import { ContractService } from '../../services/contract.service';
import { SparksService } from '../../services/sparks.service';
import { ActivatedRoute } from '@angular/router'
import { isPlatformBrowser } from '@angular/common';
import { ThemeService } from 'ng-bootstrap-darkmode';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router'
import { AppComponent } from 'src/app/app.component';
declare const tsParticles;

const isEmpty = (obj) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key))
      return true;
  }
  return false;
}

interface httpResult {
  status
  data
  description
}

interface Kryptomons {
  image
  type
  talent
  typeFormatted
  xfactor
  speciality
  timeBorn
  super
  personality
  generation
  id
  islandImage
  timeBornUnix
  unfreezable
  bio
}

interface Personality {
  Constitution
  Affection
  Crazyness
  Instinct
  Hunger
  Laziness
  Braveness
  Smart
  Ego
  generation
}


@Component({
  selector: 'app-binanceNFT',
  templateUrl: './binanceNFT.component.html',
  styleUrls: ['./binanceNFT.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BinanceNFTComponent implements OnInit {


  public connection_text
  public noWallet
  public accounts
  public disabled
  public claimed
  public listedOnDb
  public notListed
  public showShare
  public modalImage
  public modalTitle
  public modalContent
  public modalCta
  public isLoadingBNFT
  public isApproved
  public isApproved2
  public actionTodo
  public kryptomons
  public typeFormatted = ["Fire", "Water", "Ice", "Ground", "Air", "Electro", "Ghost", "Grass"];
  public collectedKryptomon
  public isClosed
  public ownsPartnerNFT
  public ownsPartnerNFT2
  public ownedPartnerNFTs
  public ownedPartnerNFTs2

  constructor(public appComponent: AppComponent, public contractService: ContractService, public http: HttpClient, public router: Router) {

    this.disabled = [false];
    this.notListed = false;
    this.showShare = false;
    this.isLoadingBNFT = false;
    this.isApproved = false;
    this.kryptomons = []
    this.collectedKryptomon = false;
    this.isClosed = false;
    this.ownsPartnerNFT = false;
    this.ownedPartnerNFTs = [];
    this.ownedPartnerNFTs2 = [];

    if (this.appComponent.accounts == undefined) {
      this.appComponent.accountsObservable.subscribe((item) => {
        this.noWallet = false;
        //console.log("quauauau")
        this.getAccounts().then(() => {
          this.checkIsApproved();
          this.checkIsApproved2();
          this.checkBNFTClaim();
          this.checkBNFTClaim2();
        });
      });
    } else {
      this.noWallet = false;
      //console.log("quauauau")
      this.getAccounts().then(() => {
        this.checkIsApproved();
        this.checkIsApproved2();
        this.checkBNFTClaim();
        this.checkBNFTClaim();
      });
    }
  }

  async getAccounts() {
    this.accounts = await this.contractService.web3.eth.getAccounts().then((accounts) => {
      //console.log(accounts)
      return accounts;
    }).catch((err) => {
      ////console.log(err, 'err!!');
    });
    if (this.accounts.length == 0) {
      this.connection_text = "Grant access";
    } else {
      this.connection_text = "Connected";
    }
  }

  ngOnInit(): void {
  }

  async isClaimed(claim, merkleInstance) {
    return await merkleInstance.methods.isClaimed(claim.index).call();
  }

  showModal(what) {
    if (what == 'sale' && !this.disabled[0]) {
      this.showShare = true;
      this.modalImage = "coin.png";
      this.modalTitle = "Egg Sale";
      this.modalContent = "You are up to buy an Egg from our Market, make sure to have at least 5BUSD & some BNBs for the Gas Fees, remember you can only buy an egg once so if you have already bought an egg this will not work!"
      this.actionTodo = "buy";
      this.modalCta = "Buy Now!"
    } else if (what == 'mochi' && !this.disabled[2]) {
      this.showShare = true;
      this.modalImage = "mochi.png";
      this.modalTitle = "Mochi Market";
      this.modalContent = "Mochi is a secondary marketplace where you can buy an egg from other trainers, keep in mind that prices are decided by the seller."
      this.actionTodo = "mochi";
      this.modalCta = "Open Mochi!"
    } else if (what == 'top10' && !this.disabled[1]) {
      this.showShare = true;
      this.modalImage = "tenK.png";
      this.modalTitle = "Top10 Eggdrop";
      this.modalContent = "Hey! First of all thanks a lot for your early support! Now you can claim your free egg! Make sure to have a little bit of BNBs to cover the Gas Fee and remember you can only collect an egg once so if you have already collected an egg this will not work!"
      this.actionTodo = "freeClaim";
      this.modalCta = "Get Egg!"
    }
  }

  async approveBNFT() {
    this.isLoadingBNFT = true;
    this.contractService.contractPartnerInstance.methods.setApprovalForAll(this.contractService.contractRedeemNFTInstance._address, true).send({
      from: this.accounts[0]
    }).then(()=>{
      this.isApproved = true;
    }).finally(()=>{
      this.isLoadingBNFT = false;
    });
    
  }

  async approveBNFT2() {
    this.isLoadingBNFT = true;
    this.contractService.contractPartnerInstance2.methods.setApprovalForAll(this.contractService.contractRedeemNFTInstance2._address, true).send({
      from: this.accounts[0]
    }).then(()=>{
      this.isApproved2 = true;
    }).finally(()=>{
      this.isLoadingBNFT = false;
    });
    
  }

  async redeemBNFT() {
    this.isLoadingBNFT = true;
    const tokenId = this.ownedPartnerNFTs[this.ownedPartnerNFTs.length-1];
    const nftAddress = this.contractService.contractPartnerInstance._address;
    this.contractService.contractRedeemNFTInstance.methods.redeem(nftAddress, tokenId).send({
      from: this.accounts[0]
    }).then(()=>{
      this.getKryptomons();
    }).catch(()=>{
      this.isLoadingBNFT = false;
    });
  }

  async redeemBNFT2() {
    this.isLoadingBNFT = true;
    const tokenId = this.ownedPartnerNFTs2[this.ownedPartnerNFTs2.length-1];
    const nftAddress = this.contractService.contractPartnerInstance2._address;
    this.contractService.contractRedeemNFTInstance2.methods.redeem(nftAddress, tokenId).send({
      from: this.accounts[0]
    }).then(()=>{
      this.getKryptomons();
    }).catch(()=>{
      this.isLoadingBNFT = false;
    });
  }


  async getKryptomons() {
    this.contractService.contractInstance.methods.ownedKryptomons().call({
      from: this.accounts[0],
    }).then((receipt) => {
      //////console.log(receipt)
      if (receipt.length > 0) {
        this.getKryptomonDetails(receipt[receipt.length - 1])
      } else {
        this.checkBNFTClaim();
        this.checkBNFTClaim2();
      }
    }).catch((err) => {
      ////console.log(err, 'err');
    });
  }

  async getKryptomonDetails(receipt) {
    await this.contractService.contractInstance.methods.getKryptomonDetails(receipt).call({
      from: this.accounts[0],
    }).then((kryptomon) => {
      this.kryptomons.push({
        id: kryptomon[0],
        genes: kryptomon[1],
        matron: kryptomon[2],
        sire: kryptomon[3],
        timeBorn: kryptomon[4],
        status: kryptomon[7],
        timeHatched: kryptomon[8],
        timeToHatch: kryptomon[9],
        extra_data: kryptomon[6]
      });
      this.kryptomonImage();
    }).catch((err) => {
      ////console.log(err, 'err');
    });
  }

  async kryptomonImage() {
    for (let x = 0; x < this.kryptomons.length; x++) {
      let genes = this.kryptomons[x].genes;
      /*01->Fuoco
      23->Acqua
      45->Ghiaccio
      67->Terra
      78->Aria
      910->Elettricità
      1112->Spettro
      1314->Erba*/
      let typeDraft = [];
      let type = [];
      let c = 0;
      for (let i = 0; i < 8; i++) {
        let sum = parseInt(genes[c]) * parseInt(genes[c + 1]);
        c = c + 2;
        typeDraft.push(sum);
      }
      let typeSelected = this.indexOfMax(typeDraft)
      let kryptomon = {} as Kryptomons;
      kryptomon.type = (typeSelected);
      kryptomon.talent = (typeDraft[typeSelected])
      kryptomon.typeFormatted = (this.typeFormatted[typeSelected]);

      let timeBorn = new Date(this.kryptomons[x].timeBorn * 1000)
      let month = timeBorn.toLocaleString('default', { month: 'short' });
      let day = timeBorn.getDate();
      let year = timeBorn.getFullYear();
      kryptomon.timeBorn = month + " " + day + " " + year;
      kryptomon.timeBornUnix = this.kryptomons[x].timeBorn;

      if (genes[19] > (genes[20] * 1.10)) {
        kryptomon.speciality = "attack";
      } else if (genes[20] > (genes[19] * 1.10)) {
        kryptomon.speciality = "defense";
      } else {
        kryptomon.speciality = "balance";
      }

      if (genes[22] == 0) {
        kryptomon.super = false;
        var img_selected = kryptomon.speciality;
      } else {
        img_selected = 'super';
        kryptomon.super = true;
      }

      kryptomon.unfreezable = this.kryptomons[x].extra_data[0];

      //console.log(kryptomon.unfreezable)

      if (kryptomon.unfreezable == 1) {
        kryptomon.image = "./assets/img/top_special/" + kryptomon.typeFormatted + "/" + img_selected + ".png";
      } else {
        kryptomon.image = "./assets/img/" + kryptomon.typeFormatted + "/" + img_selected + ".png";
      }

      kryptomon.islandImage = "./assets/img/top_special/" + kryptomon.typeFormatted + "/island.png";

      //kryptomon.image = "./assets/img/"+kryptomon.typeFormatted+"/"+img_selected+".png";
      //kryptomon.renderedImage = "./assets/img/"+kryptomon.typeFormatted+"/animated/"+img_selected+".json";
      let personality = {} as Personality
      personality.Constitution = genes[24];
      personality.Affection = genes[27];
      personality.Crazyness = genes[28];
      personality.Instinct = genes[29];
      personality.Hunger = genes[30];
      personality.Laziness = genes[31];
      personality.Braveness = genes[32];
      personality.Smart = genes[33];
      personality.Ego = genes[35];
      kryptomon.personality = personality;
      kryptomon.generation = genes[genes.length - 1];
      kryptomon.id = this.kryptomons[x].id;
      this.kryptomons[x].data = kryptomon;

      let post = {
        meta: kryptomon,
        dna: genes,
        network: this.contractService.networkName
      }

      this.http.post("https://api.kryptomon.co/json/kryptomon/checkMeta.php", post).subscribe((item: httpResult) => {
        if (item.status == "empty") {
          this.http.post("https://api.kryptomon.co/json/kryptomon/save_meta.php", post).subscribe((item) => {
            this.kryptomons[x].newborn = false;

          }, (error) => {
            //console.log(error)
          })
        }
      }, (error) => {
        //console.log(error)
      })
    }
    this.startConfetti();
    this.collectedKryptomon = true;
    this.isLoadingBNFT = false;
    this.showShare= true;
  }

  indexOfMax(arr) {
    if (arr.length === 0) {
      return -1;
    }

    var max = arr[0];
    var maxIndex = 0;

    for (var i = 1; i < arr.length; i++) {
      if (arr[i] > max) {
        maxIndex = i;
        max = arr[i];
      }
    }

    return maxIndex;
  }

  closeShare() {
    this.showShare = false;
    this.collectedKryptomon = false;
    this.stopConfetti();
  }

  stopConfetti() {
    const particles = tsParticles.domItem(0);
    particles.destroy();
  }

  startConfetti() {
    const particles = tsParticles.domItem(0);
    if(particles)
    {
      particles.play();
      return;
    }
    
    tsParticles.load("tsparticles", {
      fpsLimit: 60,
      particles: {
        number: {
          value: 0
        },
        color: {
          value: ["#00FFFC", "#FC00FF", "#fffc00"]
        },
        shape: {
          type: "confetti",
          options: {
            confetti: {
              type: ["circle", "square"]
            }
          }
        },
        opacity: {
          value: 1,
          animation: {
            enable: true,
            minimumValue: 0,
            speed: 2,
            startValue: "max",
            destroy: "min"
          }
        },
        size: {
          value: 7,
          random: {
            enable: true,
            minimumValue: 3
          }
        },
        links: {
          enable: false
        },
        life: {
          duration: {
            sync: true,
            value: 5
          },
          count: 1
        },
        move: {
          enable: true,
          gravity: {
            enable: true,
            acceleration: 20
          },
          speed: 20,
          decay: 0.1,
          direction: "none",
          random: false,
          straight: false,
          outModes: {
            default: "destroy",
            top: "none"
          }
        }
      },
      interactivity: {
        detectsOn: "window",
        events: {
          resize: true
        }
      },
      detectRetina: true,
      background: {
        color: "#232123"
      },
      emitters: {
        direction: "none",
        life: {
          count: 0,
          duration: 0.1,
          delay: 0.4
        },
        rate: {
          delay: 0.1,
          quantity: 100
        },
        size: {
          width: 0,
          height: 0
        }
      }
    });
  }

  gotoDashboard() {
    this.router.navigate(['/']);
  }

  async checkBNFTClaim() {
    const userBalance = parseInt(await this.contractService.contractPartnerInstance.methods.balanceOf(this.accounts[0]).call());

    if(userBalance > 0){
      this.ownsPartnerNFT = true;
      for(let i = 0; i < userBalance; i++){
        const tokenId = await this.contractService.contractPartnerInstance.methods.tokenOfOwnerByIndex(this.accounts[0], i).call();
        // call the api
        const address = this.contractService.contractPartnerInstance._address;
        this.ownedPartnerNFTs.push(tokenId);
        const result = await fetch(`https://api-v2.kryptomon.co/redeem/${address}/${tokenId}`)
        console.log(result);
      }
    }
  }

  async checkBNFTClaim2() {
    const userBalance = parseInt(await this.contractService.contractPartnerInstance2.methods.balanceOf(this.accounts[0]).call());

    if(userBalance > 0){
      this.ownsPartnerNFT2 = true;
      for(let i = 0; i < userBalance; i++){
        const tokenId = await this.contractService.contractPartnerInstance2.methods.tokenOfOwnerByIndex(this.accounts[0], i).call();
        // call the api
        const address = this.contractService.contractPartnerInstance2._address;
        this.ownedPartnerNFTs2.push(tokenId);
        const result = await fetch(`https://api-v2.kryptomon.co/redeem/${address}/${tokenId}`)
        console.log(result);
      }
    }
  }

  async checkIsApproved(){
    this.isApproved = await this.contractService.contractPartnerInstance.methods.isApprovedForAll(this.accounts[0], this.contractService.contractRedeemNFTInstance._address).call();

    const userBalance = parseInt(await this.contractService.contractPartnerInstance.methods.balanceOf(this.accounts[0]).call());
    if(userBalance == 0){
      this.isApproved = true;
    }
  }

  async checkIsApproved2(){
    this.isApproved2 = await this.contractService.contractPartnerInstance2.methods.isApprovedForAll(this.accounts[0], this.contractService.contractRedeemNFTInstance2._address).call();

    const userBalance = parseInt(await this.contractService.contractPartnerInstance2.methods.balanceOf(this.accounts[0]).call());
    if(userBalance == 0){
      this.isApproved2 = true;
    }
  }

  async claimBNFTEgg() {
    
  }
}

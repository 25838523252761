import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { AppComponent } from "../../../app.component";
import { Router } from '@angular/router'
import { SimpleGlobal } from 'ng2-simple-global';
import { HttpClient } from "@angular/common/http";
import { ToastrService } from 'ngx-toastr';
import { BigNumber } from "ethers";

interface Kryptomons {
  type;
  talent;
  typeFormatted;
  id;
  generation;
  speciality;
  super;
  unfreezable;
  image;
  gif;
  iconImage;
  personality;
  timeBornUnix;
  rendered;
  isReadyToHatch;
  hatchingDate;
}

interface Personality {
  Constitution
  Affection
  Crazyness
  Instinct
  Hunger
  Laziness
  Braveness
  Smart
  Ego
  generation
}

interface httpResult {
  status;
  data;
  description;
}

interface MigrateResult {
  tokenId;
  genesArray;
  genes;
  deadline;
  v;
  r;
  s;
}

@Component({
  selector: "app-eggs",
  templateUrl: "./eggs.component.html",
  styleUrls: ["./eggs.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EggsComponent implements OnInit {
  public account;
  public kryptomons;
  public kryptomonHatch;
  public showHatchModal;
  public pitchWhite
  public showKryptomonOff
  public kryptomonName
  public hatchingTime
  public fetchKryptomon
  public hasAllowance
  public loadingAllowance
  public loadingName
  public kmonBalance
  public isLoading
  public typeFormatted = [
    "Fire",
    "Water",
    "Ice",
    "Ground",
    "Air",
    "Electro",
    "Ghost",
    "Grass",
  ];

  text: any = {
    Year: '',
    Month: '',
    Weeks: "",
    Days: "",
    Hours: "",
    Minutes: "",
    Seconds: "",
    MilliSeconds: ""
  };

  divider = ':'

  constructor(public appComponent: AppComponent, public router: Router, public sg: SimpleGlobal, public http: HttpClient, public toastr: ToastrService) {

    this.kryptomons = [];
    this.showHatchModal = false;
    this.pitchWhite = false;
    this.showKryptomonOff = false;
    this.hatchingTime = 0;
    this.fetchKryptomon = false;
    this.hasAllowance = false;
    this.loadingAllowance = false;
    this.loadingName = false;
    this.kmonBalance = 0;

    if (this.appComponent.accounts == undefined) {
      this.appComponent.accountsObservable.subscribe((item) => {
        this.account = item[0];
        if (this.account !== undefined && this.account !== "") {
          this.getKmonBalance();
          this.getHatchingTime();
          this.getKryptomons();
          this.setAllowance();
        }
      });
    } else {
      this.account = this.appComponent.accounts[0];
      this.getKmonBalance();
      this.getHatchingTime();
      this.getKryptomons();
      this.setAllowance();
    }
  }

  ngOnInit(): void { }

  async getHatchingTime() {
    this.hatchingTime = parseInt(await this.appComponent.contractService.contractInstance.methods.timeToHatch().call());
  }

  async getKryptomons() {
    //this.isLoading = true;
    this.appComponent.contractService.contractInstance.methods
      .ownedKryptomons()
      .call({
        from: this.account,
      })
      .then((receipt) => {
        console.log(receipt);
        if (receipt.length > 0) {
          for (let i = 0; i < receipt.length; i += 1) {
            this.getKryptomonDetails(receipt[i]);
          }
        } else {
          //this.checkClaim();
          //this.isLoading = false;
        }
        this.fetchKryptomon = true;
      })
      .catch((err) => {
        ////console.log(err, 'err');
        this.fetchKryptomon = true;
      });

  }

  async getKryptomonDetails(receipt) {
    await this.appComponent.contractService.contractInstance.methods
      .getKryptomonDetails(receipt)
      .call({
        from: this.account,
      })
      .then((kryptomon) => {
        if (kryptomon[7] == 0) {
          this.kryptomons.push({
            id: kryptomon[0],
            genes: kryptomon[1],
            matron: kryptomon[2],
            sire: kryptomon[3],
            timeBorn: kryptomon[4],
            status: kryptomon[7],
            timeHatched: kryptomon[8],
            timeToHatch: kryptomon[9],
            extra_data: kryptomon[6],
          });

          this.parseKryptomonDetails();
        }
      })
      .catch((err) => {
        ////console.log(err, 'err');
      });
  }

  parseKryptomonDetails() {
    for (let i = 0; i < this.kryptomons.length; i++) {
      let genes = this.kryptomons[i].genes;
      let typeDraft = [];
      let type = [];
      let c = 0;
      for (let i = 0; i < 8; i++) {
        let sum = parseInt(genes[c]) * parseInt(genes[c + 1]);
        c = c + 2;
        typeDraft.push(sum);
      }
      let typeSelected = this.indexOfMax(typeDraft);
      let kryptomon = {} as Kryptomons;
      kryptomon.type = typeSelected;
      kryptomon.talent = typeDraft[typeSelected];
      kryptomon.typeFormatted = this.typeFormatted[typeSelected];

      if (genes[19] > genes[20] * 1.1) {
        kryptomon.speciality = "attack";
      } else if (genes[20] > genes[19] * 1.1) {
        kryptomon.speciality = "defense";
      } else {
        kryptomon.speciality = "balance";
      }

      if (genes[22] == 0) {
        kryptomon.super = false;
        var img_selected = kryptomon.speciality;
      } else {
        img_selected = "super";
        kryptomon.super = true;
      }

      kryptomon.unfreezable = this.kryptomons[i].extra_data[0];
      kryptomon.id = this.kryptomons[i].id;
      kryptomon.generation = genes[genes.length - 1];
      kryptomon.timeBornUnix = this.kryptomons[i].timeBorn;

      //console.log(kryptomon.unfreezable)

      if (kryptomon.unfreezable == 1) {
        kryptomon.image =
          "./assets/img/top_special/" +
          kryptomon.typeFormatted +
          "/" +
          img_selected +
          ".png";
      } else {
        kryptomon.image =
          "./assets/img/" +
          kryptomon.typeFormatted +
          "/" +
          img_selected +
          ".png";
      }

      kryptomon.iconImage =
        "./assets/img/icons/" + kryptomon.typeFormatted.toLowerCase() + ".svg";

      let personality = {} as Personality
      personality.Constitution = genes[24];
      personality.Affection = genes[27];
      personality.Crazyness = genes[28];
      personality.Instinct = genes[29];
      personality.Hunger = genes[30];
      personality.Laziness = genes[31];
      personality.Braveness = genes[32];
      personality.Smart = genes[33];
      personality.Ego = genes[35];
      kryptomon.personality = personality;
      kryptomon.generation = genes[genes.length - 1];
      kryptomon.id = this.kryptomons[i].id;

      let postRender = {
        id: kryptomon.id,
        network: this.appComponent.contractService.networkName
      }

      this.http.post("https://api.kryptomon.co/json/kryptomon/checkRender.php", postRender).subscribe((item: httpResult) => {
        kryptomon.rendered = item.status;
      })

      kryptomon.hatchingDate = this.getHatchingDate(kryptomon);
      kryptomon.isReadyToHatch = kryptomon.hatchingDate < new Date();

      this.kryptomons[i].data = kryptomon;

      /*let post = {
        meta: kryptomon,
        dna: genes,
        network: this.appComponent.contractService.networkName
      }
      console.log(kryptomon)
      this.http.post("https://api.kryptomon.co/json/kryptomon/checkMeta.php", post).subscribe((item: httpResult) => {
        console.log(item)
        if (item.status == "empty") {
          this.http.post("https://api.kryptomon.co/json/kryptomon/save_meta.php", post).subscribe((item) => {
            console.log(item)
          }, (error) => {
            //console.log(error)
          })
        }
      }, (error) => {
        //console.log(error)
      })*/
    }
    this.fetchKryptomon = true;
    for (let i = 0; i < this.kryptomons.length; i++) {
      if (this.kryptomons[i].id == 6188) {
        this.http.get("https://api.kryptomon.co/json/kryptomon/meta/customGlitched.php").subscribe((item: any) => {
          this.kryptomons[i].data.typeFormatted = item.element
          this.kryptomons[i].data.iconImage =
            "./assets/img/icons/" + this.kryptomons[i].data.typeFormatted.toLowerCase() + ".svg";
          this.kryptomons[i].data.image = "./assets/img/top_special/custom/" + this.kryptomons[i].id + ".png"
        })
      }
    }
  }

  async setAllowance() {
    let allowance = await this.appComponent.contractService.contractTokenInstance.methods.allowance(this.account, this.appComponent.contractService.contractInstance._address).call();
    this.hasAllowance = BigNumber.from(allowance).gt(0);
  }

  grantAllowance() {
    this.loadingAllowance = true;
    this.appComponent.contractService.contractTokenInstance.methods.approve(this.appComponent.contractService.contractInstance._address, this.appComponent.contractService.web3.utils.toWei('999999999999')).send({
      from: this.account
    }).then((result) => {
      if (result.events.Approval !== undefined) {
        this.hasAllowance = true;
        this.loadingAllowance = false;
      }
    }).catch((err) => {
      this.hasAllowance = false;
      this.loadingAllowance = false;
      this.toastr.error('Try again, or contact our team for support', 'Ops! Something went wrong', { timeOut: 5000 });
    });;
  }

  isReadyToHatch(kryptomon) {
    return kryptomon.hatchingDate < new Date();
  }

  getHatchingDate(kryptomon) {
    let hatchingDateInUnix = parseInt(kryptomon.timeBornUnix) + this.hatchingTime;
    return new Date((hatchingDateInUnix + 20) * 1000);
  }

  async startHatching(kryptomon) {
    this.kryptomonHatch = kryptomon;

    this.showHatchModal = true;
    //waiting for the transaction to be successfull
    await this.appComponent.contractService.contractInstance.methods.hatch(this.kryptomonHatch.id).send({
      from: this.account
    }).then((response) => {
      console.log(response)
      if (response.events.EggHatched) {
        setTimeout(() => {
          this.kryptomonHatch.data.gif =
            "https://kryptomon-images.ams3.digitaloceanspaces.com/images/kryptomons/gif/kmon_" + this.kryptomonHatch.id + "_gif.gif";
          this.showHatchModal = false;
          this.showKryptomonOff = true;
        }, 3000);
      } else {
        this.showHatchModal = true;
        this.toastr.error('Try again, or contact our team for support', 'Ops! Something went wrong', { timeOut: 5000 });
      }
    }).catch((err) => {
      this.showHatchModal = true;
      this.toastr.error('Try again, or contact our team for support', 'Ops! Something went wrong', { timeOut: 5000 });
    });
  }

  async saveNameNext(kryptomon) {

    if (this.kryptomonName !== "" && this.kryptomonName !== undefined) {
      this.loadingName = true;
      var text = "Name my Kryptomon(" + kryptomon.id + ") " + this.kryptomonName;

      var msg = await this.appComponent.contractService.web3.utils.utf8ToHex(text)
      var from = this.account;
      var params = [msg, from]
      var method = 'personal_sign'

      var parent = this;
      var name = this.kryptomonName;
      var network = this.appComponent.contractService.networkName;

      this.appComponent.contractService.web3.currentProvider.sendAsync({
        method,
        params,
        from,
      }, function (err, result) {
        if (err) { parent.loadingName = false; return console.error(err) }
        if (result.error) { parent.loadingName = false; return console.error(result.error) }
        console.log('PERSONAL SIGNED:' + JSON.stringify(result.result))

        let post = {
          id: kryptomon.id,
          name: name,
          network: network,
          signature: result.result,
          enable: true
        }
        parent.http.post(this.appComponent.contractService.apiEndPoint + "/kryptomons-meta/" + post.id + "/name", post).subscribe((response: httpResult) => {
          parent.router.navigate(['/kryptomons'])
        })
      });



    } else {
      this.toastr.warning("You need to give a name to your Kryptomon!", "Hey, don't you forget anything?", { timeOut: 500000 });
    }

  }

  skipNaming() {
    this.router.navigate(['/kryptomons'])
  }

  openDetails(what, id, eggSelected) {
    //this.sg['kryptomonSelected'] = eggSelected
    //this.router.navigate(['/details', what])
  }

  indexOfMax(arr) {
    if (arr.length === 0) {
      return -1;
    }

    var max = arr[0];
    var maxIndex = 0;

    for (var i = 1; i < arr.length; i++) {
      if (arr[i] > max) {
        maxIndex = i;
        max = arr[i];
      }
    }

    return maxIndex;
  }

  buyKmon() {
    window.location.href = 'https://pancakeswap.finance/swap?outputCurrency=0xc732b6586a93b6b7cf5fed3470808bc74998224d';
  }

  async getKmonBalance() {
    const balance = await this.appComponent.contractService.contractTokenInstance.methods.balanceOf(this.account).call();
    this.kmonBalance = BigNumber.from(parseInt(await this.appComponent.contractService.web3.utils.fromWei(balance)))
  }

  hasKmonToHatch() {
    return this.kmonBalance.gte(100);
  }

  goMarketplace() {
    window.open("https://market.kryptomon.co");
  }

  async migrateKryptomon(_tokenId) {
    const ids = [];
    const genes = [];
    const deadlines = [];
    const vs = [];
    const rs = [];
    const ss = [];
    this.isLoading = true;

    const isApproved = await this.appComponent.contractService.contractInstance.methods.isApprovedForAll(this.account, this.appComponent.contractService.contractInstanceV2._address).call();

    if (!isApproved) {
      await this.appComponent.contractService.contractInstance.methods.setApprovalForAll(this.appComponent.contractService.contractInstanceV2._address, true).send({ from: this.account });
    }

    const migrationData: MigrateResult = await this.http.get(this.appComponent.contractService.apiEndPoint + "/kryptomon/migrate/" + _tokenId).toPromise() as MigrateResult;
    ids.push(_tokenId);
    genes.push(migrationData.genesArray);
    deadlines.push(migrationData.deadline);
    vs.push(migrationData.v);
    rs.push(migrationData.r);
    ss.push(migrationData.s);

    this.appComponent.contractService.contractInstanceV2.methods
      .migrateBatch(
        ids,
        genes,
        deadlines,
        vs,
        rs,
        ss
      )
      .send({ from: this.account })
      .then(() => {
        this.isLoading = false;
        this.router.navigate(["/eggsV2"]);
      })
  }

  async migrateKryptomons() {
    const ids = [];
    const genes = [];
    const deadlines = [];
    const vs = [];
    const rs = [];
    const ss = [];
    this.isLoading = true;

    const isApproved = await this.appComponent.contractService.contractInstance.methods.isApprovedForAll(this.account, this.appComponent.contractService.contractInstanceV2._address).call();

    if (!isApproved) {
      await this.appComponent.contractService.contractInstance.methods.setApprovalForAll(this.appComponent.contractService.contractInstanceV2._address, true).send({ from: this.account });
    }

    for (let i = 0; i < this.kryptomons.length; i++) {
      if(i > 9)
        break;
      const migrationData: MigrateResult = await this.http.get(this.appComponent.contractService.apiEndPoint + "/kryptomon/migrate/" + this.kryptomons[i].id).toPromise() as MigrateResult;
      ids.push(this.kryptomons[i].id);
      genes.push(migrationData.genesArray);
      deadlines.push(migrationData.deadline);
      vs.push(migrationData.v);
      rs.push(migrationData.r);
      ss.push(migrationData.s);
    }
    this.appComponent.contractService.contractInstanceV2.methods
      .migrateBatch(
        ids,
        genes,
        deadlines,
        vs,
        rs,
        ss
      )
      .send({ from: this.account })
      .then(() => {
        this.isLoading = false;
        this.router.navigate(["/eggsV2"]);
      })
  }
}
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { AppComponent } from "../../../app.component";
import { Router } from '@angular/router'
import { SimpleGlobal } from 'ng2-simple-global';
import { HttpClient } from "@angular/common/http";

interface Kryptomons {
  type;
  talent;
  typeFormatted;
  id;
  generation;
  speciality;
  super;
  unfreezable;
  image;
  iconImage;
  gif;
  name;
}

interface httpResult {
  status;
  data;
  description;
  name;
}

interface MigrateResult {
  tokenId;
  genesArray;
  genes;
  deadline;
  v;
  r;
  s;
}

@Component({
  selector: 'app-kryptomons',
  templateUrl: './kryptomons.component.html',
  styleUrls: ['./kryptomons.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class KryptomonsComponent implements OnInit {
  public account;
  public kryptomons;
  public fetchKryptomon;
  public isLoading
  public typeFormatted = [
    "Fire",
    "Water",
    "Ice",
    "Ground",
    "Air",
    "Electro",
    "Ghost",
    "Grass",
  ];

  constructor(public appComponent: AppComponent, public router: Router, public sg: SimpleGlobal, public http: HttpClient) {
    this.kryptomons = [];
    this.fetchKryptomon = false;
    this.isLoading = false;
    if (this.appComponent.accounts == undefined) {
      this.appComponent.accountsObservable.subscribe((item) => {
        this.account = item[0];
        if (this.account !== undefined && this.account !== "") {
          this.getKryptomons();
        }
      });
    } else {
      this.account = this.appComponent.accounts[0];
      this.getKryptomons();
    }
  }

  ngOnInit(): void { }

  async getKryptomons() {
    //this.isLoading = true;
    this.appComponent.contractService.contractInstance.methods
      .ownedKryptomons()
      .call({
        from: this.account,
      })
      .then(async (receipt) => {
        console.log(receipt);
        if (receipt.length > 0) {
          for (let i = 0; i < receipt.length; i += 1) {
            await this.getKryptomonDetails(receipt[i]);
          }
        }
        this.fetchKryptomon = true;
      })
      .catch((err) => {
        ////console.log(err, 'err');
        //this.isLoading = false;
        this.fetchKryptomon = true;
      });
  }

  async getKryptomonDetails(receipt) {
    await this.appComponent.contractService.contractInstance.methods
      .getKryptomonDetails(receipt)
      .call({
        from: this.account,
      })
      .then((kryptomon) => {
        if (kryptomon[7] > 0) {
          this.kryptomons.push({
            id: kryptomon[0],
            genes: kryptomon[1],
            matron: kryptomon[2],
            sire: kryptomon[3],
            timeBorn: kryptomon[4],
            status: kryptomon[7],
            timeHatched: kryptomon[8],
            timeToHatch: kryptomon[9],
            extra_data: kryptomon[6],
          });

          this.parseKryptomonDetails();
        }
      })
      .catch((err) => {
        ////console.log(err, 'err');
      });
  }

  parseKryptomonDetails() {
    for (let i = 0; i < this.kryptomons.length; i++) {
      let genes = this.kryptomons[i].genes;
      let typeDraft = [];
      let type = [];
      let c = 0;
      for (let i = 0; i < 8; i++) {
        let sum = parseInt(genes[c]) * parseInt(genes[c + 1]);
        c = c + 2;
        typeDraft.push(sum);
      }
      let typeSelected = this.indexOfMax(typeDraft);
      let kryptomon = {} as Kryptomons;
      kryptomon.type = typeSelected;
      kryptomon.talent = typeDraft[typeSelected];
      kryptomon.typeFormatted = this.typeFormatted[typeSelected];

      if (genes[19] > genes[20] * 1.1) {
        kryptomon.speciality = "attack";
      } else if (genes[20] > genes[19] * 1.1) {
        kryptomon.speciality = "defense";
      } else {
        kryptomon.speciality = "balance";
      }

      if (genes[22] == 0) {
        kryptomon.super = false;
        var img_selected = kryptomon.speciality;
      } else {
        img_selected = "super";
        kryptomon.super = true;
      }

      kryptomon.unfreezable = this.kryptomons[i].extra_data[0];
      kryptomon.id = this.kryptomons[i].id;
      kryptomon.generation = genes[genes.length - 1];

      //console.log(kryptomon.unfreezable)

      kryptomon.image =
        "https://kryptomon-images.ams3.digitaloceanspaces.com/images/kryptomons/png/kmon_" + kryptomon.id + "_png.png";
      kryptomon.gif =
        "https://kryptomon-images.ams3.digitaloceanspaces.com/images/kryptomons/gif/kmon_" + kryptomon.id + "_gif.gif";

      /*if (kryptomon.unfreezable == 1) {
        kryptomon.image =
          "./assets/img/top_special/" + kryptomon.typeFormatted + "/" + img_selected + ".png";
      } else {
        kryptomon.image =
          "./assets/img/" + kryptomon.typeFormatted + "/" + img_selected + ".png";
      }*/

      kryptomon.iconImage =
        "./assets/img/icons/" + kryptomon.typeFormatted.toLowerCase() + ".svg";

      this.http
        .get("https://api.kryptomon.co/json/kryptomon/meta/" + kryptomon.id)
        .subscribe((item: httpResult) => {
          kryptomon.name = item.name;
        });

      this.kryptomons[i].data = kryptomon;
    }
  }

  openDetails(what, id, kryptomonSelected) {
    this.sg['kryptomonSelected'] = kryptomonSelected
    console.log(this.sg['kryptomonSelected'])
    this.router.navigate(['/details', what])
  }

  indexOfMax(arr) {
    if (arr.length === 0) {
      return -1;
    }

    var max = arr[0];
    var maxIndex = 0;

    for (var i = 1; i < arr.length; i++) {
      if (arr[i] > max) {
        maxIndex = i;
        max = arr[i];
      }
    }

    return maxIndex;
  }

  goMarketplace() {
    window.open("https://market.kryptomon.co");
  }

  async migrateKryptomon(_tokenId) {
    this.isLoading = true;

    const ids = [];
    const genes = [];
    const deadlines = [];
    const vs = [];
    const rs = [];
    const ss = [];
    this.isLoading = true;

    const isApproved = await this.appComponent.contractService.contractInstance.methods.isApprovedForAll(this.account, this.appComponent.contractService.contractInstanceV2._address).call();

    if (!isApproved) {
      await this.appComponent.contractService.contractInstance.methods.setApprovalForAll(this.appComponent.contractService.contractInstanceV2._address, true).send({ from: this.account });
    }

    const migrationData: MigrateResult = await this.http.get(this.appComponent.contractService.apiEndPoint + "/kryptomon/migrate/" + _tokenId).toPromise() as MigrateResult;
    ids.push(_tokenId);
    genes.push(migrationData.genesArray);
    deadlines.push(migrationData.deadline);
    vs.push(migrationData.v);
    rs.push(migrationData.r);
    ss.push(migrationData.s);

    this.appComponent.contractService.contractInstanceV2.methods
      .migrateBatch(
        ids,
        genes,
        deadlines,
        vs,
        rs,
        ss
      )
      .send({ from: this.account })
      .then(() => {
        this.isLoading = false;
        this.router.navigate(["/kryptomonsV2"]);
      })
  }

  async migrateKryptomons() {
    const ids = [];
    const genes = [];
    const deadlines = [];
    const vs = [];
    const rs = [];
    const ss = [];
    this.isLoading = true;
    const isApproved = await this.appComponent.contractService.contractInstance.methods.isApprovedForAll(this.account, this.appComponent.contractService.contractInstanceV2._address).call();

    if(!isApproved){
      await this.appComponent.contractService.contractInstance.methods.setApprovalForAll(this.appComponent.contractService.contractInstanceV2._address, true).send({ from: this.account});
    }

    for (let i = 0; i < this.kryptomons.length; i++) {
      if(i > 9)
        break;
      const migrationData: MigrateResult = await this.http.get(this.appComponent.contractService.apiEndPoint + "/kryptomon/migrate/" + this.kryptomons[i].id).toPromise() as MigrateResult;
      ids.push(this.kryptomons[i].id);
      genes.push(migrationData.genesArray);
      deadlines.push(migrationData.deadline);
      vs.push(migrationData.v);
      rs.push(migrationData.r);
      ss.push(migrationData.s);
    }
    this.appComponent.contractService.contractInstanceV2.methods
      .migrateBatch(
        ids,
        genes,
        deadlines,
        vs,
        rs,
        ss
      )
      .send({ from: this.account })
      .then(() => {
        this.isLoading = false;
        this.router.navigate(["/kryptomonsV2"]);
      })
  }
}
<div class="section">
  <div class="row">
    <div class="space-bg">
      <div class="poweredby">
        Powered by<img style="height: 25px; margin-left: 5px; margin-right: 15px;" src="./assets/img/Home/Chainlink Logo White.png" />
      </div>
      <div class="card-custom card lottery-card">
        <div class="black-boxes">
          <div class="box-1"></div>
          <div class="box-2"></div>
        </div>
        <div class="give-approval-overlay" *ngIf="lotteryStatus==1">
          <h3>Lottery is currently closed</h3>
          <p class="nomargin">Bear with us while we are preparing the new lottery! We are currently upgrading the
            system.</p>
        </div>
        <div class="give-approval-overlay" *ngIf="lotteryStatus==3 || lotteryStatus == 2">
          <img src="./assets/img/Home/lottery.png" />
          <h3>Drum roll...</h3>
          <p class="nomargin">System is picking up the winners, come back soon!</p>
        </div>
        <div class="lottery-heading" style="text-align: left; margin-top: 50px;" *ngIf="!accumulationStarted">
          <h2>V4 rewards start:</h2>
          <countdown units="Days | Hours | Minutes | Seconds" end="Feb 07, 2022 09:00:00 UTC"></countdown>
        </div>
        <div class="lottery-heading" style="text-align: left; margin-top: 50px;" *ngIf="accumulationStarted">
          <img src="../../../assets/img/Home/tickets (1).png" />
          <h2>Next Lottery:</h2>
          <countdown units="Days | Hours | Minutes | Seconds" end="Dec 27, 2022 9:00:00 UTC"></countdown>
        </div>
        <div class="ticket-played" *ngIf="lotteryStatus==0">
          <div class="play-card-content">
            <div class="tickets-bet">
              <h2> 🍬 {{ticketsPlayedUser.toLocaleString()}} / {{ticketsPlayed.toLocaleString()}}</h2>
              <p class="entries smaller">Winning probability {{ getWinningProbability(ticketsPlayedUser,
                ticketsPlayed, playersInLottery) }}%</p>
            </div>
          </div>
        </div>
        <div class="bottom-lottery-card">
          <p class="entries smaller">Players in the lottery: {{playersInLottery.toLocaleString()}}</p>
          <p class="entries smaller" *ngIf="kmonStakedOld>0">Your KMON Staked V3:
            {{formatNumber(kmonStakedOld).toLocaleString()}}
            - 0 candies/week</p>
          <!-- <p class="entries smaller">BUSD/KMON LP available: {{formatNumber(lpBalance).toLocaleString()}}</p> -->
          <p class="entries smaller">Total KMON Staked: {{formatNumber(totalKMONStaked).toLocaleString()}} </p>
          <p class="entries smaller">Generated: {{
            getV4WeeklyRewardRate() }} candies/week </p>
          <!-- <p class="entries smaller">Total BUSD/KMON LP Staked: {{formatNumber(totalLPStaked).toLocaleString()}} - {{
          getV4WeeklyRewardRateLP() }} candies/week </p> -->
          <!-- <p class="entries smaller">Total BUSD/KMON LP Staked: {{formatNumber(totalLPStaked).toLocaleString()}} - to be  37,332 candies/week </p> -->
          <!-- <p class='muchsmaller'>Total KMON Staked V3: {{formatNumber(totalKMONStakedV1).toLocaleString()}} - 0
          candies/per week</p> -->
        </div>
      </div>
      <div class="card-custom card lottery-result" *ngIf="haveParticipated && noWin && !submittingWinners">
        <div class="no-winning">
          <!-- <img src="./assets/img/Home/sorry.png"> -->
          <p>Sorry, nothing won this time. Try again next lottery!</p>
        </div>
      </div>
      <div class="card-custom card lottery-result" *ngIf="submittingWinners">
        <div class="submitting-winners">
          <h3>Submitting winners...</h3>
          <p>Refresh the page in a few minutes</p>
        </div>
      </div>
      <div class="card-custom card lottery-result" *ngIf="haveWon && !submittingWinners"
        [ngClass]="{'pink-border': true}">
        <div class="claim-winning">
          <h3>Congratulations! 🎉</h3>
          <p>You have won {{ winningClaims.length }} Kryptomon Egg<span *ngIf="winningClaims.length > 1">s</span>!</p>
          <div class="signup-btn" (click)="collectEgg()" *ngIf="!isLoadingCollect">
            Collect
          </div>
          <div class="lds-ripple" *ngIf="isLoadingCollect">
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
      <div class="card-custom card lottery-result" *ngIf="lootboxesObject[this.accounts[0]]">
        <div class="claim-winning">
          <h3>Last lottery lootboxes 🎉</h3>
          <p *ngIf="lootboxesObject[this.accounts[0]].basic">Basic: {{ lootboxesObject[this.accounts[0]].basic }}</p>
          <p *ngIf="lootboxesObject[this.accounts[0]].medium">Medium: {{ lootboxesObject[this.accounts[0]].medium }}</p>
          <p *ngIf="lootboxesObject[this.accounts[0]].premium">Premium: {{ lootboxesObject[this.accounts[0]].premium }}
          </p>
        </div>
      </div>
      <div class="newegg-overlay" *ngIf="showShare">
        <div id="tsparticles"></div>
        <div class="card">
          <div class="close">
            <fa-icon [icon]="['fas', 'times-circle']" (click)="closeShare()"></fa-icon>
          </div>
          <div class="card-content" *ngIf="!collectedKryptomon">
            <img src="./assets/img/Home/{{modalImage}}" class="egg-pic" />
            <div class="modal-text">
              <p>{{modalTitle}}</p>
              <p>{{modalContent}}</p>
              <div (click)="doAction()" *ngIf="!isLoadingBuy" class="signup-btn">{{modalCta}}</div>
              <div class="lds-ripple" *ngIf="isLoadingBuy">
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
          <div class="card-content" *ngIf="collectedKryptomon">
            <img src="{{kryptomons[0].data.image}}" class="egg-pic" />
            <p>Congratulation Trainer!</p>
            <p>You've collected your Lottery Kryptomon Egg 🎉</p>
            <p>Share your Kryptomon egg with the world</p>
            <div class="share-buttons">
              <share-button button="twitter" url="https://kryptomon.co"
                description="I won a Kryptomon Egg in the KMON Staking Lottery! 😍 A new adventure is about to begin and you? Do you want to be a part of it? Join the telegram group to find out how 🎉 https://t.me/kryptomonofficial #Kryptomon">
              </share-button>
              <share-button button="reddit" url="https://kryptomon.co"
                title="I won a Kryptomon Egg in the KMON Staking Lottery!"
                description="I won a Kryptomon Egg in the KMON Staking Lottery! 😍 A new adventure is about to begin and you? Do you want to be a part of it? Join the telegram group to find out how 🎉 https://t.me/kryptomonofficial #Kryptomon">
              </share-button>
              <share-button button="facebook" url="https://kryptomon.co"
                title="I won a Kryptomon Egg in the KMON Staking Lottery!"
                description="I won a Kryptomon Egg in the KMON Staking Lottery! 😍 A new adventure is about to begin and you? Do you want to be a part of it? Join the telegram group to find out how 🎉 https://t.me/kryptomonofficial #Kryptomon">
              </share-button>
            </div>
            <p class="marginTop25">And now?</p>
            <div (click)="gotoDashboard()" class="signup-btn">Go to Dashboard</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="row">
    <div class="card-custom card">
      <div class="give-approval-overlay" *ngIf="lotteryStatus==1">
        <h3>Lottery is currently closed</h3>
        <p class="nomargin">Bear with us while we are preparing the new lottery! We are currently upgrading the
          system.</p>
      </div>
      <div class="give-approval-overlay" *ngIf="lotteryStatus==3 || lotteryStatus == 2">
        <img src="./assets/img/Home/lottery.png" />
        <h3>Drum roll...</h3>
        <p class="nomargin">System is picking up the winners, come back soon!</p>
      </div>
      <div class="ticket-earned" *ngIf="lotteryStatus==0">
        <div class="play-card-content">
          <h3>Tickets Available:</h3>
          <p class="entries">{{ticketsAvailable.toLocaleString()}}</p>
          <p class="nomargin top25">How many tickets you want to play?</p>
          <div>
            <span id="rangeValue">{{selectedTicketPlayed}}</span>
            <input class="range" type="range" [(ngModel)]="rangeValue" (ngModelChange)="checkPlay()" min="0" max="100">
          </div>
          <div class="signup-btn" (click)="playTicket()" [ngClass]="{'disabled': playDisabled}" *ngIf="!isLoading">
            Play
          </div>
          <div class="lds-ripple" *ngIf="isLoading">
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
      <div class="ticket-played" *ngIf="lotteryStatus==0">
        <div class="play-card-content">
          <div class="tickets-bet">
            <h3>Tickets Bet:</h3>
            <p class="entries">{{ticketsPlayedUser.toLocaleString()}} / {{ticketsPlayed.toLocaleString()}}</p>
            <p class="entries smaller">Your winning probability is {{ getWinningProbability(ticketsPlayedUser,
              ticketsPlayed, playersInLottery) }}%</p>
          </div>
          <div class="players">
            <h3>Players in the lottery:</h3>
            <p class="entries">{{playersInLottery.toLocaleString()}}</p>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="row">
    <div class="col second-line">
      <div class="card-custom card play-card approve-card">
        <div class="give-approval-overlay" *ngIf="!isApproved">
          <h3>Before proceeding</h3>
          <p class="nomargin">you need to Approve the KMON Coin to be staked in the V4 lottery.</p>
          <div class="signup-btn" (click)="giveAllowance()" *ngIf="!isLoading">
            Confirm
          </div>
          <div class="lds-ripple" *ngIf="isLoading">
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="migrating-overlay" *ngIf="(!haveMigrated && isApproved) || migrationRunning">
          <h3>Before proceeding</h3>
          <p class="nomargin">you need to migrate your KMON to the new staking contract or withdraw them.</p>
          <div class="button-migrate">
            <div class="signup-btn" (click)="migrateKMON()" *ngIf="!isLoading">
              Migrate
            </div>
            <div class="signup-btn withdraw-btn" (click)="withdrawKMONFromOld()" *ngIf="!isLoading">
              Withdraw
            </div>
          </div>
          <div class="lds-ripple" *ngIf="isLoading">
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="actions_container">
          <div class="select_action" [ngClass]="{'selected': showContent[2]}" (click)="selectContent(2)">
            Play
          </div>
          <div class="select_action" [ngClass]="{'selected': showContent[0]}" (click)="selectContent(0)">
            Stake
          </div>
          <!-- <div class="select_action" [ngClass]="{'selected': showContent[1]}" (click)="selectContent(1)">
            Withdraw
          </div> -->
        </div>
        <div class="play_content" *ngIf="showContent[2]">
          <h2>🍬 Candies <br> Available:</h2>
          <p class="entries">{{ticketsAvailable.toLocaleString()}}</p>
          <p class="nomargin top25">How many candies do you want to play for?</p>
          <div>
            <span id="rangeValue">{{selectedTicketPlayed}}</span>
            <input class="range" type="range" [(ngModel)]="rangeValue" (ngModelChange)="checkPlay()" min="0" max="100">
          </div>
          <div class="signup-btn" (click)="playTicket()" [ngClass]="{'disabled': playDisabled}" *ngIf="!isLoading">
            Play
          </div>
          <div class="lds-ripple" *ngIf="isLoading">
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="stake_content" *ngIf="showContent[0]">
          <h2>STAKing pool <br> KMON <br> <a href="https://bscscan.com/address/{{contractService.newRewardsAddress}}"
              class="rewardlink">
              <!-- <fa-icon [icon]="['fas', 'link']"></fa-icon> -->
              <img style="width: 30px;" src="../../../assets/img/Home/K-coin.png" alt = "KMON" />
            </a></h2>
          <p class="entries">Your KMON Staked: {{formatNumber(kmonStaked).toLocaleString()}} <br> <span
              class="entries smaller">Generates: {{ formatNumber(getMyRewardRateFormatted(60 * 60 * 24 * 7, 1)) }}
              candies/week</span>
          </p>
          <div class="signup-btn stakeBtn" (click)="openStake()">Stake
          </div>
          <div class="signup-btn unstakeBtn" (click)="openUnstake()">Unstake
          </div>
          <div id="popupOverlay"></div>
          <div class="popuptext" id="stakePopup">
            <img class="popup-close-btn" src="../../../assets/img/Home/X-close-btn.png" (click)="closeStake()" />
            <h3>STAKE KMON</h3>
            <p class="nomargin">Select an amount of KMON you want to stake</p>
            <div class="amount_click">
              <div class="select_amount" (click)="selectAmountStake(0,25)"
                [ngClass]="{'selected': selectedAmountUI[0]}">
                25%
              </div>
              <div class="select_amount" (click)="selectAmountStake(1,50)"
                [ngClass]="{'selected': selectedAmountUI[1]}">
                50%
              </div>
              <div class="select_amount" (click)="selectAmountStake(2,75)"
                [ngClass]="{'selected': selectedAmountUI[2]}">
                75%
              </div>
              <div class="select_amount" (click)="selectAmountStake(3,100)"
                [ngClass]="{'selected': selectedAmountUI[3]}">
                100%
              </div>
            </div>
            <input type="number" placeholder="Or insert the exact amount here" [(ngModel)]="kmonToBeStaked">
            <div class="signup-btn" (click)="stakeKMON()"
              [ngClass]="{'disabled': kmonToBeStaked>kmonBalance || kmonToBeStaked<=0}" *ngIf="!isLoading">
              Confirm
            </div>
            <div class="lds-ripple" *ngIf="isLoading">
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div id="unstakePopup" class="withdraw_content">
          <img class="popup-close-btn" src="../../../assets/img/Home/X-close-btn.png" (click)="closeUnstake()" />
          <h3>UNSTAKE KMON <a href="https://bscscan.com/address/{{contractService.rewardsAddress}}" class="rewardlink">
              <!-- <fa-icon [icon]="['fas', 'link']"></fa-icon> -->
            </a></h3>
          <p class="nomargin">Select an amount of KMON you want to withdraw</p>
          <div class="amount_click">
            <div class="select_amount" (click)="selectAmountWithdraw(0,25)"
              [ngClass]="{'selected': selectedAmountUI[0]}">
              25%
            </div>
            <div class="select_amount" (click)="selectAmountWithdraw(1,50)"
              [ngClass]="{'selected': selectedAmountUI[1]}">
              50%
            </div>
            <div class="select_amount" (click)="selectAmountWithdraw(2,75)"
              [ngClass]="{'selected': selectedAmountUI[2]}">
              75%
            </div>
            <div class="select_amount" (click)="selectAmountWithdraw(3,100)"
              [ngClass]="{'selected': selectedAmountUI[3]}">
              100%
            </div>
          </div>
          <input type="number" placeholder="Or insert the exact amount here" [(ngModel)]="kmonToBeWithdraw">
          <div class="signup-btn" (click)="withdrawKMON()"
            [ngClass]="{'disabled': kmonToBeWithdraw>kmonStaked || kmonToBeWithdraw<=0}" *ngIf="!isLoading">
            Confirm
          </div>
          <div class="lds-ripple" *ngIf="isLoading">
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
      <div class="card-custom card staking-card staking-lp">
        <div class="give-approval-overlay" *ngIf="!isApprovedLP">
          <h3>Before proceeding</h3>
          <p class="nomargin">you need to approve the PancakeSwap BUSD/KMON LP token to be staked in the lottery.</p>
          <a href="https://pancakeswap.finance/add/BUSD/{{contractService.contractTokenInstance._address}}"
            target="_blank" class="rewardlink">
            <fa-icon [icon]="['fas', 'link']"></fa-icon>
          </a>
          <div class="signup-btn" (click)="giveAllowanceLP()" *ngIf="!isLoadingLP">
            Confirm
          </div>
          <div class="lds-ripple" *ngIf="isLoadingLP">
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="actions_container">
          <div class="select_action" [ngClass]="{'selected': showContentLP[2]}" (click)="selectContentLP(2)">
            Play
          </div>
          <div class="select_action" [ngClass]="{'selected': showContentLP[0]}" (click)="selectContentLP(0)">
            Stake
          </div>
          <!-- <div class="select_action" [ngClass]="{'selected': showContentLP[1]}" (click)="selectContentLP(1)">
            Withdraw
          </div> -->
        </div>
        <div class="play_content" *ngIf="showContentLP[2]">
          <h2>Lottery 🍬 Candies Available:</h2>
          <p class="entries">{{ticketsAvailableLP.toLocaleString()}}</p>
          <p class="nomargin top25">How many candies do you want to play for?</p>
          <div>
            <span id="rangeValue">{{selectedTicketPlayed}}</span>
            <input class="range" type="range" [(ngModel)]="rangeValueLP" (ngModelChange)="checkPlayLP()" min="0"
              max="100">
          </div>
          <div class="signup-btn" (click)="playTicketLP()" [ngClass]="{'disabled': playDisabledLP}"
            *ngIf="!isLoadingLP">
            Play
          </div>
          <div class="lds-ripple" *ngIf="isLoadingLP">
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="stake_content" *ngIf="showContentLP[0]">
          <h2>STAKing pool <br> BUSD/KMON LP <br> <a
              href="https://bscscan.com/address/{{contractService.newRewardsAddressLP}}" target="_blank"
              class="rewardlink">
              <!-- <fa-icon [icon]="['fas', 'link']"></fa-icon> -->
              <img style="width: 150px;" src="../../../assets/img/Home/Group10.png" alt="BUSD/KMON LP" />
            </a><a
              href="https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/{{contractService.contractTokenInstance._address}}"
              target="_blank" class="lplink">
              <!-- <fa-icon [icon]="['fas', 'plus']"></fa-icon> -->
            </a></h2>
          <p class="entries"> Your BUSD/KMON Staked: {{formatNumber(lpStaked).toLocaleString()}} <br> <span
              class="entries smaller">Generates: {{ formatNumber(getMyRewardRateFormattedLP(60 * 60 * 24 * 7, 1)) }}
              candies/week</span></p>
          <div class="signup-btn stakeBtn" (click)="openStakeLP()">Stake
          </div>
          <div class="signup-btn unstakeBtn" (click)="openUnstakeLP()">Unstake
          </div>
          <div id="popupOverlayLP"></div>
          <div class="popuptext" id="stakeLPPopup">
            <img class="popup-close-btn" src="../../../assets/img/Home/X-close-btn.png" (click)="closeStakeLP()" />
            <h3>STAKE BUSD/KMON LP</h3>
            <p class="nomargin">Select an amount of BUSD/KMON LP you want to stake</p>
            <div class="amount_click">
              <div class="select_amount" (click)="selectAmountStakeLP(0,25)"
                [ngClass]="{'selected': selectedAmountUILP[0]}">
                25%
              </div>
              <div class="select_amount" (click)="selectAmountStakeLP(1,50)"
                [ngClass]="{'selected': selectedAmountUILP[1]}">
                50%
              </div>
              <div class="select_amount" (click)="selectAmountStakeLP(2,75)"
                [ngClass]="{'selected': selectedAmountUILP[2]}">
                75%
              </div>
              <div class="select_amount" (click)="selectAmountStakeLP(3,100)"
                [ngClass]="{'selected': selectedAmountUILP[3]}">
                100%
              </div>
            </div>
            <input type="number" placeholder="Or insert the exact amount here" [(ngModel)]="lpToBeStaked">
            <div class="signup-btn" (click)="stakeLP()"
              [ngClass]="{'disabled': lpToBeStaked>lpBalance || lpToBeStaked<=0}" *ngIf="!isLoadingLP">
              Confirm
            </div>
            <div class="lds-ripple" *ngIf="isLoadingLP">
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div id="unstakeLPPopup" class="withdraw_content">
          <img class="popup-close-btn" src="../../../assets/img/Home/X-close-btn.png" (click)="closeUnstakeLP()" />
          <h3>UNSTAKE BUSD/KMON LP <a href="https://bscscan.com/address/{{contractService.newRewardsAddressLP}}"
              class="rewardlink">
              <!-- <fa-icon [icon]="['fas', 'link']"></fa-icon> -->
            </a></h3>
          <p class="nomargin">Select an amount of BUSD/KMON LP you want to withdraw</p>
          <div class="amount_click">
            <div class="select_amount" (click)="selectAmountWithdrawLP(0,25)"
              [ngClass]="{'selected': selectedAmountUILP[0]}">
              25%
            </div>
            <div class="select_amount" (click)="selectAmountWithdrawLP(1,50)"
              [ngClass]="{'selected': selectedAmountUILP[1]}">
              50%
            </div>
            <div class="select_amount" (click)="selectAmountWithdrawLP(2,75)"
              [ngClass]="{'selected': selectedAmountUILP[2]}">
              75%
            </div>
            <div class="select_amount" (click)="selectAmountWithdrawLP(3,100)"
              [ngClass]="{'selected': selectedAmountUILP[3]}">
              100%
            </div>
          </div>
          <input type="number" placeholder="Or insert the exact amount here" [(ngModel)]="lpToBeWithdraw">
          <div class="signup-btn" (click)="withdrawLP()"
            [ngClass]="{'disabled': lpToBeWithdraw>lpStaked || lpToBeWithdraw<=0}" *ngIf="!isLoading">
            Confirm
          </div>
          <div class="lds-ripple" *ngIf="isLoadingLP">
            <div></div>
            <div></div>
          </div>
        </div>
      </div>

      <!-- <div class="card-custom card play-card buy-card">
        <div class="give-approval-overlay" *ngIf="lotteryStatus==1">
          <h3>Lottery is currently closed</h3>
          <p class="nomargin">Bear with us while we are preparing the new lottery! We are currently upgrading the
            system.</p>
        </div>
        <div class="give-approval-overlay" *ngIf="lotteryStatus==3 || lotteryStatus == 2">
          <img src="./assets/img/Home/lottery.png" />
          <h3>Drum roll...</h3>
          <p class="nomargin">System is picking up the winners, come back soon!</p>
        </div>
        <div class="ticket-earned buy-card-content" *ngIf="lotteryStatus==0">
          <div class="">
            <h3>🍬 Candies Available:</h3>
            <p class="entries">{{ticketsAvailable.toLocaleString()}}</p>
            <p class="nomargin top25">You can now buy items with candies</p>
            <div class="signup-btn" (click)="gotoItems()" [ngClass]="{'disabled': playDisabled}" *ngIf="!isLoading">
              Buy Items
            </div>
            <div class="lds-ripple" *ngIf="isLoading">
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div> -->

    </div>
  </div>
</div>
<!--check-->

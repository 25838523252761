<div class="mobile-menu" [ngClass]="{'show': showMenu}">
  <div class="close-button" (click)="toggleMenu()">
     <fa-icon [icon]="['far', 'times-circle']"></fa-icon>
  </div>
  <div class="nav-item" (click)="WhitePaper()">
      <p>White Paper</p>
    </div>
    <div class="nav-item" (click)="tokenomicsPage()">
      <p>Tokenomics</p>
    </div>
    <div class="nav-item" (click)="teamPage()">
      <p>Team</p>
    </div>
    <div class="nav-item" (click)="collectEgg()">
      <p>Kryptomon Dashboard</p>
    </div>
    <div class="nav-item">
      <p><a href="https://medium.com/kryptomon">Blog</a></p>
    </div>
    <div class="nav-item social-links">
      <a href="https://t.me/kryptomonofficial">
        <fa-icon [icon]="['fab', 'telegram-plane']"></fa-icon>
      </a>
      <a href="https://medium.com/kryptomon">
        <fa-icon [icon]="['fab', 'medium-m']"></fa-icon>
      </a>
      <a href="https://discord.gg/hYRjSfsWXt">
        <fa-icon [icon]="['fab', 'discord']"></fa-icon>
      </a>
      <a href="https://twitter.com/KryptomonTeam">
        <fa-icon [icon]="['fab', 'twitter']"></fa-icon>
      </a>
      <a href="https://www.reddit.com/r/Kryptomon/">
        <fa-icon [icon]="['fab', 'reddit']"></fa-icon>
      </a>
    </div>
</div>
<div class="hero">
  <div class="hero-nav">
    <div class="nav-item onlyMobile" (click)="toggleMenu()">
      <fa-icon [icon]="['fas', 'bars']"></fa-icon>
    </div>
    <div class="nav-item" (click)="WhitePaper()">
      <p>White Paper</p>
    </div>
    <div class="nav-item" (click)="tokenomicsPage()">
      <p>Tokenomics</p>
    </div>
    <div class="nav-item" (click)="teamPage()">
      <p>Team</p>
    </div>
    <div class="nav-item" (click)="collectEgg()">
      <p>Kryptomon Dashboard</p>
    </div>
      <div class="nav-item">
      <p><a href="https://medium.com/kryptomon">Blog</a></p>
    </div>
    <div class="nav-item social-links">
      <a href="https://t.me/kryptomonofficial">
        <fa-icon [icon]="['fab', 'telegram-plane']"></fa-icon>
      </a>
      <a href="https://medium.com/kryptomon">
        <fa-icon [icon]="['fab', 'medium-m']"></fa-icon>
      </a>
      <a href="https://discord.gg/hYRjSfsWXt">
        <fa-icon [icon]="['fab', 'discord']"></fa-icon>
      </a>
      <a href="https://twitter.com/KryptomonTeam">
        <fa-icon [icon]="['fab', 'twitter']"></fa-icon>
      </a>
      <a href="https://www.reddit.com/r/Kryptomon/">
        <fa-icon [icon]="['fab', 'reddit']"></fa-icon>
      </a>
    </div>
  </div>
  <div class="hero-content">
    <div class="row">
      <div class="col">
        <img src="./assets/img/Home/Group 5.svg" />
        <h1 class="title">Collect and breed digital NFT Kryptomons</h1>
        <p class="subtitle">Get, Take Care, Fight and Breed your Kryptomon.</p>
        <a href="https://t.me/kryptomonofficial" class="signup-btn" angulartics2On="click" angularticsAction="GoToTelegram" angularticsCategory="home">Join us on Telegram</a>
        <div class="timer-box">
          <p class="emoji">🚀</p>
          <div class="timer-content">
            <!--<div class="timer">
              <countdown units="Days | Hours | Minutes | Seconds" end="April 4, 2021"></countdown>
            </div>-->
            <div class="timer-sub">
              <p>Launching Soon! Join our Telegram Group and share your ideas and feedbacks!</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col egg-image">
        <div id='container'>
          <img src="./assets/img/Home/dna.png" />
          <canvas id="canvas3d" width="450" height="450"></canvas>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="wave-divider">
  <img src="./assets/img/Home/Path.svg" />
</div>
<div class="lottery-banner" [ngClass]="{'close':closeBanner}">
  <div class="close-banner" (click)="toggleBanner()">
    <fa-icon [icon]="['fas', 'chevron-down']" *ngIf="!closeBanner"></fa-icon>
    <fa-icon [icon]="['fas', 'chevron-up']" *ngIf="closeBanner"></fa-icon>
  </div>
  <div class="lottery-text">
    <h1>🎉</h1>
    <h1>Airdrop is open!</h1>
    <!--<p>To celebrate the upcoming launch of the Kryptomons world we're going to airdrop the first and only 100 Gen0 Kryptmons' Eggs.</p>
    <a href="#uvembed113534" class="signup-btn" angulartics2On="click" angularticsAction="ClaimYours" angularticsCategory="home">Claim yours!</a>-->
    <!--<p>Our claim egg service is currently offline due to high demand and is not possible to claim an egg now, join our Telegram and stay tuned as soon as it's back online!</p>-->
    <p>If you have been part of the airdrop list and whitelisted your address, get your Kryptomon egg now!</p>
   <div (click)="collectEgg()" class="signup-btn">Collect your egg</div>
  </div>
</div>
<div class="section first-section">
  <div class="row">
    <div class="col text-col">
      <h1>Get your Kryptomon now!</h1>
      <p>Your egg will be unique because is based on the BEP721 NFT Token that makes it irreplicable in its kind.</p>
      <p>When you get your Kryptomon, you own it forever, unless you decide to sell it or you treat it badly...</p>
    </div>
    <div class="col">
      <img src="./assets/img/Home/card-egg.png" />
    </div>
  </div>
</div>
<div class="section second-section">
  <div class="row">
    <div class="col">
      <img src="./assets/img/Home/dna.png" />
    </div>
    <div class="col text-col">
      <h1>Pokémon meets Cryptokitties and Tamagotchi</h1>
      <p>Take care of your Kryptomon referring to its own genetic characteristics.</p>
      <p>Grow it, train it, make it stronger and then challenge other Kryptomons all around the world, fight against other players like you.</p>
      <p>So hurry up, there are only 10.000 eggs available, and don't forget that you could be the fortunate one who gets the 1 in a 1.000 SPECIAL ONE egg</p>
     <a class="signup-btn" angulartics2On="click" angularticsAction="ClaimYours" angularticsCategory="home" (click)="toggleBanner()">Collect your egg!</a>
    </div>
  </div>
</div>
<div class="section third-section">
  <div class="row">
    <div class="col text-col">
      <h1>Why claim your Kryptomon now?</h1>
      <ul class="nopadding">
        <li>
          <p class="title">Limited edition</p>
          <p class="subtitle">Only 10.000 available forever! There will be no brand new eggs in the future but new eggs will be born only by the union of existing Kryptomon</p>
        </li>
        <li>
          <p class="title">One in 1.000 is a special egg</p>
          <p class="subtitle">You have to grab them as soon as possible</p>
        </li>
        <li>
          <p class="title-text">By claiming your Kryptomon you <b>support the project</b> and allow it to advance and activate the subsequent stages:</p>
          <ul class="inner-list">
            <li>
              <p class="subtitle">birth of Kryptomon and implementation of Tamagotchi features</p>
            </li>
            <li>
              <p class="subtitle">battle system integration to challenge other players all around the world</p>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="col">
      <h1 class="dieciK">10K</h1>
    </div>
  </div>
</div>
<div class="section timeline-section">
  <div class="row">
    <div class="col">
      <div class="card-custom">
        <h1>Phase 0</h1>
        <div class="card-item">
          <div class="card-content">
            <p class="title">First basic egg rendering available</p>
          </div>
          <div class="icon-card">
            <div class="dot"></div>
            <p>Launch</p>
          </div>
        </div>
        <div class="card-item">
          <div class="card-content">
            <p class="title">First 10.000 Eggs available to claim and will be airdropped</p>
          </div>
          <div class="icon-card">
            <div class="dot"></div>
            <p>Launch</p>
          </div>
        </div>
        <div class="card-item">
          <div class="card-content">
            <p class="title">Seed & Pre-Sale</p>
            <p class="subtitle">We will use the funds collected to pump the Phase 1 Kryptomon Development </p>
          </div>
          <div class="icon-card">
            <div class="dot"></div>
            <p>Launch</p>
          </div>
        </div>
        <div class="card-item">
          <div class="card-content">
            <p class="title">Non functioning egg</p>
            <!--<p class="subtitle">At this stage the eggs will contain the entire genetic code, but as all the eggs the only things we can do is wait.</p>-->
          </div>
          <div class="icon-card">
            <div class="dot"></div>
            <p>Launch</p>
          </div>
        </div>
        <div class="card-item">
          <div class="card-content">
            <p class="title">Market &amp; Trade on BSC's NFTS Exchanges</p>
            <!-- <p class="subtitle">Trade your Eggs or Kryptomons for ETH or exchange them like the gold old days.</p>-->
          </div>
          <div class="icon-card">
            <div class="dot"></div>
            <p>Launch</p>
          </div>
        </div>
        <div class="card-item last-card-item">
          <div class="card-content">
            <p class="title">Dynamic eggs rendering</p>
            <!--<p class="subtitle">As said all the eggs are unique, and we want to make all the eggs looks like unique, so based on the genetic code the algo will automatically generate your unique egg and then Kryptomon!</p>-->
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card-custom second-card">
        <img src="./assets/img/Home/gem.png" />
        <h1>Phase 1</h1>
        <div class="card-item">
          <div class="card-content">
            <p class="title">Eggs will hatch!</p>
            <p class="subtitle">Kryptomon will born</p>
          </div>
        </div>
        <div class="card-item">
          <div class="card-content">
            <p class="title">Staking</p>
            <p class="subtitle">Stake your $KMON to earn items and powerups</p>
          </div>
        </div>
        <div class="card-item">
          <div class="card-content">
            <p class="title">Dynamic Kryptomon!</p>
            <p class="subtitle">Rendering based on DNA</p>
          </div>
        </div>
        <div class="card-item">
          <div class="card-content">
            <p class="title">Tamagotchi functions</p>
            <p class="subtitle">Take care and breed to generate new unique eggs</p>
          </div>
        </div>
      </div>
      <div class="card-custom">
        <h1>Phase 2</h1>
        <div class="card-item">
          <div class="card-content">
            <p class="title">It's time to fight!</p>
            <p class="subtitle">Battle system integration use your Kryptomon to fight against other players, gain experience, train them, make them the strongest Kryptomons around the world..</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="section fourth-section">
  <div class="row">
    <div class="col text-col">
      <h1>Kryptomon $KMON!</h1>
      <p>Together with the eggs we will issue also an BEP20 Token, that will be use as the Kryptomon's world currency.</p>
      <p>Buy items and power-ups, bet and earn Coins by winning battles against other players and stake them to earn more coins or conver them in other amenities.</p>
      <div (click)="tokenomicsPage()" class="signup-btn">Check our tokenomics</div>
    </div>
    <div class="col">
      <img src="./assets/img/Home/coin.png" />
    </div>
  </div>
</div>
<div class="section fifth-section">
  <div class="row">
    <div class="col text-col">
      <div id="chartdiv"></div>
    </div>
    <div class="col">
      <h1>Milestones</h1>
      <div class="list-bullet">
        <div class="bullet blue">1</div>
        <p>Seed Sale - June 2021</p>
      </div>
      <div class="list-bullet">
        <div class="bullet pink">3</div>
        <p>Phase 0 Relase & Airdrop - June 30th, 2021</p>
      </div>
      <div class="list-bullet">
        <div class="bullet purple">2</div>
        <p>Community Sale & IDO - August 2021</p>
      </div>
      <div class="list-bullet">
        <div class="bullet orange">4</div>
        <p>Public Listing - End of August 2021</p>
      </div>
      <div class="list-bullet">
        <div class="bullet yellow">5</div>
        <p>Phase 1 - Q3 2021</p>
      </div>
      <div class="list-bullet">
        <div class="bullet green">6</div>
        <p>Phase 2 - Q4 2021</p>
      </div>
    </div>
  </div>
</div>
<div class="section last-section">
  <div class="row">
    <div class="col">
      <img src="./assets/img/Home/dnadue.png" />
      <h1>1 in a 1000<br>is a special one</h1>
      <div class="signup-btn" (click)="gotoReddit()">Join us on Telegram</div>
      <img src="./assets/img/Home/gem.png" />
    </div>
  </div>
</div>
<div class="wave-divider-footer">
  <img src="./assets/img/Home/wave.svg" />
</div>
<div class="footer">
  <div class="row">
    <div class="col">
      <img src="./assets/img/Home/Group 5.svg" />
      <p>The Kryptomon Company - 2021</p>
      <a href="https://www.iubenda.com/privacy-policy/20002823" class="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a>
      <a href="https://www.iubenda.com/privacy-policy/20002823/cookie-policy" class="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe " title="Cookie Policy ">Cookie Policy</a>
    </div>
  </div>
</div>

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ContractService } from '../../services/contract.service';
import { BigNumber } from 'ethers';
import * as winners from './winners';
import lootboxes from './winners/lootboxes.json'
import { number } from '@amcharts/amcharts4/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';

declare const tsParticles;

interface httpResult {
  status
  data
  description
}

interface Kryptomons {
  image
  type
  talent
  typeFormatted
  xfactor
  speciality
  timeBorn
  super
  personality
  generation
  id
  islandImage
  timeBornUnix
  unfreezable
  bio
}

interface Personality {
  Constitution
  Affection
  Crazyness
  Instinct
  Hunger
  Laziness
  Braveness
  Smart
  Ego
  generation
}

@Component({
  selector: 'app-staking',
  templateUrl: './staking.component.html',
  styleUrls: ['./staking.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StakingComponent implements OnInit {

  public showContent
  public showContentLP
  public selectedAmountUI
  public selectedAmountUILP
  public rangeValue
  public rangeValueLP
  public ticketsAvailable
  public ticketsAvailableLP
  public ticketsPlayed
  public win
  public noWin
  public kmonStaked
  public haveParticipated: boolean
  public haveWon
  public accounts
  public balance
  public kmonToBeStaked
  public ticketsAvailableWei
  public ticketsAvailableWeiLP
  public kmonToBeWithdraw
  public lpToBeWithdraw
  public kmonStakedWei
  public kmonBalance
  public isLoading
  public isLoadingLP
  public totalKMONStaked
  public totalKMONStakedV1
  public totalKMONStakedWeiV1: BigNumber
  public totalKMONStakedWei: BigNumber
  public totalLPStaked
  public totalLPStakedWei: BigNumber
  public playDisabled
  public playDisabledLP
  public selectedTicketPlayed
  public playersInLottery
  public isApproved
  public isApprovedLP
  public lotteryStatus
  public ticketsPlayedUser
  public rewardRate: BigNumber
  public rewardRateLP: BigNumber
  public winningClaims
  public isLoadingCollect
  public collectedKryptomon
  public showShare
  public kryptomons
  public typeFormatted = ["Fire", "Water", "Ice", "Ground", "Air", "Electro", "Ghost", "Grass"];
  public submittingWinners;
  public haveMigrated
  public kmonStakedOldWei
  public kmonStakedOld
  public migrationRunning
  public accumulationStarted
  public balanceLP
  public lpBalance
  public lpToBeStaked
  public lpStakedWei
  public lpStaked
  public lootboxesObject

  constructor(public appComponent: AppComponent, public contractService: ContractService, public http: HttpClient, public router: Router) {
    this.showContent = [false, false, true];
    this.showContentLP = [false, false, true];
    this.selectedAmountUI = [false, false, false, false]
    this.selectedAmountUILP = [false, false, false, false]
    this.rangeValue = 0;
    this.rangeValueLP = 0;
    this.ticketsAvailable = 0;
    this.ticketsAvailableLP = 0;
    this.ticketsPlayed = 0;
    this.win = true;
    this.noWin = false;
    this.haveParticipated = false;
    this.haveWon = false;
    this.showShare = false;
    this.kmonStaked = 0;
    this.kmonToBeStaked = 0;
    this.kmonToBeWithdraw = 0;
    this.lpToBeWithdraw = 0;
    this.isLoading = false;
    this.isLoadingLP = false;
    this.totalKMONStaked = 0;
    this.totalLPStaked = 0;
    this.lpStaked = BigNumber.from(0);
    this.totalLPStakedWei = BigNumber.from(0);
    this.totalKMONStakedWei = BigNumber.from(0);
    this.totalKMONStakedV1 = 0;
    this.totalKMONStakedWeiV1 = BigNumber.from(0);
    this.kmonBalance = 0;
    this.playDisabled = true;
    this.playDisabledLP = true;
    this.selectedTicketPlayed = 0;
    this.playersInLottery = 0;
    this.isApproved = false;
    this.isApprovedLP = false;
    this.ticketsPlayedUser = 0;
    this.rewardRate = BigNumber.from(0);
    this.rewardRateLP = BigNumber.from(0);
    this.winningClaims = [];
    this.isLoadingCollect = false;
    this.collectedKryptomon = false;
    this.kryptomons = [];
    this.submittingWinners = false;
    this.haveMigrated = true;
    this.kmonStakedOldWei = 0;
    this.kmonStakedOld = 0;
    this.migrationRunning = false;
    this.accumulationStarted = true;
    this.lpToBeStaked = 0;
    this.balanceLP = 0;
    this.lpBalance = 0;
    this.lpStakedWei = 0;
    this.lpStaked = 0;
    this.lootboxesObject = lootboxes;

    if (this.appComponent.accounts == undefined) {
      this.appComponent.accountsObservable.subscribe((accounts) => {
        this.accounts = accounts;
        if (this.accounts[0] !== undefined && this.accounts[0] !== "") {
          this.checkAllowance()
          this.checkAllowanceLP()
          this.checkBalance()
          setInterval(() => {
            this.checkBalance();
          }, 5000)
        }
      });
    } else {
      this.accounts = this.appComponent.accounts;
      this.checkAllowance()
      this.checkAllowanceLP()
      this.checkBalance()
      setInterval(() => {
        this.checkBalance();
      }, 5000)
    }
  }


  async getAccounts() {
    this.accounts = await this.contractService.web3.eth.getAccounts().then((accounts) => {
      console.log(accounts)
      return accounts;
    }).catch((err) => {
      ////console.log(err, 'err!!');
    });
    if (this.accounts.length == 0) {
      //this.connection_text = "Grant access";
    } else {
      //this.connection_text = "Connected";
    }
  }

  async checkBalance() {

    this.checkLotteryStatus()

    this.balance = await this.contractService.contractTokenInstance.methods.balanceOf(this.accounts[0]).call();
    this.kmonBalance = this.contractService.web3.utils.fromWei(this.balance)

    this.balanceLP = await this.contractService.contractLPInstance.methods.balanceOf(this.accounts[0]).call();
    this.lpBalance = this.contractService.web3.utils.fromWei(this.balanceLP)

    this.kmonStakedWei = BigNumber.from(await this.contractService.newContractRewardsInstance.methods.balanceOf(this.accounts[0]).call());
    this.kmonStaked = this.contractService.web3.utils.fromWei(this.kmonStakedWei.toString())

    this.lpStakedWei = BigNumber.from(await this.contractService.newContractRewardsInstanceLP.methods.balanceOf(this.accounts[0]).call());
    this.lpStaked = this.contractService.web3.utils.fromWei(this.lpStakedWei.toString())

    this.kmonStakedOldWei = BigNumber.from(await this.contractService.contractRewardsInstance.methods.balanceOf(this.accounts[0]).call());
    this.kmonStakedOld = this.contractService.web3.utils.fromWei(this.kmonStakedOldWei.toString())

    this.totalKMONStakedWei = BigNumber.from(await this.contractService.newContractRewardsInstance.methods.totalSupply().call());
    this.totalKMONStaked = Math.floor(this.contractService.web3.utils.fromWei(this.totalKMONStakedWei.toString()));

    this.totalLPStakedWei = BigNumber.from(await this.contractService.newContractRewardsInstanceLP.methods.totalSupply().call());
    this.totalLPStaked = Math.floor(this.contractService.web3.utils.fromWei(this.totalLPStakedWei.toString()));

    this.totalKMONStakedWeiV1 = BigNumber.from(await this.contractService.contractRewardsInstance.methods.totalSupply().call());
    this.totalKMONStakedV1 = Math.floor(this.contractService.web3.utils.fromWei(this.totalKMONStakedWeiV1.toString()));

    let ticketEarned = await this.contractService.newContractRewardsInstance.methods.earned(this.accounts[0]).call();
    this.ticketsAvailable = parseFloat(this.contractService.web3.utils.fromWei(ticketEarned)).toFixed(5);
    this.ticketsAvailableWei = this.contractService.web3.utils.fromWei(ticketEarned)

    let ticketEarnedLP = await this.contractService.newContractRewardsInstanceLP.methods.earned(this.accounts[0]).call();
    this.ticketsAvailableLP = parseFloat(this.contractService.web3.utils.fromWei(ticketEarnedLP)).toFixed(5);
    this.ticketsAvailableWeiLP = this.contractService.web3.utils.fromWei(ticketEarnedLP)

    let lotteryId = await this.contractService.contractLotteryInstance.methods.lotteryId().call();
    this.haveParticipated = await this.getTicketCountInLottery(lotteryId - 1) > 0;

    this.ticketsPlayed = await this.contractService.contractLotteryInstance.methods.getTicketsInLottery(lotteryId).call();
    this.ticketsPlayedUser = await this.contractService.contractLotteryInstance.methods.balanceOf(this.accounts[0]).call();

    this.playersInLottery = await this.contractService.contractLotteryInstance.methods.getPlayersInLottery(lotteryId).call();

    this.rewardRate = BigNumber.from(await this.contractService.newContractRewardsInstance.methods.rewardRate().call());
    this.rewardRateLP = BigNumber.from(await this.contractService.newContractRewardsInstanceLP.methods.rewardRate().call());

    if (this.rewardRate.eq(0))
      this.accumulationStarted = false;

    this.winningClaims = await this.getWinningClaims();
    this.noWin = !this.hasWonInLastLottery();

    if (this.kmonStakedOld > 0) {
      this.haveMigrated = false;
    } else {
      this.haveMigrated = true;
    }

    if (this.winningClaims.length > 0){
      this.haveWon = true;
    } else {
      this.haveWon = false;
    }

    this.submittingWinners = await this.setSubmittingWinners(lotteryId);
  }

  setSubmittingWinners(lotteryId) {
    if (lotteryId == 0) {
      return false
    }

    const winningTrees = Object.entries(winners);
    const lastTree = winningTrees[lotteryId - 1];

    if (lastTree == undefined) {
      return true;
    }
    else if (Object.entries(lastTree[1].claims).length == 0) {
      return true;
    }
    else {
      return false;
    }
  }

  async collectEgg() {
    this.isLoadingCollect = true;
    const merkle = this.winningClaims.shift();

    await this.contractService.contractLotteryInstance.methods.claimLotteryEgg(merkle.lotteryId, merkle.claim.index, this.accounts[0], merkle.claim.generation, merkle.claim.proof).send({
      from: this.accounts[0]
    }).then((receipt) => {
      console.log(receipt);
      console.log(receipt.events);
      this.getKryptomons();
      // save kryptomon data
      this.isLoadingCollect = false;
    }).catch((err) => {
      console.log(err, 'err');
      this.isLoadingCollect = false;
    });
  }

  async getKryptomons() {
    this.contractService.contractInstance.methods.ownedKryptomons().call({
      from: this.accounts[0],
    }).then((receipt) => {
      //////console.log(receipt)
      if (receipt.length > 0) {
        this.getKryptomonDetails(receipt[receipt.length - 1])
      }
    }).catch((err) => {
      ////console.log(err, 'err');
    });
  }

  async getKryptomonDetails(id) {
    await this.contractService.contractInstance.methods.getKryptomonDetails(id).call({
      from: this.accounts[0],
    }).then((kryptomon) => {
      this.kryptomons.push({
        id: kryptomon[0],
        genes: kryptomon[1],
        matron: kryptomon[2],
        sire: kryptomon[3],
        timeBorn: kryptomon[4],
        status: kryptomon[7],
        timeHatched: kryptomon[8],
        timeToHatch: kryptomon[9],
        extra_data: kryptomon[6]
      });
      this.kryptomonImage();
    }).catch((err) => {
      ////console.log(err, 'err');
    });
  }

  async kryptomonImage() {
    for (let x = 0; x < this.kryptomons.length; x++) {
      let genes = this.kryptomons[x].genes;
      /*01->Fuoco
      23->Acqua
      45->Ghiaccio
      67->Terra
      78->Aria
      910->Elettricità
      1112->Spettro
      1314->Erba*/
      let typeDraft = [];
      let type = [];
      let c = 0;
      for (let i = 0; i < 8; i++) {
        let sum = parseInt(genes[c]) * parseInt(genes[c + 1]);
        c = c + 2;
        typeDraft.push(sum);
      }
      let typeSelected = this.indexOfMax(typeDraft)
      let kryptomon = {} as Kryptomons;
      kryptomon.type = (typeSelected);
      kryptomon.talent = (typeDraft[typeSelected])
      kryptomon.typeFormatted = (this.typeFormatted[typeSelected]);

      let timeBorn = new Date(this.kryptomons[x].timeBorn * 1000)
      let month = timeBorn.toLocaleString('default', { month: 'short' });
      let day = timeBorn.getDate();
      let year = timeBorn.getFullYear();
      kryptomon.timeBorn = month + " " + day + " " + year;
      kryptomon.timeBornUnix = this.kryptomons[x].timeBorn;

      if (genes[19] > (genes[20] * 1.10)) {
        kryptomon.speciality = "attack";
      } else if (genes[20] > (genes[19] * 1.10)) {
        kryptomon.speciality = "defense";
      } else {
        kryptomon.speciality = "balance";
      }

      if (genes[22] == 0) {
        kryptomon.super = false;
        var img_selected = kryptomon.speciality;
      } else {
        img_selected = 'super';
        kryptomon.super = true;
      }

      kryptomon.unfreezable = this.kryptomons[x].extra_data[0];

      //console.log(kryptomon.unfreezable)

      if (kryptomon.unfreezable == 1) {
        kryptomon.image = "./assets/img/top_special/" + kryptomon.typeFormatted + "/" + img_selected + ".png";
      } else {
        kryptomon.image = "./assets/img/" + kryptomon.typeFormatted + "/" + img_selected + ".png";
      }

      kryptomon.islandImage = "./assets/img/top_special/" + kryptomon.typeFormatted + "/island.png";

      //kryptomon.image = "./assets/img/"+kryptomon.typeFormatted+"/"+img_selected+".png";
      //kryptomon.renderedImage = "./assets/img/"+kryptomon.typeFormatted+"/animated/"+img_selected+".json";
      let personality = {} as Personality
      personality.Constitution = genes[24];
      personality.Affection = genes[27];
      personality.Crazyness = genes[28];
      personality.Instinct = genes[29];
      personality.Hunger = genes[30];
      personality.Laziness = genes[31];
      personality.Braveness = genes[32];
      personality.Smart = genes[33];
      personality.Ego = genes[35];
      kryptomon.personality = personality;
      kryptomon.generation = genes[genes.length - 1];
      kryptomon.id = this.kryptomons[x].id;
      this.kryptomons[x].data = kryptomon;

      let post = {
        meta: kryptomon,
        dna: genes,
        network: this.contractService.networkName
      }

      this.http.post("https://api.kryptomon.co/json/kryptomon/checkMeta.php", post).subscribe((item: httpResult) => {
        if (item.status == "empty") {
          this.http.post("https://api.kryptomon.co/json/kryptomon/save_meta.php", post).subscribe((item) => {
            this.kryptomons[x].newborn = false;

          }, (error) => {
            //console.log(error)
          })
        }
      }, (error) => {
        //console.log(error)
      })
    }

    this.collectedKryptomon = true;
    this.isLoadingCollect = false;
    this.showShare = true;

    setTimeout(() => {
      this.startConfetti()
    }, 250)
  }

  indexOfMax(arr) {
    if (arr.length === 0) {
      return -1;
    }

    var max = arr[0];
    var maxIndex = 0;

    for (var i = 1; i < arr.length; i++) {
      if (arr[i] > max) {
        maxIndex = i;
        max = arr[i];
      }
    }

    return maxIndex;
  }

  closeShare() {
    this.showShare = false;
    this.collectedKryptomon = false;
  }

  startConfetti() {
    tsParticles.load("tsparticles", {
      fpsLimit: 60,
      particles: {
        number: {
          value: 0
        },
        color: {
          value: ["#00FFFC", "#FC00FF", "#fffc00"]
        },
        shape: {
          type: "confetti",
          options: {
            confetti: {
              type: ["circle", "square"]
            }
          }
        },
        opacity: {
          value: 1,
          animation: {
            enable: true,
            minimumValue: 0,
            speed: 2,
            startValue: "max",
            destroy: "min"
          }
        },
        size: {
          value: 7,
          random: {
            enable: true,
            minimumValue: 3
          }
        },
        links: {
          enable: false
        },
        life: {
          duration: {
            sync: true,
            value: 5
          },
          count: 1
        },
        move: {
          enable: true,
          gravity: {
            enable: true,
            acceleration: 20
          },
          speed: 20,
          decay: 0.1,
          direction: "none",
          random: false,
          straight: false,
          outModes: {
            default: "destroy",
            top: "none"
          }
        }
      },
      interactivity: {
        detectsOn: "window",
        events: {
          resize: true
        }
      },
      detectRetina: true,
      background: {
        color: "#232123"
      },
      emitters: {
        direction: "none",
        life: {
          count: 0,
          duration: 0.1,
          delay: 0.4
        },
        rate: {
          delay: 0.1,
          quantity: 100
        },
        size: {
          width: 0,
          height: 0
        }
      }
    });
  }

  getTicketCountInLottery(lotteryId): number {
    if (lotteryId < 0)
      return 0;

    if (lotteryId == 0) {
      return this.contractService.contractOldLotteryInstance.methods.getUserTicketCountForLottery(lotteryId, this.accounts[0]).call();
    }
    else {
      return this.contractService.contractLotteryInstance.methods.getUserTicketCountForLottery(lotteryId, this.accounts[0]).call();
    }
  }

  hasWonInLastLottery(): boolean {
    const winningTrees = Object.entries(winners);
    const lastTree = winningTrees[winningTrees.length - 1];

    if (Object.entries(lastTree[1].claims).length == 0)
      return true;

    return lastTree[1].claims[this.accounts[0]];
  }

  async getWinningClaims() {
    // loop through each lottery merkle
    let claims = [];
    for (const [key, value] of Object.entries(winners)) {
      // find claims
      if (value.claims[this.accounts[0]]) {
        claims.push({
          lotteryId: value.lotteryId,
          claim: value.claims[this.accounts[0]]
        })
      }
    }
    console.log(claims);

    // collect unclaimed eggs
    let unclaimedClaims = [];
    for (var i = 0; i < claims.length; i++) {
      var value = claims[i]
      const isClaimed = await this.contractService.contractLotteryInstance.methods.isClaimed(value.lotteryId, value.claim.index).call();
      if (!isClaimed)
        unclaimedClaims.push(value);
    }

    return unclaimedClaims;
  }

  async checkLotteryStatus() {
    this.lotteryStatus = await this.contractService.contractLotteryInstance.methods.lottery_state().call();
  }

  selectContent(index) {
    for (var i = 0; i < 3; i++) {
      if (i == index) {
        this.showContent[i] = true;
      } else {
        this.showContent[i] = false;
      }
    }
    this.selectedAmountUI = [false, false, false, false];
  }

  selectContentLP(index) {
    for (var i = 0; i < 3; i++) {
      if (i == index) {
        this.showContentLP[i] = true;
      } else {
        this.showContentLP[i] = false;
      }
    }
    this.selectedAmountUILP = [false, false, false, false];
  }

  selectAmountStake(index, value) {
    for (var i = 0; i < 4; i++) {
      if (i == index) {
        this.selectedAmountUI[i] = true;
      } else {
        this.selectedAmountUI[i] = false;
      }
    }

    this.kmonToBeStaked = (Math.floor(this.contractService.web3.utils.fromWei(this.balance) * value) / 100)
  }

  selectAmountStakeLP(index, value) {
    for (var i = 0; i < 4; i++) {
      if (i == index) {
        this.selectedAmountUILP[i] = true;
      } else {
        this.selectedAmountUILP[i] = false;
      }
    }

    this.lpToBeStaked = (Math.floor(this.contractService.web3.utils.fromWei(this.balanceLP) * value) / 100)
  }

  selectAmountWithdraw(index, value) {
    for (var i = 0; i < 4; i++) {
      if (i == index) {
        this.selectedAmountUI[i] = true;
      } else {
        this.selectedAmountUI[i] = false;
      }
    }

    this.kmonToBeWithdraw = (Math.floor(this.contractService.web3.utils.fromWei(this.kmonStakedWei.toString()) * value) / 100)
  }

  selectAmountWithdrawLP(index, value) {
    for (var i = 0; i < 4; i++) {
      if (i == index) {
        this.selectedAmountUILP[i] = true;
      } else {
        this.selectedAmountUILP[i] = false;
      }
    }

    this.lpToBeWithdraw = (Math.floor(this.contractService.web3.utils.fromWei(this.lpStakedWei.toString()) * value) / 100)
  }

  checkPlay() {
    let ticketToBePlayed = this.rangeValue == 100 ? this.ticketsAvailableWei : (this.ticketsAvailableWei * this.rangeValue / 100).toString();
    this.selectedTicketPlayed = Math.floor(parseFloat(ticketToBePlayed));
    if (this.selectedTicketPlayed > 0) {
      this.playDisabled = false;
    } else {
      this.playDisabled = true;
    }
  }

  checkPlayLP() {
    let ticketToBePlayed = this.rangeValueLP == 100 ? this.ticketsAvailableWeiLP : (this.ticketsAvailableWeiLP * this.rangeValueLP / 100).toString();
    this.selectedTicketPlayed = Math.floor(parseFloat(ticketToBePlayed));
    if (this.selectedTicketPlayed > 0) {
      this.playDisabledLP = false;
    } else {
      this.playDisabledLP = true;
    }
  }

  playTicket() {

    let ticketToBePlayed = this.rangeValue == 100 ? this.ticketsAvailableWei : (this.ticketsAvailableWei * this.rangeValue / 100).toString();

    if (Math.floor(parseFloat(ticketToBePlayed)) > 0) {
      this.isLoading = true;
      let ticketToBePlayedWei = this.contractService.web3.utils.toWei(ticketToBePlayed)
      this.contractService.newContractRewardsInstance.methods.enterLottery(ticketToBePlayedWei).send({
        from: this.accounts[0]
      }).then((result) => {
        if (result.events.EnteredLottery !== undefined) {
          this.rangeValue = 0;
          this.checkBalance()
        } else {
          console.log('error');
          this.isLoading = false;
        }
        this.isLoading = false;
      }, (error) => {
        console.log(error)
        this.isLoading = false;
      })
    }
  }

  playTicketLP() {

    let ticketToBePlayed = this.rangeValue == 100 ? this.ticketsAvailableWeiLP : (this.ticketsAvailableWeiLP * this.rangeValueLP / 100).toString();

    if (Math.floor(parseFloat(ticketToBePlayed)) > 0) {
      this.isLoadingLP = true;
      let ticketToBePlayedWei = this.contractService.web3.utils.toWei(ticketToBePlayed)
      this.contractService.newContractRewardsInstanceLP.methods.enterLottery(ticketToBePlayedWei).send({
        from: this.accounts[0]
      }).then((result) => {
        if (result.events.EnteredLottery !== undefined) {
          this.rangeValueLP = 0;
          this.checkBalance()
        } else {
          console.log('error');
          this.isLoadingLP = false;
        }
        this.isLoadingLP = false;
      }, (error) => {
        console.log(error)
        this.isLoadingLP = false;
      })
    }
  }


  async checkAllowance() {
    this.isLoading = true;
    let allowance = await this.contractService.contractTokenInstance.methods.allowance(this.accounts[0], this.contractService.newRewardsAddress).call();
    console.log(allowance)
    if (allowance > 0) {
      this.isApproved = true;
      this.isLoading = false;
    } else {
      this.isApproved = false;
      this.isLoading = false;
    }
  }

  async checkAllowanceLP() {
    this.isLoadingLP = true;
    let allowance = await this.contractService.contractLPInstance.methods.allowance(this.accounts[0], this.contractService.newContractRewardsInstanceLP._address).call();
    console.log(allowance)
    if (allowance > 0) {
      this.isApprovedLP = true;
      this.isLoadingLP = false;
    } else {
      this.isApprovedLP = false;
      this.isLoadingLP = false;
    }
  }

  async giveAllowance() {
    this.isLoading = true;
    this.contractService.contractTokenInstance.methods.approve(this.contractService.newRewardsAddress, this.contractService.web3.utils.toWei('999999999999')).send({
      from: this.accounts[0]
    }).then((result) => {
      if (result.events.Approval !== undefined) {
        this.isApproved = true;
        this.isLoading = false;
      } else {
        this.isLoading = false;
        this.isApproved = false;
      }
    }, (error) => {
      //console.log(error)
      this.isLoading = false;
      this.isApproved = false;
    })
  }

  async giveAllowanceLP() {
    this.isLoadingLP = true;
    this.contractService.contractLPInstance.methods.approve(this.contractService.newContractRewardsInstanceLP._address, this.contractService.web3.utils.toWei('999999999999')).send({
      from: this.accounts[0]
    }).then((result) => {
      if (result.events.Approval !== undefined) {
        this.isApprovedLP = true;
        this.isLoadingLP = false;
      } else {
        this.isLoadingLP = false;
        this.isApprovedLP = false;
      }
    }, (error) => {
      //console.log(error)
      this.isLoadingLP = false;
      this.isApprovedLP = false;
    })
  }

  async migrateKMON() {

    this.kmonToBeWithdraw = this.kmonStakedOld;
    this.isLoading = true;
    this.migrationRunning = true;
    let kmonToBeWithdrawWei = this.contractService.web3.utils.toWei(this.kmonToBeWithdraw.toString());
    this.contractService.contractRewardsInstance.methods.withdraw(kmonToBeWithdrawWei).send({
      from: this.accounts[0]
    }).then((result) => {
      console.log(result)
      if (result.events.Withdrawn !== undefined) {
        this.kmonToBeStaked = this.kmonToBeWithdraw
        let kmonToBeStakedWei = this.contractService.web3.utils.toWei(this.kmonToBeStaked.toString());
        this.contractService.newContractRewardsInstance.methods.stake(kmonToBeStakedWei).send({
          from: this.accounts[0]
        }).then((result) => {
          console.log(result)

          if (result.events.Staked !== undefined) {
            this.checkBalance();
            this.kmonToBeStaked = 0;
            this.kmonToBeWithdraw = 0;
            this.migrationRunning = false;
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.migrationRunning = false;
          }
        }, (error) => {
          console.log(error)
          this.isLoading = false;
          this.migrationRunning = false;
        })
      } else {
        this.isLoading = false;
        this.migrationRunning = false;
      }
    }, (error) => {
      console.log(error)
      this.isLoading = false;
      this.migrationRunning = false;
    })

  }

  stakeKMON() {
    if (this.kmonToBeStaked > 0 && this.kmonToBeStaked <= this.kmonBalance) {
      this.isLoading = true;
      let kmonToBeStakedWei = this.contractService.web3.utils.toWei(this.kmonToBeStaked.toString());
      this.contractService.newContractRewardsInstance.methods.stake(kmonToBeStakedWei).send({
        from: this.accounts[0]
      }).then((result) => {
        console.log(result)
        if (result.events.Staked !== undefined) {
          this.checkBalance();
          this.kmonToBeStaked = 0;
        } else {
          this.isLoading = false;
        }
        this.isLoading = false;
      }, (error) => {
        console.log(error)
        this.isLoading = false;
      })
    }
  }

  stakeLP() {
    if (this.lpToBeStaked > 0 && this.lpToBeStaked <= this.lpBalance) {
      this.isLoadingLP = true;
      let lpToBeStakedWei = this.contractService.web3.utils.toWei(this.lpToBeStaked.toString());
      this.contractService.newContractRewardsInstanceLP.methods.stake(lpToBeStakedWei).send({
        from: this.accounts[0]
      }).then((result) => {
        console.log(result)
        if (result.events.Staked !== undefined) {
          this.checkBalance();
          this.lpToBeStaked = 0;
        } else {
          this.isLoadingLP = false;
        }
        this.isLoadingLP = false;
      }, (error) => {
        console.log(error)
        this.isLoadingLP = false;
      })
    }
  }

  withdrawKMONFromOld() {
    this.kmonToBeWithdraw = this.kmonStakedOld;
    this.isLoading = true;
    let kmonToBeWithdrawWei = this.contractService.web3.utils.toWei(this.kmonToBeWithdraw.toString());
    this.contractService.contractRewardsInstance.methods.withdraw(kmonToBeWithdrawWei).send({
      from: this.accounts[0]
    }).then((result) => {
      console.log(result)
      if (result.events.Withdrawn !== undefined) {
        this.checkBalance();
        this.kmonToBeWithdraw = 0;
      } else {
        this.isLoading = false;
      }
      this.isLoading = false;
    }, (error) => {
      console.log(error)
      this.isLoading = false;
    })
  }

  withdrawKMON() {
    if (this.kmonToBeWithdraw > 0 && this.kmonToBeWithdraw <= this.kmonStaked) {
      this.isLoading = true;
      let kmonToBeWithdrawWei = this.contractService.web3.utils.toWei(this.kmonToBeWithdraw.toString());
      this.contractService.newContractRewardsInstance.methods.withdraw(kmonToBeWithdrawWei).send({
        from: this.accounts[0]
      }).then((result) => {
        console.log(result)
        if (result.events.Withdrawn !== undefined) {
          this.checkBalance();
          this.kmonToBeWithdraw = 0;
        } else {
          this.isLoading = false;
        }
        this.isLoading = false;
      }, (error) => {
        console.log(error)
        this.isLoading = false;
      })
    }
  }

  withdrawLP() {
    if (this.lpToBeWithdraw > 0 && this.lpToBeWithdraw <= this.lpStaked) {
      this.isLoadingLP = true;
      let lpToBeWithdrawWei = this.contractService.web3.utils.toWei(this.lpToBeWithdraw.toString());
      this.contractService.newContractRewardsInstanceLP.methods.withdraw(lpToBeWithdrawWei).send({
        from: this.accounts[0]
      }).then((result) => {
        console.log(result)
        if (result.events.Withdrawn !== undefined) {
          this.checkBalance();
          this.lpToBeWithdraw = 0;
        } else {
          this.isLoadingLP = false;
        }
        this.isLoadingLP = false;
      }, (error) => {
        console.log(error)
        this.isLoadingLP = false;
      })
    }
  }

  formatNumber(number): number {
    return Math.floor(number * 100) / 100
  }

  ngOnInit(): void {
  }

  getMyRewardRateFormatted(seconds: number,
    decimals: number): string {
    let rewardRate = this.getMyRewardRate(seconds, decimals);
    return parseFloat(this.contractService.web3.utils.fromWei(rewardRate.toString())).toFixed(4);
  }

  getMyRewardRateFormattedLP(seconds: number,
    decimals: number): string {
    let rewardRate = this.getMyRewardRateLP(seconds, decimals);
    return parseFloat(this.contractService.web3.utils.fromWei(rewardRate.toString())).toFixed(4);
  }

  getMyRewardRate(seconds: number,
    decimals: number): BigNumber {
    const kmonRewardRate = this.getHypotheticalRewardRate(this.kmonStakedWei, this.totalKMONStakedWei, seconds, decimals, this.rewardRate);
    return kmonRewardRate;
  }

  getMyRewardRateLP(seconds: number,
    decimals: number): BigNumber {
    const lpRewardRate = this.getHypotheticalRewardRate(this.lpStakedWei, this.totalLPStakedWei, seconds, decimals, this.rewardRateLP);
    return lpRewardRate;
  }

  getV4WeeklyRewardRate() {
    return parseFloat(this.contractService.web3.utils.fromWei(this.getHypotheticalRewardRate(this.totalKMONStakedWei, this.totalKMONStakedWei, 60 * 60 * 24 * 7, 1, this.rewardRate).toString())).toFixed(0);
  }

  getV4WeeklyRewardRateLP() {
    return parseFloat(this.contractService.web3.utils.fromWei(this.getHypotheticalRewardRate(this.totalLPStakedWei, this.totalLPStakedWei, 60 * 60 * 24 * 7, 1, this.rewardRateLP).toString())).toFixed(0);
  }

  getHypotheticalRewardRate(stakedAmount: BigNumber,
    totalStakedAmount: BigNumber,
    seconds: number,
    decimals: number,
    rewardRate: BigNumber): BigNumber {
    let amount = BigNumber.from("0");
    if (totalStakedAmount.gt(BigNumber.from("0"))) {
      const rr = rewardRate.mul(BigNumber.from(seconds));
      const sa = stakedAmount;
      const tsa = totalStakedAmount;
      const urr = rr.mul(sa).mul(BigNumber.from(decimals));
      amount = urr.div(tsa);
    }
    return amount;

  }

  getWinningProbability(ticketsBet, totalTicketsBet, players): string {
    if (players == 0)
      return "0";
    const eggsAvailable = Math.floor(players / 10);

    const meanTicketsBet = (totalTicketsBet - ticketsBet) / (players - 1);

    const totalLessMean = totalTicketsBet - (eggsAvailable * meanTicketsBet / 2)

    const probability = (1 - ((totalLessMean - ticketsBet) / totalLessMean) ** eggsAvailable) * 100;

    return probability.toFixed(2);
  }

  gotoDashboard() {
    this.router.navigate(['/']);
  }

  gotoItems() {
    window.open("https://market.kryptomon.co/items");
  }
  
  openStake(){
    document.getElementById("popupOverlay").style.display = "flex";
    document.getElementById("stakePopup").style.display = "flex"; 
  }
  openStakeLP(){
    console.log("click")
    document.getElementById("popupOverlayLP").style.display = "flex";
    document.getElementById("stakeLPPopup").style.display = "flex"; 
  }
  openUnstake(){
    document.getElementById("unstakePopup").style.display = "flex";
    document.getElementById("popupOverlay").style.display = "flex";
  }
  openUnstakeLP(){
    document.getElementById("unstakeLPPopup").style.display = "flex";
    document.getElementById("popupOverlayLP").style.display = "flex";
  }
  closeStake(){
    document.getElementById("popupOverlay").style.display = "none";
    document.getElementById("stakePopup").style.display = "none"; 
  }
  closeStakeLP(){
    document.getElementById("popupOverlayLP").style.display = "none";
    document.getElementById("stakeLPPopup").style.display = "none"; 
  }
  closeUnstake(){
    document.getElementById("popupOverlay").style.display = "none";
    document.getElementById("unstakePopup").style.display = "none"
  }
  closeUnstakeLP(){
    document.getElementById("popupOverlayLP").style.display = "none";
    document.getElementById("unstakeLPPopup").style.display = "none"
  }
}

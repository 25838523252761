import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { GetEggComponent } from './pages/get-egg/get-egg.component';
import {NgBootstrapDarkmodeModule} from 'ng-bootstrap-darkmode';
import {HttpClientModule} from '@angular/common/http'
import {FormsModule} from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { CountdownModule } from "ng2-countdown-timer";
import { WinningListComponent } from './pages/winning-list/winning-list.component';
import { WhitePaperComponent } from './pages/white-paper/white-paper.component';
import { TokenomicsComponent } from './pages/tokenomics/tokenomics.component';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { TeamComponent } from './pages/team/team.component';
import { AidropCollectComponent } from './pages/aidrop-collect/aidrop-collect.component';
import { LeaderboardComponent } from './pages/leaderboard/leaderboard.component';
import { MerkleTestComponent } from './pages/merkle-test/merkle-test.component';
import { SplashscreenComponent } from './pages/splashscreen/splashscreen.component';
import { ShareButtonModule } from 'ngx-sharebuttons/button';
import { ShareButtonsConfig } from 'ngx-sharebuttons';
import { DragScrollModule } from 'ngx-drag-scroll';
import { MarketComponent } from './pages/market/market.component';
import { MBSaleComponent } from './pages/mb-sale/mb-sale.component';
import { BinanceNFTComponent } from './pages/binanceNFT/binanceNFT.component';
import { StakingComponent } from './pages/staking/staking.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { EggsComponent } from './pages/dashboard/eggs/eggs.component';
import { KryptomonsComponent } from './pages/dashboard/kryptomons/kryptomons.component';
import { DetailsComponent } from './pages/dashboard/details/details.component';
import { SimpleGlobal } from 'ng2-simple-global';
import { InternalComponent } from './pages/internal/internal.component';
import { ToastrModule } from 'ngx-toastr';
import { GameViewComponent } from './pages/game-view/game-view.component';
import { ClipboardModule } from 'ngx-clipboard';
import { EggHuntListingComponent } from './pages/egg-hunt-listing/egg-hunt-listing.component';
import { KryptomonsComponentV2 } from './pages/dashboard/kryptomonsV2/kryptomonsV2.component';
import { EggsComponentV2 } from './pages/dashboard/eggsV2/eggs.component';
import { DetailsComponentV2 } from './pages/dashboard/detailsV2/details.component';
import { FooterComponent } from './pages/footer/footer.component';



const customConfig: ShareButtonsConfig = {
  twitterAccount: 'KryptomonTeam',
  url: "https://kryptomon.co",
  title: "I'm a Kryptomon trainer!",
  description:"I just got my first Kryptomon egg! Ready to start this adventure...",
  autoSetMeta:true
}

declare global {
  interface Window { analytics: any; }
}


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    GetEggComponent,
    WinningListComponent,
    WhitePaperComponent,
    TokenomicsComponent,
    TeamComponent,
    AidropCollectComponent,
    LeaderboardComponent,
    MerkleTestComponent,
    SplashscreenComponent,
    MarketComponent,
    MBSaleComponent,
    BinanceNFTComponent,
    StakingComponent,
    DashboardComponent,
    EggsComponent,
    KryptomonsComponent,
    DetailsComponentV2,
    EggsComponentV2,
    KryptomonsComponentV2,
    DetailsComponent,
    InternalComponent,
    GameViewComponent,  
    EggHuntListingComponent, FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgBootstrapDarkmodeModule,
    HttpClientModule,
    FormsModule,
    FontAwesomeModule,
    CountdownModule,
    Angulartics2Module.forRoot(),
    ShareButtonModule.withConfig(customConfig),
    DragScrollModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ClipboardModule


  ],
  providers: [SimpleGlobal],
  bootstrap: [AppComponent]
})
export class AppModule {
 constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far, fab);
  }
}

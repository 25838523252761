<div class="section-container-details">
  <div class="card-details" *ngIf="!isWhiteListed && !isCompleted && !isClosed">
    <p>Whitelist your address to take part of the 1st Egg Hunt</p>
    <p class="subtitle">Remember you can only whitelist one address only, be nice and leave space to other players once you have whitelisted your first address.</p>
    <div class="address-connected">
      Address Connected: {{account}}
    </div>
    <div class="egg-action-button" [ngClass]="{'disabled': account=='' || account==undefined}" (click)="whiteList()">
      Whitelist
    </div>
  </div>
  <div class="card-details" *ngIf="isWhiteListed && address_listed==undefined">
     <p>Congratulations! Your address is whitelisted.</p>
  </div>
  <div class="card-details" *ngIf="isWhiteListed && address_listed!==undefined">
     <p>Congratulations! Your address "{{address_listed}}" is whitelisted.</p>
  </div>
  <div class="card-details" *ngIf="!isWhiteListed && isClosed">
     <p>Whitelisting is currently closed, come back later and follow our Announcement Channel to know when it will open again!</p>
  </div>
  <div class="card-details" *ngIf="!isWhiteListed && isCompleted">
     <p>Whitelisting is completed, 20,000 people have been whitelisted for this Egg Hunt! </p>
  </div>
</div>

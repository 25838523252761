<div class="hero">
  <div class="hero-nav">
    <div class="nav-item" (click)="WhitePaper()">
      <p>White Paper</p>
    </div>
       <div class="nav-item" (click)="teamPage()">
      <p>Team</p>
    </div>
    <div class="nav-item">
      <p><a href="https://medium.com/kryptomon">Blog</a></p>
    </div>
    <div class="nav-item social-links">
      <a href="https://t.me/kryptomonofficial">
        <fa-icon [icon]="['fab', 'telegram-plane']"></fa-icon>
      </a>
      <a href="https://medium.com/kryptomon">
        <fa-icon [icon]="['fab', 'medium-m']"></fa-icon>
      </a>
      <a href="https://discord.gg/hYRjSfsWXt">
        <fa-icon [icon]="['fab', 'discord']"></fa-icon>
      </a>
      <a href="https://twitter.com/KryptomonTeam">
        <fa-icon [icon]="['fab', 'twitter']"></fa-icon>
      </a>
       <a href="https://www.reddit.com/r/Kryptomon/">
        <fa-icon [icon]="['fab', 'reddit']"></fa-icon>
      </a>
    </div>
    <!--<div class="nav-item">
        <p>Connect</p>
      </div>-->
  </div>
</div>
<div class="section first-section">
  <div class="row">
    <div class="col text-col">
      <img class="first-img" src="./assets/img/Home/Group 5.svg" (click)="goHome()" />
      <h1>Tokenomics</h1>
      <p>Do you wonder how many $KMON tokens there will be or how our team is using the funds? All you need to know about the Kryptmon $KMON token is on this page.</p>
    </div>
  </div>
</div>
<div class="section card-section">
  <div class="row">
    <div class="col">
      <div class="card-custom">
        <h1>1B</h1>
        <div class="card-item">
          <div class="card-content">
            <p class="title">Total Supply</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card-custom">
        <h1>$0.01</h1>
        <div class="card-item">
          <div class="card-content">
            <p class="title">Listing Price</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card-custom">
        <h1>10K</h1>
        <div class="card-item">
          <div class="card-content">
            <p class="title">Starting available NFTs</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="section allocation">
  <div class="row">
    <div class="col">
      <div class="allocation-content">
        <h1>Allocation</h1>
        <ul>
          <li><b>Pre-Seed Allocation:</b> 50.000.000 @0.0025$ - Locked for 6 months from TGE, then 18 months vesting period - 5,55% Monthly vesting in daily increments.</li>
          <li><b>Seed Allocation:</b> 200.000.000 @0.0033$ - 15% Available at TGE, then 6 months vesting period with a linear daily distribution, max $50k.</li>
          <li><b>Pre-IDO Allocation:</b> 50.000.000 @0.005$ - 20% Available at TGE, then 6 months vesting period with a linear daily distribution, max $50k.</li>
          <li><b>Community Allocation:</b> 20.000.000 @0.005$ - 20% Available at TGE, then 6 months vesting period with a linear daily distribution, max $2.5k.</li>
          <li><b>IDO Allocation:</b> 50.000.000 @0.0066$ - Available at TGE, max 750$ per user.</li>
          <li><b>Liquidity Pool at TGE:</b> 50.000.000 @0.01$ - $500k in BNB locked for 6 months.</li>
          <li><b>Team Allocation:</b> 150.000.000 - Locked for 6 months from TGE, then 1.5 years vesting period - 5,55% Monthly vesting in daily increments.</li>
          <li><b>Advisory Allocation:</b> 30.000.000 - 10% Available at public sale then 2 years vesting period - 3,75% Monthly vesting in daily increments.</li>
          <li><b>Foundation Reserve:</b> 300.000.000 - 5% Available 1 month after public sale, then 2 years vesting period - 3,75% Monthly vesting in daily increments.</li>
          <li><b>Liquidity / Reward Allocation:</b> 100.000.000</li>
        </ul>
      </div>
    </div>
    <div class="col">
      <div id="chartdiv"></div>
    </div>
  </div>
</div>
<div class="section allocation reverse">
  <div class="row">
    <div class="col">
      <div id="chartdiv-use"></div>
    </div>
    <div class="col">
      <div class="allocation-content">
        <h1>Use of Funds</h1>
        <ul>
          <li>40% of the funds will be used to support the development team</li>
          <li>Marketing actions such as community building, community support, partnerships, awareness and go-to market strategy will account for 30% of the total funds raised</li>
          <li>Legal and Accountancy will represent 5% of the total funds raised</li>
          <li>25% of funds will be used to provide liquidity to exchanges</li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="wave-divider-footer">
  <img src="./assets/img/Home/wave.svg" />
</div>
<div class="footer">
  <div class="row">
    <div class="col">
      <img src="./assets/img/Home/Group 5.svg" />
      <p>The Kryptomon Company - 2021</p>
      <a href="https://www.iubenda.com/privacy-policy/20002823" class="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a>
      <a href="https://www.iubenda.com/privacy-policy/20002823/cookie-policy" class="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe " title="Cookie Policy ">Cookie Policy</a>
    </div>
  </div>
</div>
<!--<div class="lottery-banner" [ngClass]="{'close':closeBanner}">
  <div class="close-banner" (click)="toggleBanner()">
     <fa-icon [icon]="['fas', 'chevron-down']" *ngIf="!closeBanner"></fa-icon>
     <fa-icon [icon]="['fas', 'chevron-up']" *ngIf="closeBanner"></fa-icon>
  </div>
  <div class="lottery-text">
    <h1>🎉</h1>
    <h1>Get your free GEN0 Kryptomon</h1>
    <p>To celebrate the upcoming launch of the Kryptomons world we're going to airdrop the first and only 100 Gen0 Kryptmons' Eggs.</p>
    <a href="./#uvembed113534" class="signup-btn" angulartics2On="click" angularticsAction="ClaimYours" angularticsCategory="home">Claim yours!</a>SS
  </div>
</div>-->
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { SimpleGlobal } from "ng2-simple-global";
import { AppComponent } from "../../../app.component";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Location } from "@angular/common";

interface attributesFormatted {
  text;
  id;
  value;
}

interface httpResult {
  status;
  data;
  description;
}

interface elementData {
  typeName
  valueName
  percentage
  image
}

type Genes = {
  fire: number;
  fireTalent: number;
  water: number;
  waterTalent: number;
  ice: number;
  iceTalent: number;
  ground: number;
  groundTalent: number;
  air: number;
  airTalent: number;
  electro: number;
  electroTalent: number;
  ghost: number;
  ghostTalent: number;
  grass: number;
  grassTalent: number;
  color: number;
  sex: number;
  generalTalent: number;
  attack: number;
  defense: number;
  special: number;
  xFactor: number;
  growthTalentFactor: number;
  constitution: number;
  healthPoints: number;
  speed: number;
  affections: number;
  crazyness: number;
  instinct: number;
  hunger: number;
  laziness: number;
  brave: number;
  smart: number;
  bodySize: number;
  ego: number;
  skinType: number;
  generation: number;
};

@Component({
  selector: "app-details",
  templateUrl: "./details.component.html",
  styleUrls: ["./details.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DetailsComponentV2 implements OnInit {
  public kryptomon;
  public account;
  public extendChart;
  public showBreakDown;
  public showTextInput;
  public kryptomonName;
  public oldKryptomonName;
  public attributesFormatted = [
    { text: "Attack", id: 19, value: 0, newIndex: 20 },
    { text: "Defense", id: 20, value: 0, newIndex: 21 },
    { text: "Special", id: 21, value: 0, newIndex: 22 },
    { text: "Speed", id: 26, newIndex: 27 },
    { text: "Constitution", id: 24, newIndex: 25 },
    { text: "Health Points", id: 25, newIndex: 26 },
    { text: "Growth Talent Factor", id: 23, value: 0, newIndex: 24 },
    { text: "Affection", id: 27, newIndex: 28 },
    { text: "Craziness", id: 28, newIndex: 29 },
    { text: "Instinct", id: 29, value: 0, newIndex: 30 },
    { text: "Hunger", id: 30, value: 0, newIndex: 31 },
    { text: "Laziness", id: 31, value: 0, newIndex: 32 },
    { text: "Braveness", id: 32, value: 0, newIndex: 33 },
    { text: "Smartness", id: 33, value: 0, newIndex: 34 },
    { text: "Ego", id: 35, value: 0, newIndex: 36 },
    { text: "Body Size", id: 34, value: 0, newIndex: 35 },
  ];

  public typeFormatted = [
    "Fire",
    "Water",
    "Ice",
    "Ground",
    "Air",
    "Electro",
    "Ghost",
    "Grass",
  ];

  public versionSelected;

  constructor(
    public sg: SimpleGlobal,
    public appComponent: AppComponent,
    public router: Router,
    public http: HttpClient,
    private location: Location
  ) {
    this.kryptomon = this.sg["kryptomonSelected"];
    console.log(this.kryptomon)
    if (!this.kryptomon) {
      this.router.navigate(["/dashboard/eggs"]);
    } else {
      if (this.appComponent.accounts == undefined) {
        this.appComponent.accountsObservable.subscribe((item) => {
          this.account = item[0];
        });
      } else {
        this.account = this.appComponent.accounts[0];
      }

      this.extendChart = false;
      this.showBreakDown = false;
      this.showTextInput = false;
      if (this.kryptomon.id !== '6188') {
        this.parseDna();
      } else {
        this.parseDnaCustom();
      }

    }
    this.versionSelected = [true, false];
  }

  ngOnInit(): void { }

  parseDna() {
    let genes = this.kryptomon.genes;
    for (let i = 0; i < this.attributesFormatted.length; i++) {
      let id = this.attributesFormatted[i].id;
      this.attributesFormatted[i].value = parseInt(genes[id]);
    }

    if (this.kryptomon.extra_data[0] == "1") {
      this.kryptomon.data.unfreezable = "Unfreezable";
    }

    if (genes[22] == "1") {
      this.kryptomon.data.special = "Special";
    } else {
      this.kryptomon.data.special = "Normal";
    }

    if (genes[18] >= 1 && genes[18] <= 5) {
      this.kryptomon.data.sex = "Female";
    } else {
      this.kryptomon.data.sex = "Male";
    }

    let timeBorn = new Date(this.kryptomon.timeBorn * 1000);
    let month = timeBorn.toLocaleString("default", { month: "short" });
    let day = timeBorn.getDate();
    let year = timeBorn.getFullYear();
    this.kryptomon.data.timeBorn = month + " " + day + " " + year;

    var typeDraft = [];
    let c = 0;
    for (let i = 0; i < 8; i++) {
      let sum = parseInt(genes[c]) * parseInt(genes[c + 1]);
      c = c + 2;
      typeDraft.push(sum);
    }

    let elementBreakDown = [];

    for (var i = 0; i < typeDraft.length; i++) {
      let data = {} as elementData
      data.typeName = this.typeFormatted[i];
      data.valueName = typeDraft[i];
      data.image = "./assets/img/icons/" + this.typeFormatted[i].toLowerCase() + ".svg";
      elementBreakDown.push(data);
    }

    let totalSum = 0;
    for (let i = 0; i < elementBreakDown.length; i++) {
      totalSum = totalSum + parseInt(elementBreakDown[i].valueName);
    }

    for (let i = 0; i < elementBreakDown.length; i++) {
      elementBreakDown[i].percentage = Math.round(((elementBreakDown[i].valueName / totalSum) * 100) * 100) / 100
    }

    this.kryptomon.data.elementPercentage = Math.round(((this.kryptomon.data.talent / totalSum) * 100) * 100) / 100
    this.kryptomon.data.elementBreakDown = elementBreakDown;
    this.kryptomonName = this.kryptomon.data.name;
    this.oldKryptomonName = this.kryptomon.data.name;
    this.http
      .get("https://api.kryptomon.co/json/kryptomon/meta/" + this.kryptomon.id)
      .subscribe((item: httpResult) => {
        this.kryptomon.data.bio = item.description;
      });

    console.log(this.kryptomon)
  }

  parseDnaCustom() {
    this.http.get("https://api.kryptomon.co/json/kryptomon/meta/customGlitched.php").subscribe((item: any) => {
      this.attributesFormatted = item.attributes;
      this.kryptomon.data.typeFormatted = item.element
      this.kryptomon.data.iconImage =
        "./assets/img/icons/" + this.kryptomon.data.typeFormatted.toLowerCase() + ".svg";
      this.kryptomon.data.elementPercentage = item.percentage
      this.kryptomon.data.unfreezable = item.unfreezable
      this.kryptomon.data.special = item.special
    })

    this.kryptomon.data.sex = "7b9MZUEAeP";
    let timeBorn = new Date(this.kryptomon.timeBorn * 1000);
    let month = timeBorn.toLocaleString("default", { month: "short" });
    let day = timeBorn.getDate();
    let year = timeBorn.getFullYear();
    this.kryptomon.data.timeBorn = month + " " + day + " " + year;

    this.http
      .get("https://api.kryptomon.co/json/kryptomon/meta/" + this.kryptomon.id)
      .subscribe((item: any) => {
        this.kryptomon.data.bio = item.description;
        this.kryptomon.data.name = item.name;
      });
  }

  async saveName() {

    if (this.kryptomonName !== "" && this.kryptomonName !== undefined && this.kryptomonName != this.oldKryptomonName) {

      var text = "Name my Kryptomon(" + this.kryptomon.id + ") " + this.kryptomonName;

      var msg = await this.appComponent.contractService.web3.utils.utf8ToHex(text)
      var from = this.account;
      var params = [msg, from]
      var method = 'personal_sign'

      var parent = this;
      var name = this.kryptomonName;
      var network = this.appComponent.contractService.networkName;
      var endpoint = this.appComponent.contractService.apiEndPoint;
      var kryptomon = this.kryptomon;

      kryptomon.data.name = name;

      this.appComponent.contractService.web3.currentProvider.sendAsync({
        method,
        params,
        from,
      }, function (err, result) {
        if (err) { kryptomon.data.name = parent.oldKryptomonName; parent.kryptomonName = parent.oldKryptomonName; return console.error(err) }
        if (result.error) { kryptomon.data.name = parent.oldKryptomonName; parent.kryptomonName = parent.oldKryptomonName; return console.error(result.error) }
        console.log('PERSONAL SIGNED:' + JSON.stringify(result.result))

        let post = {
          id: kryptomon.id,
          name: name,
          network: network,
          signature: result.result,
          enable: true
        }
        parent.http.post(endpoint + "/kryptomons-meta/" + post.id + "/name", post).subscribe((response: httpResult) => {
          kryptomon.data.name = name;
          parent.oldKryptomonName = name;
        })
      });



    }
    this.showTextInput = false;
  }

  goBack() {
    this.location.back();
  }

  selectVersion(index) {
    for (var i = 0; i < 2; i++) {
      if (i == index) {
        this.versionSelected[i] = true;
      } else {
        this.versionSelected[i] = false;
      }
    }
    if (index == 1) {
      this.parseResampled();
    } else {
      this.parseDna();
    }
  }

  parseResampled() {

    this.http.get(this.appComponent.contractService + "/kryptomon/" + this.kryptomon.id).subscribe((item: any) => {

      const genes = this.getReorderedGenes(item.genes);

      for (let i = 0; i < this.attributesFormatted.length; i++) {
        let id = this.attributesFormatted[i].id;
        this.attributesFormatted[i].value = parseInt(<any>Object.values(genes)[id]);
      }

      var typeDraft = [];
      let c = 0;
      for (let i = 0; i < 8; i++) {
        let sum = parseInt(<any>Object.values(genes)[c]) * parseInt(<any>Object.values(genes)[c + 1]);
        c = c + 2;
        typeDraft.push(sum);
      }

      console.log(typeDraft)

      let elementBreakDown = [];

      for (var i = 0; i < typeDraft.length; i++) {
        let data = {} as elementData
        data.typeName = this.typeFormatted[i];
        data.valueName = typeDraft[i];
        data.image = "./assets/img/icons/" + this.typeFormatted[i].toLowerCase() + ".svg";
        elementBreakDown.push(data);
      }

      let totalSum = 0;
      for (let i = 0; i < elementBreakDown.length; i++) {
        totalSum = totalSum + parseInt(elementBreakDown[i].valueName);
      }

      let biggestTalent = 0;

      for (let i = 0; i < elementBreakDown.length; i++) {
        elementBreakDown[i].percentage = Math.round(((elementBreakDown[i].valueName / totalSum) * 100) * 100) / 100;
        if (elementBreakDown[i].valueName > biggestTalent) {
          biggestTalent = elementBreakDown[i].valueName;
        }
      }

      this.kryptomon.data.elementPercentage = Math.round(((biggestTalent / totalSum) * 100) * 100) / 100
      this.kryptomon.data.elementBreakDown = elementBreakDown;

    })
  }

  getReorderedGenes(newGenes){
    const genes: Genes = {
      fire: 0,
      fireTalent: 0,
      water: 0,
      waterTalent: 0,
      ice: 0,
      iceTalent: 0,
      ground: 0,
      groundTalent: 0,
      air: 0,
      airTalent: 0,
      electro: 0,
      electroTalent: 0,
      ghost: 0,
      ghostTalent: 0,
      grass: 0,
      grassTalent: 0,
      color: 0,
      sex: 0,
      generalTalent: 0,
      attack: 0,
      defense: 0,
      special: 0,
      xFactor: 0,
      growthTalentFactor: 0,
      constitution: 0,
      healthPoints: 0,
      speed: 0,
      affections: 0,
      crazyness: 0,
      instinct: 0,
      hunger: 0,
      laziness: 0,
      brave: 0,
      smart: 0,
      bodySize: 0,
      ego: 0,
      skinType: 0,
      generation: 0,
    };
    return Object.assign(genes, newGenes);
  }
}
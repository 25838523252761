<div class="newegg-overlay" *ngIf="noWallet">
  <div class="card">
    <div class="card-content" *ngIf="connection_text == 'No wallet'">
      <p>No wallet detected, please install a wallet like <a href="https://metamask.io/download"
          target="_blank">Metamask</a> or Mist and refresh the page once done!</p>
    </div>
    <div class="card-content" *ngIf="connection_text == 'Grant access'">
      <p>We've dected a wallet, but no connection has been established. Please connect a wallet</p>
      <div (click)="showModalConnect()" class="signup-btn marginTop25">Connect</div>
    </div>
    <div class="card-content" *ngIf="connection_text == 'Wrong Network'">
      <p>We've dected a wallet, but you are connected to the wrong network. Please connect to Binance Smartchain
        Mainnet.</p>
    </div>
  </div>
</div>
<div class="newegg-overlay" *ngIf="showShare">
  <div id="tsparticles"></div>
  <div class="card">
    <div class="close">
      <fa-icon [icon]="['fas', 'times-circle']" (click)="closeShare()"></fa-icon>
    </div>
    <div class="card-content" *ngIf="!collectedKryptomon">
      <img src="./assets/img/Home/{{modalImage}}" class="egg-pic" />
      <div class="modal-text">
        <p>{{modalTitle}}</p>
        <p>{{modalContent}}</p>
        <div (click)="doAction()" *ngIf="!isLoadingBuy" class="signup-btn">{{modalCta}}</div>
        <div class="lds-ripple" *ngIf="isLoadingBuy">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
    <div class="card-content" *ngIf="collectedKryptomon">
      <img src="{{kryptomons[0].data.image}}" class="egg-pic" />
      <p>Congratulation Trainer!</p>
      <p>You got your first Kryptomon Egg 🎉</p>
      <p>Share your Kryptomon egg with the world</p>
      <div class="share-buttons">
        <share-button button="twitter" url="https://kryptomon.co"
          description="Here is my Kryptomon Egg! 😍 A new adventure is about to begin and you? Do you want to be a part of it? Join the telegram group to find out how 🎉 https://t.me/kryptomonofficial #Kryptomon">
        </share-button>
        <share-button button="reddit" url="https://kryptomon.co" title="I finally got my Kryptomon's egg!"
          description="Here is my Kryptomon Egg! 😍 A new adventure is about to begin and you? Do you want to be a part of it? Join the telegram group to find out how 🎉 https://t.me/kryptomonofficial #Kryptomon">
        </share-button>
        <share-button button="facebook" url="https://kryptomon.co" title="I finally got my Kryptomon's egg!"
          description="Here is my Kryptomon Egg! 😍 A new adventure is about to begin and you? Do you want to be a part of it? Join the telegram group to find out how 🎉 https://t.me/kryptomonofficial #Kryptomon">
        </share-button>
      </div>
      <p class="marginTop25">And now?</p>
      <div (click)="gotoDashboard()" class="signup-btn">Go to Dashboard</div>
    </div>
  </div>
</div>
<div class="row content_display dashboard">
  <div class="col">
    <div class="card" [ngClass]="{'disabled': disabled[0]}" (click)="showModal('airdrop')">
      <div class="card-content">
        <img src="./assets/img/Home/coin.png" style="margin: 20px;"/>
        <p>Physital NFT Campaign</p>
      </div>
    </div>
    <div class="card card-error" *ngIf="disabled[0]">
      <div class="card-content">
        <span>No airdrop to claim</span>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card" [ngClass]="{'disabled': availableCryptoComClaims.length == 0}" (click)="claimCryptoCom()">
      <div class="card-content">
        <img src="./assets/img/Home/crypto-com-coin-cro-logo.png" style="padding: 20px"/>
        <p>Crypto.com</p>
        <p *ngIf="availableCryptoComClaims.length > 0">{{availableCryptoComClaims.length}} NFTs</p>
      </div>
    </div>
    <div class="card card-error" *ngIf="availableCryptoComClaims.length == 0">
      <div class="card-content">
        <span>Nothing to claim</span>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card" [ngClass]="{'disabled': disabled[2]}" (click)="showModal('sale')">
      <div class="card-content">
        <img src="./assets/img/Home/egg-hunt.png" />
        <p>Egg Hunt KMON coin Airdrop</p>
      </div>
    </div>
    <div class="card card-error" *ngIf="disabled[2]">
      <div class="card-content">
        <span>Nothing to claim</span>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card" [ngClass]="{'disabled': disabled[3]}" (click)="claimHunt()">
      <div class="card-content">
        <img src="./assets/img/Home/egg-hunt.png" />
        <p>Treasure Hunt Egg Drop</p>
      </div>
    </div>
    <div class="card card-error" *ngIf="disabled[3]">
      <div class="card-content">
        <span>Nothing to claim</span>
      </div>
    </div>
  </div>
</div>
<div class="section-container-inside">
  <div class="title" *ngIf="!fetchKryptomon">
    Loading...
  </div>
  <div class="title" *ngIf="kryptomons.length>0 && fetchKryptomon">
    Kryptomons
  </div>
  <div class="title" *ngIf="kryptomons.length==0 && fetchKryptomon">
    Feels like a desert...
  </div>
  <div class="small migration-note" *ngIf="kryptomons.length > 0">
    Migration is required to continue accessing the game and breeding
  </div>
  <div class="egg-action-button" (click)="migrateKryptomons()" *ngIf="kryptomons.length > 0 && !isLoading">
    Migrate Kryptomons to V2
  </div>
  <div class="lds-ripple" *ngIf="isLoading">
    <div></div>
    <div></div>
  </div>
  <div class="egg-list">
    <div class="egg-card" *ngFor="let k of kryptomons">
      <div class="egg-card-image">
        <img src="{{ k.data.image }}" />
      </div>
      <div class="egg-card-content">
        <div class="egg-id-element">
          <p>No. {{ k.data.id }}</p>
          <img src="{{ k.data.iconImage }}" />
        </div>
        <div class="egg-gen-element">
          <p>GEN: {{ k.data.generation }}</p>
          <p>ELEMENT: {{ k.data.typeFormatted }}</p>
        </div>
        <!--<div
          class="egg-action-button"
          (click)="openDetails('kryptomon', k.data.id, k)"
        >
          Genome
        </div>-->
        <div class="egg-action-button" (click)="migrateKryptomon(k.data.id)" *ngIf="kryptomons.length > 0 && !isLoading">
          Migrate
        </div>
      </div>
    </div>
    <div
      class="egg-card no-eggs"
      *ngIf="kryptomons.length == 0 && fetchKryptomon"
    >
      <div class="egg-card-image">
        <img src="./assets/img/Home/cactus.png" />
      </div>
      <div class="egg-card-content">
        <div class="egg-id-element">
          <p>You don't have any Kryptomon yet!</p>
        </div>
        <div class="egg-action-button extend" (click)="goMarketplace()">
          Marketplace
        </div>
      </div>
    </div>
  </div>
</div>

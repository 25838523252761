<div class="row section">
  <div class="logo">
    <img class="first-img" src="./assets/img/Home/Group 5.svg" (click)="goHome()" />
  </div>
    <div id="tsparticles"></div>
  <div class="col">
    <div class="card">
      <div class="form">
        <div class="form_img">
          <img src="./assets/img/Fire/super.png" />
        </div>
        <div *ngIf="!validEmail && !changeForm">
          <p>The whitelisting for the EggDrop has been closed on the 30th of July at 17:00CET! Congrats to all that made in on time and collect their eggs :)</p>
          <!--<p>Insert your Email</p>
          <input type="text" placeholder="your@email.com" [(ngModel)]="email" />
          <div class="send_button" (click)="checkEmail()" *ngIf="!loading">
            <fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon>
          </div>-->
        </div>
        <div class="tokenDiv" *ngIf="(validEmail && !successRegister)">
          <p class="margin25">Insert your Binance Smart Chain wallet address (once listed address CANNOT BE CHANGED)</p>
          <input type="text" placeholder="0X000...." [(ngModel)]="address" />
          <div class="send_button" (click)="saveAddress()" *ngIf="!loading">
            <fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon>
          </div>
        </div>
        <div class="changeForm" *ngIf="changeForm && !successChange">
           <p class="margin25">Insert your new Binance Smart Chain wallet address</p>
          <input type="text" placeholder="0X000...." [(ngModel)]="newAddress" />
          <div class="send_button" (click)="updateAddress()" *ngIf="!loading">
            <fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon>
          </div>
        </div>
        <div *ngIf="successRegister && !saleEgg && !successChange">
          <p>Great! Your address have been saved, we will whitelist this for the Aidrop and for the Egg Sale!</p>
          <p>Remember Airdrop is open, but you will be able to claim your egg starting from next week!</p>
        </div>

        <div *ngIf="successRegister && saleEgg && !successChange">
          <p>Great! Your address have been saved, we will whitelist this for the Egg Sale!</p>
          <p>Remember the sale will open on the in July! Stay tuned on our Telegram or Discord for more info.</p>
        </div>

        <div *ngIf="successChange">
          <p>Great! Your address have been updated, we will whitelist this for the Egg Sale!</p>
          <p>Remember the sale will open on the in July! Stay tuned on our Telegram or Discord for more info.</p>
        </div>
        <!--<div *ngIf="invalidToken && !loading">
          <p>Invalid token or it have been already used.</p>
        </div>-->
        <div *ngIf="loading">
          <div class="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
    <div class="card extended-card" [ngClass]="{'show':showExtendedCard || wrongRegister}" *ngIf="wrongRegister && !loading">
        <div class="card-content">
          <div class="header-card">
            <p>{{errorMsg}}</p>
          </div>
        </div>
    </div>
    <div class="card extended-card-due" [ngClass]="{'show':(showExtendedCard || wrongRegister) && validChange}">
      <div class="card-content">
          <div class="header-card">
            <p>This address is also whitelisted for the Egg Sale, do you want to change it? </p>
            <p>Remember is possible to change the address before the egg sale opens, once the egg sale is open YOU CANNOT CHANGE THE ADDRESS ANYMORE!</p>
             <div class="signup-btn" (click)="showChange()">Change address</div>
          </div>
        </div>
    </div>
  </div>
</div>

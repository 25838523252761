import { Component, OnInit, ViewEncapsulation, AfterViewInit, NgZone, PLATFORM_ID, Inject } from '@angular/core';
import { ContractService } from '../../services/contract.service';
import { SparksService } from '../../services/sparks.service';
import { ActivatedRoute } from '@angular/router'
import { isPlatformBrowser } from '@angular/common';
import { ThemeService } from 'ng-bootstrap-darkmode';
import { HttpClient } from '@angular/common/http';
import * as merkle_mb_stage1 from './merkle-mb-stage1.json';
import * as merkle_mb_stage2 from './merkle-mb-stage2.json';
import { Router } from '@angular/router'
import { AppComponent } from 'src/app/app.component';
import { fetchJson } from '@ethersproject/web';
import { BigNumber, utils } from 'ethers';

declare const tsParticles;

const isEmpty = (obj) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key))
      return true;
  }
  return false;
}

interface httpResult {
  status
  data
  description
}

interface Kryptomons {
  image
  type
  talent
  typeFormatted
  xfactor
  speciality
  timeBorn
  super
  personality
  generation
  id
  islandImage
  timeBornUnix
  unfreezable
  bio
}

interface Personality {
  Constitution
  Affection
  Crazyness
  Instinct
  Hunger
  Laziness
  Braveness
  Smart
  Ego
  generation
}


@Component({
  selector: 'app-mb-sale',
  templateUrl: './mb-sale.component.html',
  styleUrls: ['./mb-sale.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MBSaleComponent implements OnInit {


  public connection_text
  public noWallet
  public accounts
  public disabled
  public whitelisted
  public soldOut
  public claimed
  public claims
  public listedOnDb
  public notListed
  public showShare
  public modalImage
  public modalTitle
  public modalContent
  public modalCta
  public isLoadingBuy
  public actionTodo
  public kryptomons
  public typeFormatted = ["Fire", "Water", "Ice", "Ground", "Air", "Electro", "Ghost", "Grass"];
  public collectedKryptomon
  public isClosed
  public airdropClaim
  public availableRewards = []
  public availableCryptoComClaims = []
  public sales;
  public maxSalesAvailable;
  public mbSold;
  public purchaseTokenBalance;
  public purchaseTokenName;
  public price;
  public authorized;
  public isLoadingAuthorize;
  public ended;
  public utils;

  constructor(public appComponent: AppComponent, public contractService: ContractService, public http: HttpClient, public router: Router) {

    this.disabled = [true, true, true]
    this.soldOut = [false, false, false]
    this.claimed = [false, false, false];
    this.claims = {};
    this.whitelisted = [true, true, true];
    this.notListed = false;
    this.showShare = false;
    this.isLoadingBuy = false;
    this.kryptomons = []
    this.collectedKryptomon = false;
    this.isClosed = false;
    this.airdropClaim = null;
    this.authorized = true;
    this.ended = false;
    this.isLoadingAuthorize = false;
    this.utils = utils;

    if (this.appComponent.accounts == undefined) {
      this.appComponent.accountsObservable.subscribe((item) => {
        this.noWallet = false;
        //console.log("quauauau")
        this.getAccounts().then(async () => {
          this.init();
        });
      });
    } else {
      this.noWallet = false;
      //console.log("quauauau")
      this.getAccounts().then(async () => {
        this.init();
      });
    }

    //this.checkClose()
  }

  async getAccounts() {
    this.accounts = await this.contractService.web3.eth.getAccounts().then((accounts) => {
      //console.log(accounts)
      return accounts;
    }).catch((err) => {
      ////console.log(err, 'err!!');
    });
    if (this.accounts.length == 0) {
      this.connection_text = "Grant access";
    } else {
      this.connection_text = "Connected";
    }
  }

  ngOnInit(): void {
  }

  async init() {
    this.purchaseTokenName = await this.contractService.contractMBPurchaseToken.methods.name().call();
    this.price = await this.contractService.contractMerkleMB.methods.price().call();
    const allowance = await this.contractService.contractMBPurchaseToken.methods.allowance(this.accounts[0], this.contractService.contractMerkleMB._address).call();
    this.authorized = parseInt(allowance) >= parseInt(this.contractService.web3.utils.toWei('9999999999'));
    this.refresh();
    setInterval(() => {
      this.refresh();
    }, 5000)
  }

  refresh() {
    this.updateSales();
    this.checkClaim(0);
    // this.checkClaim(1);
    // this.checkClaim(2);
  }

  async updateSales() {
    this.sales = await this.contractService.contractMerkleMB.methods.getSaleInfos().call();
    this.maxSalesAvailable = await this.contractService.contractMerkleMB.methods.maxSales().call();
    this.mbSold = await this.contractService.contractMerkleMB.methods.mbPurchased().call();
    this.purchaseTokenBalance = await this.contractService.contractMBPurchaseToken.methods.balanceOf(this.accounts[0]).call();
  }

  async checkClaim(stage) {
    let claim;

    return;

    if (stage == 0) {
      claim = merkle_mb_stage1.default.claims[this.contractService.web3.utils.toChecksumAddress(this.accounts[0])];
      if (claim) {
        claim.account = this.accounts[0];
        this.claims[stage] = claim;
        this.whitelisted[stage] = true;
      }
    }
    else if (stage == 1) {
      try{
        claim = await fetchJson(this.appComponent.contractService.apiEndPoint + '/airdrop/' + this.accounts[0]);
      }
      catch{

      }
      if (claim) {
        claim.account = claim.address;
        this.claims[stage] = claim;
        this.whitelisted[stage] = true;
      }
    }
    else {
      return;
    }

    if (claim == undefined) {
      this.disabled[stage] = true;
      this.whitelisted[stage] = false;
    } else {
      // check if the claim has been completed
      let isClaimed = false;
      if (claim != undefined)
        isClaimed = await this.isClaimed(claim, this.contractService.contractMerkleMB, stage);

      if (isClaimed) {
        this.claimed[stage] = true;
      }
      else {
        this.claimed[stage] = false;
      }
    }

  }

  async isClaimed(claim, merkleInstance, stage) {
    return await merkleInstance.methods.isClaimed(claim.index, stage).call();
  }

  async getClaim() {
    try {
      this.airdropClaim = await fetchJson(this.appComponent.contractService.apiEndPoint + '/airdrop/' + this.accounts[0]);
    }
    catch {
      this.airdropClaim = undefined;
    }
  }

  async buyMysteryBox(stage) {
    if(this.isLoadingBuy)
      return;

    if(this.getDisabledMessage(stage) != "")
      return;

    this.isLoadingBuy = true;
    const buyClaim = this.claims[stage];

    if (buyClaim !== undefined && stage < 2) {
      this.processBuyClaim(buyClaim, this.contractService.contractMerkleMB, stage);
    }
    else {
      this.processBuyClaim({ index: 0, account: this.accounts[0], proof: []}, this.contractService.contractMerkleMB, stage);
    }
  }

  async authorizePaymentToken() {
    this.isLoadingAuthorize = true;
    this.contractService.contractMBPurchaseToken.methods.approve(this.contractService.contractMerkleMB._address, this.contractService.web3.utils.toWei('999999999999')).send({
      from: this.accounts[0]
    }).then((result)=> {
      this.authorized = true;
    }).finally(() => {
      this.isLoadingAuthorize = false;
    })
  }

  async processBuyClaim(claim, contractMerkleInstance, stage) {
    if (claim !== undefined) {
      let allowance = await this.contractService.contractMBPurchaseToken.methods.allowance(this.accounts[0], contractMerkleInstance._address).call();
      if (allowance >= this.price) {
        this.executeClaim(claim, contractMerkleInstance, stage);
      }
      else {
        this.contractService.contractMBPurchaseToken.methods.approve(contractMerkleInstance._address, this.contractService.web3.utils.toWei('999999999999')).send({
          from: this.accounts[0]
        }).then((result) => {
          if (result.events.Approval !== undefined) {
            this.authorized = true;
            this.executeClaim(claim, contractMerkleInstance, stage);
          } else {
            this.isLoadingBuy = false;
          }
        }, (error) => {
          //console.log(error)
          this.isLoadingBuy = false;
        })
      }
    } else {
      //console.log('notlisted')
      this.isLoadingBuy = false;
      this.notListed = true;
    }
  }

  getDisabledMessage(stage) {
    const sale = this.sales[stage];

    if (parseInt(this.mbSold) >= parseInt(this.maxSalesAvailable)) {
      this.ended = true;
      return "Sold out!";
    }

    if (sale.maxSales > 0) {
      if (parseInt(sale.saleCount) >= parseInt(sale.maxSales)) {
        return "Sold out!";
      }
    }

    if (this.getDateFromEpoch(sale.endTime) < new Date()) {
      if(stage == 0){
        this.ended = true;
      }
      return "Sale ended";
    }

    if (this.claimed[stage]) {
      return "Already claimed";
    }

    if (!this.whitelisted[stage]) {
      return "You're not whitelisted"
    }

    if (this.getDateFromEpoch(sale.startTime) > new Date()) {
      return "Starting at " + this.getDateFromEpoch(sale.startTime, true).toLocaleString();
    }

    if(this.purchaseTokenBalance < this.price){
      return "Not enough " + this.purchaseTokenName;
    }
    
    if (this.getDateFromEpoch(sale.endTime) > new Date() && this.getDateFromEpoch(sale.startTime) < new Date()) {
      // ongoing sale
      // check if claimed
      // check if sold out
      
    }

    return "";
  }

  getDateFromEpoch(epochString, forDisplay = false) {
    let dateInUnix = parseInt(epochString);
    if(forDisplay)
      return new Date((dateInUnix) * 1000);
    else
      return new Date((dateInUnix + 10) * 1000);
  }

  async processClaim(claim, contractMerkleInstance, stage) {
    if (claim !== undefined) {
      let allowance = await this.contractService.contractTokenInstance.methods.allowance(this.accounts[0], contractMerkleInstance._address).call();
      if (allowance > 0) {
        this.executeClaim(claim, contractMerkleInstance, stage);
      }
      else {
        this.contractService.contractTokenInstance.methods.approve(contractMerkleInstance._address, this.contractService.web3.utils.toWei('999999999999')).send({
          from: this.accounts[0]
        }).then((result) => {
          if (result.events.Approval !== undefined) {
            this.executeClaim(claim, contractMerkleInstance, stage);
          } else {
            this.isLoadingBuy = false;
          }
        }, (error) => {
          //console.log(error)
          this.isLoadingBuy = false;
        })
      }

    } else {
      //console.log('notlisted')
      this.isLoadingBuy = false;
      this.notListed = true;
    }
  }

  async executeClaim(claim, contractMerkleInstance, stage) {
    if (claim !== undefined) {
      this.notListed = false;
      await contractMerkleInstance.methods.buy(claim.index, claim.account, claim.proof, stage).send({
        from: this.accounts[0]
      }).then((receipt) => {
        //console.log(receipt);
        this.isLoadingBuy = false;
        this.refresh();
        alert("You've purchased your bundle!");
      }).catch((err) => {
        ////console.log(err, 'err');
        this.isLoadingBuy = false;
      });
    } else {
      //console.log('notlisted')
      this.isLoadingBuy = false;
      this.notListed = true;
    }
  }

  showModalConnect() {
    console.log('connect')
    this.contractService.mount().then((data) => {
      console.log('data')
      console.log(data)
      if (data !== 'nomask' && data !== 'denied_access' && data !== 'wrong_network') {
        //console.log("quauauau")
        this.noWallet = false;
        this.getAccounts().then(() => { });
      } else {
        console.log('wut')
        if (data == "nomask") {
          this.noWallet = true;
          this.connection_text = "No wallet"
        } else if (data == "denied_access") {
          this.noWallet = true;
          this.connection_text = "Grant access"
          //console.log("qua!=")
        } else {
          this.noWallet = true;
          this.connection_text = "Wrong Network"
        }
      }
    }, (error) => {
      console.log('cia')
    });
  }

  indexOfMax(arr) {
    if (arr.length === 0) {
      return -1;
    }

    var max = arr[0];
    var maxIndex = 0;

    for (var i = 1; i < arr.length; i++) {
      if (arr[i] > max) {
        maxIndex = i;
        max = arr[i];
      }
    }

    return maxIndex;
  }

  closeShare() {
    this.showShare = false;
    this.collectedKryptomon = false;
  }

  connectDisconnectProvider() {
    if (this.connection_text == "Connected") {
      this.contractService.disconnectProvider();
    } else {
      this.showModalConnect();
    }
  }

  startConfetti() {
    tsParticles.load("tsparticles", {
      fpsLimit: 60,
      particles: {
        number: {
          value: 0
        },
        color: {
          value: ["#00FFFC", "#FC00FF", "#fffc00"]
        },
        shape: {
          type: "confetti",
          options: {
            confetti: {
              type: ["circle", "square"]
            }
          }
        },
        opacity: {
          value: 1,
          animation: {
            enable: true,
            minimumValue: 0,
            speed: 2,
            startValue: "max",
            destroy: "min"
          }
        },
        size: {
          value: 7,
          random: {
            enable: true,
            minimumValue: 3
          }
        },
        links: {
          enable: false
        },
        life: {
          duration: {
            sync: true,
            value: 5
          },
          count: 1
        },
        move: {
          enable: true,
          gravity: {
            enable: true,
            acceleration: 20
          },
          speed: 20,
          decay: 0.1,
          direction: "none",
          random: false,
          straight: false,
          outModes: {
            default: "destroy",
            top: "none"
          }
        }
      },
      interactivity: {
        detectsOn: "window",
        events: {
          resize: true
        }
      },
      detectRetina: true,
      background: {
        color: "#232123"
      },
      emitters: {
        direction: "none",
        life: {
          count: 0,
          duration: 0.1,
          delay: 0.4
        },
        rate: {
          delay: 0.1,
          quantity: 100
        },
        size: {
          width: 0,
          height: 0
        }
      }
    });
  }

  gotoDashboard() {
    this.router.navigate(['/']);
  }

}

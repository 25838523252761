import { Injectable } from '@angular/core';
declare const window

@Injectable({
  providedIn: 'root'
})
export class SparksService {

  constructor() {}

}
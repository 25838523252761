<nav>
  <div class="logo" routerLink="/">
    Kryptomon
  </div>
  <div class="metaconnection" (click)="connectDisconnectProvider()">
    <div class="button-connection">
    <div class="bollino" [ngClass]="{'red': (connection_text == 'No wallet' || connection_text == undefined), 'green': connection_text == 'Connected', 'yellow': (connection_text == 'Wrong Network' || connection_text == 'Grant access')}"></div>
    <div class="connection_text" *ngIf="connection_text!==undefined">{{connection_text}}</div>
    <div class="connection_text" *ngIf="connection_text==undefined">No wallet</div>
  </div>
  </div>
  <div class="share-buttons hideMobile">
        <share-button button="twitter" url="https://kryptomon.co" description="Here is my Kryptomon Egg! 😍 A new adventure is about to begin and you? Do you want to be a part of it? Join the telegram group to find out how 🎉 https://t.me/kryptomonofficial #Kryptomon"></share-button>
        <share-button button="reddit" url="https://kryptomon.co" title="I finally got my Kryptomon's egg!" description="Here is my Kryptomon Egg! 😍 A new adventure is about to begin and you? Do you want to be a part of it? Join the telegram group to find out how 🎉 https://t.me/kryptomonofficial #Kryptomon"></share-button>
        <share-button button="facebook" url="https://kryptomon.co" title="I finally got my Kryptomon's egg!" description="Here is my Kryptomon Egg! 😍 A new adventure is about to begin and you? Do you want to be a part of it? Join the telegram group to find out how 🎉 https://t.me/kryptomonofficial #Kryptomon"></share-button>
        <a href="https://app.mochi.market/token/56/0xC33d69a337B796A9f0F7588169cd874C3987BDE9/{{selectedKryptomon.id}}/null" target="_blank"><div class="sb-wrapper sb-show-icon sb-mochi sb-button"><img src="./assets/img/team/mochi.png"></div></a>
      </div>
  <ngbd-theme-switch style="icon" size="sm"></ngbd-theme-switch>
</nav>
<!--<div class="newegg-overlay" *ngIf="kryptomons.length>0 && showShare">
  <div id="tsparticles"></div>
  <div class="card">
    <div class="close">
      <fa-icon [icon]="['fas', 'times-circle']" (click)="closeShare()"></fa-icon>
    </div>
    <div class="card-content">
      <img src="{{selectedKryptomon.data.image}}" class="egg-pic" />
      <p>Congratulation Trainer!</p>
      <p>You got your first Kryptomon Egg 🎉</p>
      <p>Share your Kryptomon egg with the world</p>
      <div class="share-buttons">
        <share-button button="twitter" url="https://kryptomon.co" description="Here is my Kryptomon Egg! 😍 A new adventure is about to begin and you? Do you want to be a part of it? Join the telegram group to find out how 🎉"></share-button>
        <share-button button="reddit" url="https://kryptomon.co" title="I finally got my Kryptomon's egg!" description="Here is my Kryptomon Egg! 😍 A new adventure is about to begin and you? Do you want to be a part of it? Join the telegram group to find out how 🎉"></share-button>
        <share-button button="facebook" url="https://kryptomon.co" title="I finally got my Kryptomon's egg!" description="Here is my Kryptomon Egg! 😍 A new adventure is about to begin and you? Do you want to be a part of it? Join the telegram group to find out how 🎉"></share-button>

      </div>
    </div>
  </div>
</div>-->
<div class="loading" *ngIf="isLoading">Wait a bit...</div>
<div class="content_display height100">
  <!--<div class="disclaimer" *ngIf="showDisclaimer">
    <p>This is a test only version, the eggs you obtain here are fake and can be obtain with fake BNBs - These eggs have no value.</p>
    <fa-icon [icon]="['fas', 'times-circle']" (click)="closeDisclaimer()"></fa-icon>
  </div>-->
  <div class="row centerAlignVertical empty" *ngIf="kryptomons.length==0 && connection_text == 'Connected' && !signupForm">
    <div class="col flex centerAlignHorizontal" [ngClass]="{'not-listed-row': notListed}">
      <div class="card">
        <div class="card-content">
          <img *ngIf="!isLoadingBuy" class="desert" src="./assets/img/Home/cactus.png"/>
          <p *ngIf="!isLoadingBuy">Feels like a desert.</p>
          <p *ngIf="isLoadingBuy">Genetic code is processing...</p>
          <div (click)="gotToMarket()" class="signup-btn">Get your egg!</div>
          <!--<div class="lds-ripple" *ngIf="isLoadingBuy && !notListed">
            <div></div>
            <div></div>
          </div>-->
        </div>
        <!--<div class="card-content not-listed" *ngIf="notListed">
          <img src="./assets/img/Home/notfound.png" />
          <p *ngIf="!listedOnDb">Sorry, this wallet address is not whitelisted.</p>
          <p *ngIf="listedOnDb">All good, but you need to wait for the 15th July.</p>
        </div>-->
      </div>
      <!--<div class="card not-listed-card" *ngIf="notListed && !listedOnDb">
        <div class="card-content not-listed">
          <p>If you are part of the airdrop list and havent whitelisted your address yet, you can do it by clicking the button below.</p>
          <div (click)="gotoDrop()" class="signup-btn">Whitelist your address!</div>
        </div>
      </div>
      <div class="card not-listed-card" *ngIf="notListed && listedOnDb">
        <div class="card-content not-listed">
          <p>Your address have been successfully whitelisted, but not yet uploaded on the Blockchain, come back on the 15th of July to collect your egg!</p>
        </div>
      </div>-->
    </div>
  </div>
  <div class="row centerAlignVertical empty" *ngIf="connection_text == 'No wallet' || connection_text == 'Grant access' || connection_text == 'Wrong Network'">
    <div class="col flex centerAlignHorizontal">
      <div class="card">
        <div class="card-content" *ngIf="connection_text == 'No wallet'">
          <p>No wallet detected, please install a wallet like <a href="https://metamask.io/download" target="_blank">Metamask</a> or TrustWallet and refresh the page once done!</p>
        </div>
        <div class="card-content" *ngIf="connection_text == 'Grant access'">
          <p>We've dected a wallet, but no connection has been established. Please connect a wallet</p>
          <div (click)="showModalConnect()" class="signup-btn">Connect</div>
        </div>
        <div class="card-content" *ngIf="connection_text == 'Wrong Network'">
          <p>We've dected a wallet, but you are connected to the wrong network. Please connect to Binance Smartchain Mainnet.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row centerAlignVertical empty formSignup" *ngIf="signupForm">
    <div class="col flex centerAlignHorizontal">
      <div class="card">
        <div class="card-content">
          <div class="header-card">
            <p>Create Account</p>
          </div>
          <div class="form-card" *ngIf="!signupLoading">
            <p>Your email address is only used to send you important updates. Your nickname is how other Kryptomon players will identify you.</p>
            <div class="form">
              <div class="input-item">
                <input type="email" placeholder="Your email address" [(ngModel)]="email" (ngModelChange)="checkEmail()" [ngClass]="{'shake':emailAlreadyUsed}" #emailValid="ngModel" required />
                <fa-icon [icon]="['fas', 'times-circle']" *ngIf="emailAlreadyUsed"></fa-icon>
              </div>
              <div class="input-item">
                <input type="text" placeholder="Nickname" [(ngModel)]="nickname" (ngModelChange)="checkNickname()" [ngClass]="{'shake':nicknameAlreadyUsed}" #nickValid="ngModel" required minlength="3" />
                <fa-icon [icon]="['fas', 'times-circle']" *ngIf="nicknameAlreadyUsed"></fa-icon>
              </div>
              <div class="button" (click)="signup()" [ngClass]="{'disabled' :  emailValid.errors?.required || nickValid.errors?.minlength || nickValid.errors?.required || nicknameAlreadyUsed || emailAlreadyUsed}">
                Continue
              </div>
            </div>
          </div>
          <div class="loadingSignup" *ngIf="signupLoading">
            <div class="lds-ripple">
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      <div class="card extended-card" [ngClass]="{'show':showExtendedCard}">
        <div class="card-content">
          <div class="header-card">
            <p>{{alreadyUsedText}} already in use.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row dashboard" *ngIf="kryptomons.length>0">
    <div class="col-lg-4 egg-list">
      <div class="share-buttons showMobile">
        <share-button button="twitter" url="https://kryptomon.co" description="Here is my Kryptomon Egg! 😍 A new adventure is about to begin and you? Do you want to be a part of it? Join the telegram group to find out how 🎉 https://t.me/kryptomonofficial #Kryptomon"></share-button>
        <share-button button="reddit" url="https://kryptomon.co" title="I finally got my Kryptomon's egg!" description="Here is my Kryptomon Egg! 😍 A new adventure is about to begin and you? Do you want to be a part of it? Join the telegram group to find out how 🎉 https://t.me/kryptomonofficial #Kryptomon"></share-button>
        <share-button button="facebook" url="https://kryptomon.co" title="I finally got my Kryptomon's egg!" description="Here is my Kryptomon Egg! 😍 A new adventure is about to begin and you? Do you want to be a part of it? Join the telegram group to find out how 🎉 https://t.me/kryptomonofficial #Kryptomon"></share-button>
        <a href="https://app.mochi.market/token/56/0xC33d69a337B796A9f0F7588169cd874C3987BDE9/{{selectedKryptomon.id}}/null" target="_blank"><div class="sb-wrapper sb-show-icon sb-mochi sb-button"><img src="./assets/img/team/mochi.png"></div></a>
      </div>
      <div class="card">
        <div class="badge purple" *ngIf="isTopSupporter">
          <div class="circle">10K</div>
          <div class="ribbon">Top Supporter</div>
        </div>
        <div class="badge gold" *ngIf="isTeamMember">
          <div class="circle">KMN</div>
          <div class="ribbon">Founding Team</div>
        </div>
        <!--[ngClass]="{'shake':shake}"-->
        <div class="egg-image">
          <img src="{{selectedKryptomon.data.image}}" class="egg-pic" />
          <div class="plateu">
            <img class="shadow-image" src="./assets/img/Home/shadow.png" />
            <img class="ground-image" src="{{selectedKryptomon.data.islandImage}}" />
          </div>
          <div class="containerBubble" *ngIf="selectedKryptomon.data.super">
            <div class="bubble-wrap">
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
              <div class="bubble"></div>
            </div>
          </div>
          <!--<canvas id="canvas3d" width="450" height="450"></canvas>-->
        </div>
        <div class="egg-image-label">
          <p>Laid</p>
          <p>{{selectedKryptomon.data.timeBorn}}</p>
        </div>
      </div>
      <div class="card egg-card-selector">
        <drag-scroll class="egg-selector">
          <div drag-scroll-item class="egg-item" *ngFor="let kryptomon of kryptomons; let i = index" (click)="selectKryptomon(i)" [ngClass]="{'selected': kryptomon.selected}">
            <!--<canvas id="canvas3d" class="canvas3d" width="450" height="450"></canvas>-->
            <img src="{{kryptomon.data.image}}" />
          </div>
          <div class="egg-item add" (click)="gotToMarket()">
            <h1 *ngIf="!isLoadingBuy">+</h1>
            <div class="lds-ripple" *ngIf="isLoadingBuy">
              <div></div>
              <div></div>
            </div>
          </div>
        </drag-scroll>
      </div>
    </div>
    <div class="col-lg-8 egg-display">
      <div class="card-block">
      <div class="card">
        <p class="showMobile">Element Genetic</p>
        <div id="chartdiv" style="width: 100%; height: 500px"></div>
      </div>
      <div class="card">
        <p class="showMobile">Personality Genetic</p>
        <div id="chartdiv_personality" style="width: 100%; height: 500px"></div>
      </div>
    </div>
    <div class="card dex-card">
      <div class="dex-img">
        <img src="./assets/img/Home/dex.png" />
      </div>
      <div class="dex-content">
        <p>KryptoScanner</p>
        <p>{{selectedKryptomon.bio}}</p>
      </div>
    </div>
    </div>
  </div>
</div>

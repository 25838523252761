<div class="section-container-inside">
  <div class="title" *ngIf="!fetchKryptomon">
    Loading...
  </div>
  <div class="title" *ngIf="kryptomons.length>0 && fetchKryptomon">
    Kryptomons
  </div>
  <div class="title" *ngIf="kryptomons.length==0 && fetchKryptomon">
    Feels like a desert...
  </div>
  <div class="egg-list">
    <div class="egg-card" *ngFor="let k of kryptomons; let i = index">
      <div class="egg-card-image">
        <img src="{{ k.data.image }}" />
      </div>
      <div class="egg-card-content">
        <div class="egg-id-element">
          <p>No. {{ k.data.id }}</p>
          <img src="{{ k.data.iconImage }}" />
        </div>
        <div class="egg-gen-element">
          <p>GEN: {{ k.data.generation }}</p>
          <p>ELEMENT: {{ k.data.typeFormatted }}</p>
        </div>
        <div
          class="egg-action-button"
          (click)="openDetails('kryptomon', k.data.id, k)"
        >
          Genome
        </div>
        <div class="egg-action-button" (click)="grantAllowance()" *ngIf="
            isReadyToEvolve(i) &&
            !hasAllowance &&
            !loadingAllowance
          ">
          <span>Allowance</span>
        </div>
        <div class="egg-action-button disabled" *ngIf="loadingAllowance">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div *ngIf="isReadyToEvolve(i) && !hasKmonToEvolve()">
          <p style="text-align: center; color: #d84f3e">100 KMON is required to evolve</p>
          <div class="egg-action-button" (click)="buyKmon(k)">
            Buy KMON
          </div>
        </div>
        <div
          class="egg-action-button"
          (click)="startEvolving(k.data.id, i)"
          *ngIf="isReadyToEvolve(i) && fetchKryptomon && !isEvolving[k.data.id] && hasAllowance && hasKmonToEvolve()"
        >
          Evolve
        </div>
        <div
          class="egg-action-button disabled"
          (click)="startEvolving(k.data.id, i)"
          *ngIf="isReadyToEvolve(i) && fetchKryptomon && isEvolving[k.data.id]"
        >
          Evolving
        </div>
      </div>
    </div>
    <div
      class="egg-card no-eggs"
      *ngIf="kryptomons.length == 0 && fetchKryptomon"
    >
      <div class="egg-card-image">
        <img src="./assets/img/Home/cactus.png" />
      </div>
      <div class="egg-card-content">
        <div class="egg-id-element">
          <p>You don't have any Kryptomon yet!</p>
        </div>
        <div class="egg-action-button extend" (click)="goMarketplace()">
          Marketplace
        </div>
      </div>
    </div>
  </div>
</div>
<div class="newegg-overlay" *ngIf="noWallet">
  <div class="card">
    <div class="card-content" *ngIf="connection_text == 'No wallet'">
      <p>No wallet detected, please install a wallet like <a href="https://metamask.io/download" target="_blank">Metamask</a> or Mist and refresh the page once done!</p>
    </div>
    <div class="card-content" *ngIf="connection_text == 'Grant access'">
      <p>We've dected a wallet, but no connection has been established. Please connect a wallet</p>
      <div class="signup-btn marginTop25">Connect</div>
    </div>
    <div class="card-content" *ngIf="connection_text == 'Wrong Network'">
      <p>We've dected a wallet, but you are connected to the wrong network. Please connect to Binance Smartchain Mainnet.</p>
    </div>
  </div>
</div>
<div class="newegg-overlay" *ngIf="showShare">
  <div class="card">
    <div class="close">
      <fa-icon [icon]="['fas', 'times-circle']" (click)="closeShare()"></fa-icon>
    </div>
    <div class="card-content" *ngIf="!collectedKryptomon">
      <img src="./assets/img/Home/{{modalImage}}" class="egg-pic" />
      <div class="modal-text">
        <p>{{modalTitle}}</p>
        <p>{{modalContent}}</p>
        <div (click)="doAction()" *ngIf="!isLoadingBuy" class="signup-btn">{{modalCta}}</div>
        <div class="lds-ripple" *ngIf="isLoadingBuy">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
    <div class="card-content" *ngIf="collectedKryptomon">
      <img src="{{kryptomons[0].data.image}}" class="egg-pic" />
      <p>Congratulation Trainer!</p>
      <p>You got your Kryptomon Egg 🎉</p>
      <p>Share your Kryptomon egg with the world</p>
      <div class="share-buttons">
        <share-button button="twitter" url="https://kryptomon.co" description="Here is my Kryptomon Egg! 😍 A new adventure is about to begin and you? Do you want to be a part of it? Join the telegram group to find out how 🎉 https://t.me/kryptomonofficial #Kryptomon"></share-button>
        <share-button button="reddit" url="https://kryptomon.co" title="I finally got my Kryptomon's egg!" description="Here is my Kryptomon Egg! 😍 A new adventure is about to begin and you? Do you want to be a part of it? Join the telegram group to find out how 🎉 https://t.me/kryptomonofficial #Kryptomon"></share-button>
        <share-button button="facebook" url="https://kryptomon.co" title="I finally got my Kryptomon's egg!" description="Here is my Kryptomon Egg! 😍 A new adventure is about to begin and you? Do you want to be a part of it? Join the telegram group to find out how 🎉 https://t.me/kryptomonofficial #Kryptomon"></share-button>
      </div>
      <p class="marginTop25">And now?</p>
      <div (click)="gotoDashboard()" class="signup-btn">Go to Dashboard</div>
    </div>
  </div>
</div>
<div id="tsparticles"></div>
<div class="row content_display dashboard">
  <div class="col"></div>
  <div class="col">
    <div class="card" [ngClass]="{'disabled': !ownsPartnerNFT && !ownsPartnerNFT2}">
      <div class="card-content">
        <img class="bnftImage" src="./assets/img/Home/binance-coin-bnb-logo.png" />
        <p>Binance NFT <span *ngIf="ownsPartnerNFT">({{ownedPartnerNFTs.length}})</span> </p>
      </div>
      <div *ngIf="!ownsPartnerNFT && !ownsPartnerNFT2" class="signup-btn disabled">Nothing to redeem</div>
      <div (click)="approveBNFT()" *ngIf="!isLoadingBNFT && !isApproved && ownsPartnerNFT" class="signup-btn">Approve</div>
      <div (click)="approveBNFT2()" *ngIf="!isLoadingBNFT && !isApproved2 && ownsPartnerNFT2 && !ownsPartnerNFT" class="signup-btn">Approve</div>
      <div (click)="redeemBNFT()" *ngIf="!isLoadingBNFT && isApproved && ownsPartnerNFT" class="signup-btn">Redeem</div>
      <div (click)="redeemBNFT2()" *ngIf="!isLoadingBNFT && isApproved2 && ownsPartnerNFT2 && !ownsPartnerNFT" class="signup-btn">Redeem</div>
        <div class="lds-ripple" *ngIf="isLoadingBNFT">
          <div></div>
          <div></div>
        </div>
    </div>
    <div class="card card-error" *ngIf="disabled[0]">
      <div class="card-content">
        <span>No Kryptomon eggs to redeem</span>
      </div>
    </div>
  </div>
  <div class="col"></div>
</div>


import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppComponent } from "../../app.component";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-egg-hunt-listing',
  templateUrl: './egg-hunt-listing.component.html',
  styleUrls: ['./egg-hunt-listing.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class EggHuntListingComponent implements OnInit {

  public account
  public isWhiteListed
  public address_listed
  public isClosed
  public isCompleted

  constructor(public appComponent: AppComponent, public http: HttpClient) {

    this.isWhiteListed = false;
    this.isClosed = false;
    this.isCompleted = false;

    let cookie = window.localStorage.getItem('listed');

    if(cookie==undefined || cookie==''){
    
     if (this.appComponent.accounts == undefined) {
         this.appComponent.accountsObservable.subscribe((item) => {
           this.account = item[0];
           this.checkListed();
         });
       } else {
         this.account = this.appComponent.accounts[0];
         this.checkListed();
      }
    }else{
      let cookie_address = window.localStorage.getItem('address');
      this.address_listed = cookie_address;
      this.isWhiteListed = true;
    }
  }

  ngOnInit(): void {
  }

  checkListed(){
     if(this.account!=='' && this.account!==undefined){
      let post = {address: this.account};
      this.http.post("https://api.kryptomon.co/auth/egg-hunt-check.php", post).subscribe((item:any)=>{
        console.log(item)
        if(item.status == 'already'){
          this.isWhiteListed = true;
          this.setCookie()
        }else if(item.status == 'closed'){
          this.isWhiteListed = false;
          this.isClosed = true;
        }else if(item.status == 'completed'){
          this.isWhiteListed = false;
          this.isCompleted = true;
        }else{
          this.isWhiteListed = false;
        }

      })
    }
  }

  whiteList(){
    if(this.account!=='' && this.account!==undefined){
      let post = {address: this.account};
      this.http.post("https://api.kryptomon.co/auth/egg-hunt-listing.php", post).subscribe((item:any)=>{
        if(item.status == 'done'){
          this.isWhiteListed = true;
          this.setCookie()
        }else if(item.status=='already'){
          this.isWhiteListed = true;
          this.setCookie()
        }else{
          this.isWhiteListed = false;
        }
      })
    }
  }

  setCookie(){
    window.localStorage.setItem('listed', "true");
    window.localStorage.setItem('address', this.account)
  }

}
